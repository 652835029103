<div class="container-fluid paddingTop">
    <div class="row">
        <div class="col-md-4">
            <h1 class="display-4 display">Alerting</h1>
        </div>
        <div class="col-md-8">
            <div class="form-inline d-flex align-items-center float-right purgingEnable">
                <div class="btn-group" btnRadioGroup [(ngModel)]="alerting.Enabled">
                    <label class="btn btn-outline-danger mb-0" [btnRadio]="false" (click)="changeAlerting()">Disable</label>
                    <label class="btn btn-outline-success mb-0" [btnRadio]="true" (click)="changeAlerting()">Enable</label>
                </div>
            </div>
            <h1 class="display-4 display">
                Status
                <span *ngIf="lstHistories.length > 0 || lstHistories.length > 0">
                    <svg *ngIf="alerting.Enabled" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" /></svg>
                    <svg *ngIf="!alerting.Enabled" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" /></svg>
                </span>
            </h1>
        </div>
    </div>
</div>

<div class="row paddingTable container-fluid">
    <div class="col-md-4">
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/alerting/subscriptions">Subscriptions</a>
            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/alerting/conditions">Conditions</a>
            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/alerting/actions">Actions</a>
            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/alerting/scheduler">Schedulers</a>
        </div>
    </div>
    <div class="col-md-8 paddingBottomTable">
        <div class="table-responsive" style="overflow: inherit">
            <div class="card">
                <table class="table table-hover p-3 mb-5 tableLikeCard">
                    <thead class="text-primary-inverse">
                        <tr>
                            <th class="firstCell col-md-5" id="runtime">Last runtimes</th>
                            <th class="col-md-3" id="status">Status</th>
                            <th class="col-md-4" id="error">Scheduler</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="4" class="nomsg text-muted" *ngIf="!isLoading && lstHistories.length == 0">
                                No history found.
                            </td>
                            <td colspan="12" *ngIf="isLoading">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                        Loading status
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr *ngFor="let theHistory of lstHistories; let i = index">
                            <td class="firstCell col-md-9 ellipsis">
                                <span>{{this.datepipe.transform(theHistory.Date, 'dd/MM/yyyy - HH:mm:ss')}}</span>
                            </td>
                            <td class="col-md-3">
                                <span>
                                    {{ theHistory.Status }}
                                </span>
                            </td>
                            <td class="col-md-4 ellipsis">
                                <span>
                                {{ theHistory.AlertingScheduler.Name }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>
<div class="col-md-12">
    <router-outlet></router-outlet>
</div>