import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../_services';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SnackbarService } from 'ngx-snackbar';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    styleUrls: ['../configuration.component.scss', './configuration_alerting.component.scss'],
    templateUrl: './configuration_alerting.component.html',
    providers: [DatePipe]
})

export class ConfigurationAlertingComponent implements OnInit {

    public alerting: TnTAlerting;
    public isLoading: boolean = false;

    public lstHistories: Array<TnTAlertingHistory> = [];
    

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private snackbarService: SnackbarService, public datepipe: DatePipe, private sanitizer: DomSanitizer) {
     }

    public ngOnInit() {
        this.svcAPI.getAlerting()
        .pipe(catchError((error) => {
            return of([]); // Return empty message set
        }))
        .subscribe(
            (alerting:TnTAlerting) => {
                this.alerting = alerting
            }
        );
        this.svcAPI.getAlertingHistory()
        .pipe(catchError((error) => {
            return of([]); // Return empty message set
        }))
        .subscribe(
            (history:Array<TnTAlertingHistory>) => {
                this.lstHistories = history
            }
        );
    }

    ngAfterViewInit(): void { }

    openUrl(jobId: string){
        window.open(window.location.origin + '/hangfire/jobs/details/' + jobId,'_blank')
    }

    public showDate(dateString: string){
        const date: Date = new Date(dateString)
        return this.addZ(date.getDate()) + '/' + this.addZ(date.getUTCMonth()) + '/' + date.getFullYear() + " " + this.addZ(date.getHours()) + ":" + this.addZ(date.getMinutes()) + ":" + this.addZ(date.getSeconds())
    }

    addZ(n){return n<10? '0'+n:''+n;}

    changeAlerting(){
        this.svcAPI.modifyAlerting(this.alerting)
        .pipe(catchError((error) => {
            return of([]); // Return empty message set
        }))
        .subscribe(
            (res) => {
                if(res['Message'] == 'Success'){
                    this.alerting = res['Value']
                    const _this = this;
                    this.snackbarService.add({
                        msg: "Saved",
                        timeout: 2000,
                        customClass: "snackBarSucces",
                        action: {
                            text: "X",
                        },
                    });
                }else{
                    const _this = this;
                    this.snackbarService.add({
                        msg: res['Message'],
                        timeout: 2000,
                        customClass: "snackBarError",
                        action: {
                            text: "X",
                        },
                    });
                }
            }
        );
    }
}
