<ng-template #filterMessagetype>
    <div class="filterwidth">
        <input type="text" #inputMessageTypeSearch class="form-control" placeholder="MessageType" [(ngModel)]="srcMessageType" (keyup.enter)="getMessageTypes(); popoverMessageType.hide()">
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="getMessageTypes(); popoverMessageType.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearSearchMessagetype(); popoverMessageType.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<ng-template #filterMessagetypeBusinessName>
    <div class="filterwidth">
        <ng-select [items]="lstMessageTypesBusinessNames"
                   [multiple]="false"
                   [clearable]="true"
                   bindLabel="BusinessName"
                   [(ngModel)]="selMessageTypeBnFilter">
        </ng-select>
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="getMessageTypes(); popoverMessageTypeBusinessName.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearSearchMessagetypeBusinessName(); popoverMessageTypeBusinessName.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<div class="container-fluid paddingTop">
    <h1 class="display-4 display">Message type administration</h1>
    <button class="btn btn-primary float-right addButton" type="button" (click)="btnAddMessageTypeEnabledClicked()">
        <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
    </button>
</div>

<div class="row paddingTable container-fluid">
    <div class="col-md-12 paddingBottomTable">
        <div class="card">
            <div class="table-responsive" style="overflow: inherit">
                <table class="table table-hover p-3 mb-5 tableLikeCard">
                    <thead class="text-primary-inverse">
                        <tr>
                            <th class="col-3">Messagetype <i-feather name="filter" [popover]="filterMessagetype" placement="right" #popoverMessageType="bs-popover" class="filterIcon" [ngClass]="{'fill': blnSearchMessageTypeApplied}" (onShown)="focusInputSearchMessageType()"></i-feather></th>
                            <th class="col-3">Businessname <i-feather name="filter" [popover]="filterMessagetypeBusinessName" #popoverMessageTypeBusinessName="bs-popover" class="filterIcon" [ngClass]="{'fill': blnSearchMessageTypeBusinessNameApplied}"></i-feather></th>
                            <th class="col-2">Download reference</th>
                            <th class="col-2">Extension</th>
                            <th class="col-2"><span tooltip="Set in Retention tab.">Retention</span></th>
                            <th class="smallTD-7 centerText"><span tooltip="Enabled message types will be shown in the message type filter in the monitor.">Searchable</span></th>
                            <th class="smallTD-6 centerText"></th>
                        </tr>
                    </thead>
                    <tbody class="pointer">
                        <tr>
                            <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstMessageTypes.length == 0">
                                No messagetypes found.
                            </td>
                            <td colspan="12" *ngIf="isLoading">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                        Loading messages
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr id="lastTR" *ngIf="AddMessageTypeEnabled">
                            <td class="col-3">
                                <input #inputNewMessageType class="form-control" [(ngModel)]="strNewMessageType" placeholder="Message type name" />
                            </td>
                            <td class="col-3">
                                <ng-select placeholder="Select business name"
                                           [items]="lstMessageTypesBusinessNames"
                                           [multiple]="false"
                                           [clearable]="true"
                                           bindLabel="BusinessName"
                                           [(ngModel)]="selNewMessageTypeBn">
                                </ng-select>

                            </td>

                            <td class="col-2">
                                <input #inputNewMessageTypeDr class="form-control" [(ngModel)]="strNewMessageTypeDr" placeholder="Download reference" />
                            </td>
                            <td class="col-2">
                                <ng-select placeholder="Select extension"
                                           [items]="lstExtensions"
                                           [multiple]="false"
                                           [clearable]="true"
                                           [(ngModel)]="selNewMessageTypeExt">
                                </ng-select>

                            </td>


                            <td class="smallTD-6 centerText text-muted" tooltip="Set in retention tab."></td>


                            <td (click)="searchableNewMTClicked()" class="centerText smallTD-7">
                                <i-feather name="square" id="lastCheckMark" class="pointer" *ngIf="!blnNewMessageTypeSearchable"></i-feather>
                                <i-feather name="check-square" id="lastCheckMark" class="pointer" *ngIf="blnNewMessageTypeSearchable"></i-feather>
                            </td>
                            <td class="centerText smallTD">
                                <button class="btn btn-outline-primary" type="button" (click)="btnAddMessageTypeClicked()">
                                    <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
                                </button>
                            </td>
                        </tr>

                        <tr *ngFor="let theMessageType of lstMessageTypes; let i = index" [class.active]="i == selectedRow" (click)="messageTypeClicked(i,'',undefined)">
                            <ng-container *ngIf="!isLoading">
                                <!--MessageType-->
                                <td class="col-3 firstCell ellipsis" (click)="messageTypeClicked(i, 'MessageType',theMessageType)">
                                    <div class="form-group">
                                        <span *ngIf="selMessageTypeId != theMessageType.Id">{{theMessageType.MessageType}}</span>
                                        <input #inputMessageType class="form-control" *ngIf="selMessageTypeId == theMessageType.Id" [(ngModel)]="theMessageType.MessageType" (input)="mtChanged(theMessageType)" />
                                    </div>
                                </td>

                                <!--BusinessName-->
                                <td class="col-3" (click)="messageTypeClicked(i, 'BusinessName',theMessageType)" [ngClass]="{'ellipsis': selMessageTypeBnId != theMessageType.Id}">
                                    <span *ngIf="selMessageTypeBnId != theMessageType.Id">{{theMessageType.BusinessName}}</span>

                                    <ng-select *ngIf="selMessageTypeBnId == theMessageType.Id"
                                               [items]="lstMessageTypesBusinessNames"
                                               [multiple]="false"
                                               [clearable]="true"
                                               bindLabel="BusinessName"
                                               [(ngModel)]="selMessageTypeBn"
                                               (ngModelChange)="bnMtChanged(theMessageType)">
                                    </ng-select>
                                </td>

                                <!--Extension-->
                                <td class="col-2" (click)="messageTypeClicked(i, 'Extension',theMessageType)">
                                    <span *ngIf="selMessageTypeExtId != theMessageType.Id">{{theMessageType.Extension}}</span>

                                    <ng-select *ngIf="selMessageTypeExtId == theMessageType.Id"
                                               [items]="lstExtensions"
                                               [multiple]="false"
                                               [clearable]="true"
                                               [(ngModel)]="theMessageType.Extension"
                                               (ngModelChange)="extMtChanged(theMessageType)">
                                    </ng-select>
                                </td>

                                <!--Download reference-->
                                <td class="col-2 ellipsis" (click)="messageTypeClicked(i, 'DownloadRef',theMessageType)">
                                    <div class="form-group">
                                        <span *ngIf="selMessageTypeDrId != theMessageType.Id">{{theMessageType.ReferenceKey}}</span>
                                        <input #inputMessageTypeDr class="form-control" *ngIf="selMessageTypeDrId == theMessageType.Id" [(ngModel)]="theMessageType.ReferenceKey" (input)="drMtChanged(theMessageType)" />
                                    </div>
                                </td>

                                <!-- Display retention time-->
                                <td class="col-2 ellipsis">
                                    <div class="form-group">
                                        <span class="text-muted">{{getRetentionFE(theMessageType.BusinessNameId)}}</span>
                                    </div>
                                </td>

                                <!--Searchable-->
                                <td (click)="searchableClicked(theMessageType)" class="centerText smallTD-7">
                                    <i-feather name="square" *ngIf="theMessageType.Searchable == 0"></i-feather>
                                    <i-feather name="check-square" *ngIf="theMessageType.Searchable != 0"></i-feather>
                                </td>

                                <!--Delete-->
                                <td class="smallTD-6 centerText">
                                    <span (click)="removeMessageType(theMessageType)" class="hoverTrashIcon"><i-feather name="trash"></i-feather></span>
                                </td>
                            </ng-container>
                        </tr>


                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
