import { Injectable, Pipe, PipeTransform } from '@angular/core'; // Pipe

@Pipe({
    name: 'isactive',
    pure: false
})
export class GroupIsActivePipe implements PipeTransform {
    transform(items: any[], active: boolean): any {
        // filter items array, items which match and return true will be kept, false will be filtered out
        if (active) {
            return items.filter(item => item.Active === 1);
        } else {
            return items.filter(item => item.Active === 0);
        }
    }
}
