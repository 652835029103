<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="detail-header" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title overflow">
                    <div class="c-detail-wrapper">
                        <h4 id="detail-header" *ngIf="theAction">
                            {{ theAction.Subscription.Type }}
                        </h4>
                    </div>
                </div>
                <div class="pull-right">
                    <button type="button" class="close" aria-label="Close" (click)="hideChildModal()">
                        <i-feather name="x" class="modaltitlebutton"></i-feather>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <div class="detail-content" *ngIf="!isLoading">
                    <!-- General type details -->
                    <div class="container-fluid">
                        <div class="col-12 paddingBottomTable" *ngIf="theAction.Subscription.Type === 'Webhook'">
                            <div class="card">
                                <div class="table-responsive" style="overflow: inherit">
                                    <table class=" table table-hover p-3 mb-5 tableLikeCard ">
                                        <thead class="text-primary-inverse">
                                            <tr>
                                                <th class="col-5">Name</th>
                                                <th class="col-6">URL</th>
                                                <th class="col-1">Active</th>
                                            </tr>
                                        </thead>
                                        <tbody class="pointer">
                                            <tr>
                                                <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstWebhooks.length == 0">
                                                    No endpoints found.
                                                </td>
                                                <td colspan="12" *ngIf="isLoading">
                                                    <div class="progress">
                                                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                                            Loading endpoints
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr id="lastTR" *ngIf="addEndpointEnabled">
                                                <td class="col-3">
                                                    <input #inputNewEndpointUrl class="form-control" [(ngModel)]="strNewEndpointUrl" placeholder="Url" />
                                                </td>
                                                <td class="smallTD-6 centerText">
                                                    <span (click)="btnAddEndpointClicked()" class="hoverTrashIcon">
                                                        <i-feather name="plus-circle"></i-feather>
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr *ngFor="let theEndpoint of lstWebhooks; let i = index" [class.active]="i == selectedRow">
                                                <ng-container *ngIf="!isLoading">
                                                    <!--Name-->
                                                    <td class="col-5 firstCell ellipsis">
                                                        <div class="form-group">
                                                            <span>{{theEndpoint.Endpoint.Name}}</span>
                                                        </div>
                                                    </td>

                                                    <!--Url-->
                                                    <td class="col-6 firstCell ellipsis">
                                                        <div class="form-group">
                                                            <span>{{theEndpoint.Endpoint.Url}}</span>
                                                        </div>
                                                    </td>

                                                    <!--Delete-->
                                                    <td class="col-1" (click)="webhookClicked(theEndpoint)">
                                                        <i-feather name="square" *ngIf="theEndpoint.Active == 0">
                                                        </i-feather>
                                                        <i-feather name="check-square" *ngIf="theEndpoint.Active != 0">
                                                        </i-feather>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" *ngIf="isLoading">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                            Loading
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row col-12">
                        <button class="btn btn-secondary col-5" (click)="hideChildModal()">
                            Close
                        </button>
                        <span class="col-2"></span>
                        <button class="btn btn-primary col-5" (click)="save()">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>