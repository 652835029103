import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TnTAPIService } from "../../../_services/";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { SnackbarService } from "ngx-snackbar";

@Component({
    styleUrls: [
        "../../configuration.component.scss",
        "./configuration_reprocessing_triggermech.component.scss",
    ],
    templateUrl: "./configuration_reprocessing_triggermech.component.html",
})
export class ConfigurationReprocessingTriggermechComponent implements OnInit {
    private selectedRow: Number;

    private selTrigger: TnTReprocessTrigger = null;
    private selTriggerUrlId: number = -1;
    private selTriggerBn: TnTMessageType;
    private selTriggerMtId: number = -1;
    public isLoading: boolean = false;

    //Add reprocesstrigger
    public blnIncludePayloadNewReprocessTrigger: boolean = false;
    public blnIncludeMetadataNewReprocessTrigger: boolean = false;
    public blnIncludeProcessNewReprocessTrigger: boolean = false;
    public strNewReprocessTriggerURL: string = "";
    public selNewReprocessTriggerBn: TnTMessageType;

    private previousReprocessTrigger: TnTReprocessTrigger = null;

    private timer;

    public lstReprocessTriggers: Array<TnTReprocessTrigger> = [];
    public lstFEMessageTypes: Array<TnTMessageType> = [];
    public lstAllMessageTypes: Array<TnTMessageType> = [];

    @ViewChild("inputURL", { static: false }) inputURL: any;

    // Filter vars
    public srcMessageTypeFilter: TnTMessageType = null;
    public blnSearchMessageTypeApplied = false;

    public srcURL: string = "";
    public blnSearchURLApplied = false;

    @ViewChild("inputURLSearch", { static: false }) inputURLSearch: any;

    constructor(
        private svcAPI: TnTAPIService,
        private svcRouter: Router,
        private snackbarService: SnackbarService
    ) {}

    public ngOnInit() {
        this.isLoading = true;
        this.getAllReprocessTriggers();
        this.getAvailableMessageTypes();
        this.getAllMessageTypes();
        this.isLoading = false;
    }

    ngAfterViewInit(): void {}

    public getAllReprocessTriggers(): void {
        this.svcAPI
            .getAllReprocessTriggers(this.srcMessageTypeFilter, this.srcURL)
            .pipe(
                catchError((error) => {
                    return of([]); // Return empty message set
                })
            )
            .subscribe((res) => {
                this.lstReprocessTriggers = res;
            });
    }

    public getAllMessageTypes() {
        this.svcAPI
            .getMessageTypes(false)
            .pipe(
                catchError((error) => {
                    return of([]); // Return empty message set
                })
            )
            .subscribe((messagetypes) => {
                this.lstAllMessageTypes = messagetypes;
            });
    }

    public getAvailableMessageTypes(includeMessageType?: TnTReprocessTrigger) {
        if (includeMessageType != null) {
            this.svcAPI
                .getMessageTypesReprocessTrigger(
                    false,
                    includeMessageType.MessageTypeId
                )
                .pipe(
                    catchError((error) => {
                        return of([]); // Return empty message set
                    })
                )
                .subscribe((messagetypes) => {
                    this.lstFEMessageTypes = messagetypes;
                    this.selTriggerBn = this.lstFEMessageTypes.find(
                        (x) => x.Id == includeMessageType.MessageTypeId
                    );
                });
        } else {
            this.svcAPI
                .getMessageTypesReprocessTrigger(false)
                .pipe(
                    catchError((error) => {
                        return of([]); // Return empty message set
                    })
                )
                .subscribe((messagetypes) => {
                    this.lstFEMessageTypes = messagetypes;
                });
        }
    }

    public triggerClicked(
        i: number,
        field: String,
        clicked?: TnTReprocessTrigger
    ) {
        this.selectedRow = i;
        this.selTrigger = clicked;

        this.selTriggerMtId = -1;
        this.selTriggerUrlId = -1;

        this.lstFEMessageTypes = this.lstAllMessageTypes;

        if (clicked != undefined) {
            this.getAvailableMessageTypes(clicked);
        }

        if (field === "URL") {
            this.lstReprocessTriggers.forEach((t) => {
                if (clicked.Id === t.Id) {
                    this.selTriggerUrlId = t.Id;
                    setTimeout(() => {
                        this.inputURL.nativeElement.focus();
                    }, 10);
                }
            });
        }

        if (field === "MessageType") {
            this.lstReprocessTriggers.forEach((t) => {
                if (clicked.Id === t.Id) {
                    this.selTriggerMtId = t.Id;
                }
            });
        }
    }

    public newDescriptionClicked(): void {
        this.getAvailableMessageTypes();
    }

    public PayloadClicked(t: TnTReprocessTrigger): void {
        if (t.IncludePayload) {
            t.IncludePayload = false;
        } else {
            t.IncludePayload = true;
        }
        this.svcAPI.changeReprocessTrigger(t).subscribe((result) => {
            if (result === "Succes") {
                const _this = this;
                this.snackbarService.add({
                    msg: "Saved",
                    timeout: 2000,
                    customClass: "snackBarSucces",
                    action: {
                        text: "X",
                    },
                });
            } else {
                const _this = this;
                this.snackbarService.add({
                    msg: result,
                    timeout: 2000,
                    customClass: "snackBarError",
                    action: {
                        text: "X",
                    },
                });
            }
        });
    }

    public MetadataClicked(t: TnTReprocessTrigger): void {
        if (t.IncludeMetadata) {
            t.IncludeMetadata = false;
        } else {
            t.IncludeMetadata = true;
        }
        this.svcAPI.changeReprocessTrigger(t).subscribe((result) => {
            if (result === "Succes") {
                const _this = this;
                this.snackbarService.add({
                    msg: "Saved",
                    timeout: 2000,
                    customClass: "snackBarSucces",
                    action: {
                        text: "X",
                    },
                });
            } else {
                const _this = this;
                this.snackbarService.add({
                    msg: result,
                    timeout: 2000,
                    customClass: "snackBarError",
                    action: {
                        text: "X",
                    },
                });
            }
        });
    }

    public ProcessClicked(t: TnTReprocessTrigger): void {
        if (t.IncludeProcess) {
            t.IncludeProcess = false;
        } else {
            t.IncludeProcess = true;
        }
        this.svcAPI.changeReprocessTrigger(t).subscribe((result) => {
            if (result === "Succes") {
                const _this = this;
                this.snackbarService.add({
                    msg: "Saved",
                    timeout: 2000,
                    customClass: "snackBarSucces",
                    action: {
                        text: "X",
                    },
                });
            } else {
                const _this = this;
                this.snackbarService.add({
                    msg: result,
                    timeout: 2000,
                    customClass: "snackBarError",
                    action: {
                        text: "X",
                    },
                });
            }
        });
    }

    public URLChanged(t: TnTReprocessTrigger): void {
        if (t != null) {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.svcAPI.changeReprocessTrigger(t).subscribe((result) => {
                    if (result === "Succes") {
                        const _this = this;
                        this.snackbarService.add({
                            msg: "Saved",
                            timeout: 2000,
                            customClass: "snackBarSucces",
                            action: {
                                text: "X",
                            },
                        });
                    } else {
                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: "snackBarError",
                            action: {
                                text: "X",
                            },
                        });
                    }
                });
                clearInterval(this.timer);
            }, 800);
        }
    }

    public bnTriggerChanged(t: TnTReprocessTrigger): void {
        if (t != null) {
            t.MessageTypeId = this.selTriggerBn.Id;
            this.svcAPI.changeReprocessTrigger(t).subscribe((result) => {
                if (result === "Succes") {
                    this.getAllReprocessTriggers();
                    const _this = this;
                    this.snackbarService.add({
                        msg: "Saved",
                        timeout: 2000,
                        customClass: "snackBarSucces",
                        action: {
                            text: "X",
                        },
                    });
                } else {
                    const _this = this;
                    this.snackbarService.add({
                        msg: result,
                        timeout: 2000,
                        customClass: "snackBarError",
                        action: {
                            text: "X",
                        },
                    });
                }
            });
        }
    }

    public removeReprocessTrigger(t: TnTReprocessTrigger): void {
        if (t != null) {
            this.svcAPI
                .deleteReprocessTrigger(t.Id, false)
                .subscribe((result) => {
                    if (result === "Succes") {
                        this.getAllReprocessTriggers();
                        this.getAvailableMessageTypes();
                        const _this = this;
                        this.snackbarService.add({
                            msg: "Removed",
                            timeout: 2000,
                            customClass: "snackBarSucces",
                            action: {
                                text: "X",
                            },
                        });
                    } else {
                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: "snackBarError",
                            action: {
                                text: "X",
                            },
                        });
                    }
                });
        }
    }

    public btnAddReprocessTriggerClicked(): void {
        if (this.strNewReprocessTriggerURL == "") {
            const _this = this;
            this.snackbarService.add({
                msg: "Please fill in a URL",
                timeout: 2000,
                customClass: "snackBarError",
                action: {
                    text: "X",
                },
            });
        } else if (this.selNewReprocessTriggerBn == undefined) {
            const _this = this;
            this.snackbarService.add({
                msg: "Please select a messagetype",
                timeout: 2000,
                customClass: "snackBarError",
                action: {
                    text: "X",
                },
            });
        } else {
            var theNewReprocessTrigger: TnTReprocessTrigger = {
                Id: -1,
                URL: this.strNewReprocessTriggerURL,
                MessageTypeId: this.selNewReprocessTriggerBn.Id,
                IncludePayload: this.blnIncludePayloadNewReprocessTrigger,
                IncludeMetadata: this.blnIncludeMetadataNewReprocessTrigger,
                IncludeProcess: this.blnIncludeProcessNewReprocessTrigger,
                MessageTypeBusinessName: "",
                Description: null,
            };

            this.svcAPI
                .addReprocessTrigger(theNewReprocessTrigger)
                .subscribe((result) => {
                    if (result === "Succes") {
                        this.getAllReprocessTriggers();
                        this.getAvailableMessageTypes();
                        this.selNewReprocessTriggerBn = null;
                        this.strNewReprocessTriggerURL = "";
                        this.blnIncludeMetadataNewReprocessTrigger = false;
                        this.blnIncludePayloadNewReprocessTrigger = false;
                        this.blnIncludeProcessNewReprocessTrigger = false;

                        const _this = this;
                        this.snackbarService.add({
                            msg: "Added",
                            timeout: 2000,
                            customClass: "snackBarSucces",
                            action: {
                                text: "X",
                            },
                        });
                    } else {
                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: "snackBarError",
                            action: {
                                text: "X",
                            },
                        });
                    }
                });
        }
    }

    public PayloadNewReprocessTriggerClicked(): void {
        if (this.blnIncludePayloadNewReprocessTrigger) {
            this.blnIncludePayloadNewReprocessTrigger = false;
        } else {
            this.blnIncludePayloadNewReprocessTrigger = true;
        }
    }

    public MetadataNewReprocessTriggerClicked(): void {
        if (this.blnIncludeMetadataNewReprocessTrigger) {
            this.blnIncludeMetadataNewReprocessTrigger = false;
        } else {
            this.blnIncludeMetadataNewReprocessTrigger = true;
        }
    }

    public ProcessNewReprocessTriggerClicked(): void {
        if (this.blnIncludeProcessNewReprocessTrigger) {
            this.blnIncludeProcessNewReprocessTrigger = false;
        } else {
            this.blnIncludeProcessNewReprocessTrigger = true;
        }
    }

    public focusInputSearchURL(): void {
        setTimeout(() => {
            this.inputURLSearch.nativeElement.focus();
        }, 10);
    }

    public clearSearchMessagetype(): void {
        this.srcMessageTypeFilter = null;
        this.getAllReprocessTriggers();
    }

    public clearSearchURL(): void {
        this.srcURL = "";
        this.getAllReprocessTriggers();
    }
}
