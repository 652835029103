import { Component, OnInit, ElementRef, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../_services/';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from 'ngx-snackbar';

@Component({
    styleUrls: ['../configuration.component.scss','./configuration_filters.component.scss'],
    templateUrl: './configuration_filters.component.html',
})

export class ConfigurationFiltersComponent implements OnInit {
    // Loader
    public isLoading: boolean;

    //Storage
    public lstMessageTypesBN: Array<TnTMessageTypeBusinessName> = [];
    public lstPartiesBN: Array<TnTPartyBusinessName> = [];
    public lstTransactionStatus: Array<TnTTransactionStatus> = [];
    public lstFilters: TnTFilter[] = [];

    //Lists
    public lstPropertyMessageTypeBN: any;
    public lstPropertyMessageTypeBNSelected: Array<TnTMessageTypeBusinessName> = [];
    public lstPropertySenderBN: any;
    public lstPropertySenderBNSelected: Array<TnTPartyBusinessName> = [];
    public lstPropertyReceiverBN: any;
    public lstPropertyReceiverBNSelected: Array<TnTPartyBusinessName> = [];
    public lstPropertyTransactionStatusBN: any;
    public lstPropertyTransactionStatusBNSelected: Array<TnTTransactionStatus> = [];

    // Timer
    private timer;

    //Filter vars
    public selFilterId: number;
    public selProperty: string;
    public selFilter: TnTFilter;
    public edtFilter: TnTFilter; // Will contain edited values for selected status until another row is clicked, the changes are saved & this value is reset
    public newFilter: TnTFilter = <TnTFilter>{}; // Contains new status values
    public blnEditFilterName: boolean = false;

    public strNewFilterName: string = '';

    public allSelectedMessageTypes: boolean = false;
    public allSelectedSenders: boolean = false;
    public allSelectedReceivers: boolean = false;
    public allSelectedTransactionstatusses: boolean = false;

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private snackbarService: SnackbarService) { }

    public ngOnInit() {
        // Retrieve list of messagetypes
        forkJoin({
            messagetypeBN: this.svcAPI.getMessageTypesBusinessNames(true) // businessnames
                .pipe(catchError((error) => {
                    return of([]); // Return empty status set
                })),
            partiesBN: this.svcAPI.getPartiesBusinessNames(true) // parties
                .pipe(catchError((error) => {
                    return of([]); // Return empty status set
                })),
            transactions: this.svcAPI.getTransactionStatusTypes() // parties
                .pipe(catchError((error) => {
                    return of([]); // Return empty status set
                })),
            filters: this.svcAPI.getFilters()
                .pipe(catchError((error) => {
                    return of([]); // Return empty status set
                }))
        }).subscribe((results) => {
            this.lstFilters = results.filters;
            this.lstMessageTypesBN = results.messagetypeBN;
            this.lstPartiesBN = results.partiesBN;
            this.lstTransactionStatus = results.transactions;
        });
    }

    public filterSelected(theFilter: TnTFilter) {
        this.selFilter = theFilter;
        this.selFilterId = theFilter.Id;
        this.selProperty = '';
    }

    public propteryClicked(theProperty: string) {
        this.selProperty = theProperty;
        this.lstPropertyMessageTypeBN = [];
        this.lstPropertyMessageTypeBNSelected = [];
        this.lstPropertySenderBN = [];
        this.lstPropertySenderBNSelected = [];
        this.lstPropertyReceiverBN = [];
        this.lstPropertyReceiverBNSelected = [];
        this.lstPropertyTransactionStatusBN = [];
        this.lstPropertyTransactionStatusBNSelected = [];

        if (theProperty == 'Message types') {

            for (const messagetype of this.lstMessageTypesBN) {
                const data = { 'MessageType': messagetype, 'Active': 0 };
                this.lstPropertyMessageTypeBN.push(data);
            }

            if (this.selFilter.MessageTypes != null && this.selFilter.MessageTypes != "" && this.selFilter.MessageTypes.length > 0) {
                var tmpList = this.selFilter.MessageTypes.trim().split(',');
                tmpList.forEach((bnId, idx) => {
                    var bn = this.lstMessageTypesBN.find(x => x.Id == +bnId);
                    this.lstPropertyMessageTypeBNSelected.push(bn);
                });

                this.lstPropertyMessageTypeBN.forEach((messagetype, idx) => {
                    this.lstPropertyMessageTypeBNSelected.forEach((activeMessagetype) => {
                        if (messagetype.MessageType.Id === activeMessagetype.Id) {
                            this.lstPropertyMessageTypeBN[idx].Active = 1;
                        }
                    });
                });
                this.allSelectedMessageTypes = this.lstPropertyMessageTypeBN.length === this.lstPropertyMessageTypeBNSelected.length;
            }

        }

        if (theProperty == 'Senders') {
            for (const party of this.lstPartiesBN) {
                const data = { 'Party': party, 'Active': 0 };
                this.lstPropertySenderBN.push(data);
            }

            if (this.selFilter.Senders != null && this.selFilter.Senders != "" && this.selFilter.Senders.length > 0) {
                var tmpList = this.selFilter.Senders.trim().split(',');
                tmpList.forEach((bnId, idx) => {
                    var bn = this.lstPartiesBN.find(x => x.Id == +bnId);
                    this.lstPropertySenderBNSelected.push(bn);
                });

                this.lstPropertySenderBN.forEach((party, idx) => {
                    this.lstPropertySenderBNSelected.forEach((activeParty) => {
                        if (party.Party.Id === activeParty.Id) {
                            this.lstPropertySenderBN[idx].Active = 1;
                        }
                    });
                });
            }
            this.allSelectedSenders = this.lstPropertySenderBN.length === this.lstPropertySenderBNSelected.length;
        }

        if (theProperty == 'Receivers') {
            for (const party of this.lstPartiesBN) {
                const data = { 'Party': party, 'Active': 0 };
                this.lstPropertyReceiverBN.push(data);
            }

            if (this.selFilter.Receivers != null && this.selFilter.Receivers != "" && this.selFilter.Receivers.length > 0) {
                var tmpList = this.selFilter.Receivers.trim().split(',');
                tmpList.forEach((bnId, idx) => {
                    var bn = this.lstPartiesBN.find(x => x.Id == +bnId);
                    this.lstPropertyReceiverBNSelected.push(bn);
                });

                this.lstPropertyReceiverBN.forEach((party, idx) => {
                    this.lstPropertyReceiverBNSelected.forEach((activeParty) => {
                        if (party.Party.Id === activeParty.Id) {
                            this.lstPropertyReceiverBN[idx].Active = 1;
                        }
                    });
                });
            }
            this.allSelectedReceivers = this.lstPropertyReceiverBN.length === this.lstPropertyReceiverBNSelected.length;
        }

        if (theProperty == 'Transactionstatusses') {

            for (const TransactionStatus of this.lstTransactionStatus) {
                const data = { 'TransactionStatus': TransactionStatus, 'Active': 0 };
                this.lstPropertyTransactionStatusBN.push(data);
            }
            if (this.selFilter.TransactionStatus != null && this.selFilter.TransactionStatus != "" && this.selFilter.TransactionStatus.length > 0) {
                var tmpList = this.selFilter.TransactionStatus.trim().split(',');
                tmpList.forEach((bnId, idx) => {
                    var bn = this.lstTransactionStatus.find(x => x.Id == +bnId);
                    this.lstPropertyTransactionStatusBNSelected.push(bn);
                });

                this.lstPropertyTransactionStatusBN.forEach((transactionStatus, idx) => {
                    this.lstPropertyTransactionStatusBNSelected.forEach((activeTransactionStatus) => {
                        if (transactionStatus.TransactionStatus.Id === activeTransactionStatus.Id) {
                            this.lstPropertyTransactionStatusBN[idx].Active = 1;
                        }
                    });
                });
            }
            this.allSelectedTransactionstatusses = this.lstPropertyTransactionStatusBN.length === this.lstPropertyTransactionStatusBNSelected.length;
        }

        if (theProperty == 'Failed Messages') {
            if (this.selFilter.FailedMessages)
                this.selFilter.FailedMessages = false;
            else
                this.selFilter.FailedMessages = true;
            this.saveChangeFilter(this.selFilter);
        }
    }

    public getMTBusinessNameById(ids: string) {
        var businessNames = '';
        if (ids != null && ids != "" && ids.length > 0) {
            var tmpList = ids.trim().split(',');
            tmpList.forEach((bnId, idx) => {
                var bn = this.lstMessageTypesBN.find(x => x.Id == +bnId);
                businessNames += bn.BusinessName;
                if (idx < tmpList.length - 1) {
                    businessNames += ', ';
                }
            });
        }
        return businessNames;
    }

    public getPartyBusinessNameById(ids: string) {
        var businessNames = '';
        if (ids != null && ids != "" && ids.length > 0) {
            var tmpList = ids.trim().split(',');
            tmpList.forEach((bnId, idx) => {
                var bn = this.lstPartiesBN.find(x => x.Id == +bnId);
                businessNames += bn.BusinessName;
                if (idx < tmpList.length - 1) {
                    businessNames += ', ';
                }
            });
        }
        return businessNames;
    }

    public getTransactionBusinessNameById(ids: string) {
        var businessNames = '';
        if (ids != null && ids != "" && ids.length > 0) {
            var tmpList = ids.trim().split(',');
            tmpList.forEach((bnId, idx) => {
                var bn = this.lstTransactionStatus.find(x => x.Id == +bnId);
                businessNames += bn.TransactionStatus;
                if (idx < tmpList.length - 1) {
                    businessNames += ', ';
                }
            });
        }
        return businessNames;
    }

    //Change filter
    public saveChangeFilter(filter: TnTFilter) {
        if (filter != null) {
            this.svcAPI.changeFilter(filter)
                .pipe(catchError((error) => {
                    return of(null); // Return empty message
                }))
                .subscribe(
                    response => {
                        if (response) {

                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Saved',
                                timeout: 2000,
                                customClass: 'snackBarSucces',
                                action: {
                                    text: 'X'
                                }
                            });

                        }
                        else if (response == null) {
                            this.allSelectedMessageTypes = this.lstPropertyMessageTypeBN.length === this.lstPropertyMessageTypeBNSelected.length;
                            this.allSelectedSenders = this.lstPropertySenderBN.length === this.lstPropertySenderBNSelected.length;
                            this.allSelectedReceivers = this.lstPropertyReceiverBN.length === this.lstPropertyReceiverBNSelected.length;
                            this.allSelectedTransactionstatusses = this.lstPropertyTransactionStatusBN.length === this.lstPropertyTransactionStatusBNSelected.length;
                        }
                    });
        }
    }

    //Properties click select all
    public clickSelectAll(property: string) {
        if (property == 'Message types') {
            this.allSelectedMessageTypes = !this.allSelectedMessageTypes;
            if (this.allSelectedMessageTypes) {
                this.lstMessageTypesBN.forEach((messagetype, idx) => {
                    this.lstPropertyMessageTypeBN[idx].Active = 1;
                });
                this.saveFilterMessageTypes();
            } else {
                this.lstMessageTypesBN.forEach((messagetype, idx) => {
                    this.lstPropertyMessageTypeBN[idx].Active = 0;
                });
                this.saveFilterMessageTypes();
            }
        }

        if (property == 'Senders') {
            
            this.allSelectedSenders = !this.allSelectedSenders;
            if (this.allSelectedSenders) {
                
                this.lstPartiesBN.forEach((messagetype, idx) => {
                    this.lstPropertySenderBN[idx].Active = 1;
                });
                this.saveFilterSenders();
            } else {
                
                this.lstPartiesBN.forEach((messagetype, idx) => {
                    this.lstPropertySenderBN[idx].Active = 0;
                });
                this.saveFilterSenders();
            }
        }

        if (property == 'Receivers') {
            
            this.allSelectedReceivers = !this.allSelectedReceivers;
            if (this.allSelectedReceivers) {
                
                this.lstPartiesBN.forEach((messagetype, idx) => {
                    this.lstPropertyReceiverBN[idx].Active = 1;
                });
                this.saveFilterReceivers();
            } else {
                
                this.lstPartiesBN.forEach((messagetype, idx) => {
                    this.lstPropertyReceiverBN[idx].Active = 0;
                });
                this.saveFilterReceivers();
            }
        }

        if (property == 'Transactionstatusses') {
            this.allSelectedTransactionstatusses = !this.allSelectedTransactionstatusses;
            if (this.allSelectedTransactionstatusses) {
                
                this.lstTransactionStatus.forEach((messagetype, idx) => {
                    this.lstPropertyTransactionStatusBN[idx].Active = 1;
                });
                this.saveFilterTransactionStatus();
            } else {
                
                this.lstTransactionStatus.forEach((messagetype, idx) => {
                    this.lstPropertyTransactionStatusBN[idx].Active = 0;
                });
                this.saveFilterTransactionStatus();
            }
        }
    }

    //Properties clicked funtions - MessageTypes

    public propMessageTypeClick(clickedMessageType: any): void {
        this.lstPropertyMessageTypeBN.forEach((messagetype, idx) => {
            if (messagetype.MessageType.Id === clickedMessageType.MessageType.Id) {
                if (clickedMessageType.Active === 0)
                    this.lstPropertyMessageTypeBN[idx].Active = 1;
                else
                    this.lstPropertyMessageTypeBN[idx].Active = 0;
            }
        });
        this.saveFilterMessageTypes();
    }

    public saveFilterMessageTypes(): void {
        const activeMessageTypeBNIds: number[] = [];
        this.lstPropertyMessageTypeBN.forEach((messagetype, idx) => {
            if (messagetype.Active === 1) {
                activeMessageTypeBNIds.push(messagetype.MessageType.Id);
            }
        });
        var strActiveMessageTypeBNIds: string = "";
        activeMessageTypeBNIds.forEach((mtId, idx) => {
            strActiveMessageTypeBNIds += mtId;
            if (idx < activeMessageTypeBNIds.length - 1) {
                strActiveMessageTypeBNIds += ', ';
            }
        });

        this.selFilter.MessageTypes = strActiveMessageTypeBNIds;
        this.saveChangeFilter(this.selFilter);
    }

    //Properties clicked funtions - Senders

    public propSendersClick(clickedSender: any): void {
        this.lstPropertySenderBN.forEach((sender, idx) => {
            if (sender.Party.Id === clickedSender.Party.Id) {
                if (clickedSender.Active === 0)
                    this.lstPropertySenderBN[idx].Active = 1;
                else
                    this.lstPropertySenderBN[idx].Active = 0;
            }
        });
        this.saveFilterSenders();
    }

    public saveFilterSenders(): void {
        const activeSenderBNIds: number[] = [];
        this.lstPropertySenderBN.forEach((sender, idx) => {
            if (sender.Active === 1) {
                activeSenderBNIds.push(sender.Party.Id);
            }
        });
        var strActiveSenderBNIds: string = "";
        activeSenderBNIds.forEach((mtId, idx) => {
            strActiveSenderBNIds += mtId;
            if (idx < activeSenderBNIds.length - 1) {
                strActiveSenderBNIds += ', ';
            }
        });

        this.selFilter.Senders = strActiveSenderBNIds;
        this.saveChangeFilter(this.selFilter);
    }


    //Properties clicked funtions - Receivers

    public propReceiversClick(clickedReceiver: any): void {
        this.lstPropertyReceiverBN.forEach((sender, idx) => {
            if (sender.Party.Id === clickedReceiver.Party.Id) {
                if (clickedReceiver.Active === 0)
                    this.lstPropertyReceiverBN[idx].Active = 1;
                else
                    this.lstPropertyReceiverBN[idx].Active = 0;
            }
        });
        this.saveFilterReceivers();
    }

    public saveFilterReceivers(): void {
        const activeReceiverBNIds: number[] = [];
        this.lstPropertyReceiverBN.forEach((sender, idx) => {
            if (sender.Active === 1) {
                activeReceiverBNIds.push(sender.Party.Id);
            }
        });
        var strActiveReceiverBNIds: string = "";
        activeReceiverBNIds.forEach((mtId, idx) => {
            strActiveReceiverBNIds += mtId;
            if (idx < activeReceiverBNIds.length - 1) {
                strActiveReceiverBNIds += ', ';
            }
        });

        this.selFilter.Receivers = strActiveReceiverBNIds;
        this.saveChangeFilter(this.selFilter);
    }

    //Property - Reference

    public saveFilterReference(): void {
        clearInterval(this.timer);
        this.timer = setInterval(() => {
            this.saveChangeFilter(this.selFilter);
            clearInterval(this.timer);
        }, 800);
    }
    

    //Properties clicked funtions - TransactionStatus

    public propTransactionStatusClick(clickedTransactionStatus: any): void {
        this.lstPropertyTransactionStatusBN.forEach((TransactionStatus, idx) => {
            if (TransactionStatus.TransactionStatus.Id === clickedTransactionStatus.TransactionStatus.Id) {
                if (clickedTransactionStatus.Active === 0)
                    this.lstPropertyTransactionStatusBN[idx].Active = 1;
                else
                    this.lstPropertyTransactionStatusBN[idx].Active = 0;
            }
        });
        this.saveFilterTransactionStatus();
    }

    public saveFilterTransactionStatus(): void {
        const activeTransactionStatusBNIds: number[] = [];
        this.lstPropertyTransactionStatusBN.forEach((TransactionStatus, idx) => {
            if (TransactionStatus.Active === 1) {
                activeTransactionStatusBNIds.push(TransactionStatus.TransactionStatus.Id);
            }
        });

        var strActiveTransactionStatusBNIds: string = "";
        activeTransactionStatusBNIds.forEach((mtId, idx) => {
            strActiveTransactionStatusBNIds += mtId;
            if (idx < activeTransactionStatusBNIds.length - 1) {
                strActiveTransactionStatusBNIds += ', ';
            }
        });

        this.selFilter.TransactionStatus = strActiveTransactionStatusBNIds;
        this.saveChangeFilter(this.selFilter);
    }

    public removeFilter(theFilter: TnTFilter): void {
        this.svcAPI.deleteFilter(theFilter).subscribe(
            (res) => {
                this.refreshFilters();

                const _this = this;
                this.snackbarService.add({
                    msg: 'Removed',
                    timeout: 2000,
                    customClass: 'snackBarSucces',
                    action: {
                        text: 'X'
                    }
                });

            });
    }

    public addNewFilter(): void {
        const theFilter: TnTFilter = <TnTFilter>{
            Name: this.strNewFilterName
        };
        this.svcAPI.addFilter(theFilter).subscribe(
            (res) => {

                const _this = this;
                this.snackbarService.add({
                    msg: 'Added',
                    timeout: 2000,
                    customClass: 'snackBarSucces',
                    action: {
                        text: 'X'
                    }
                });

                    this.svcAPI.getFilters().subscribe(
                        filters => {
                            this.lstFilters = filters;
                            this.filterSelected(this.lstFilters.find(x => x.Name === this.strNewFilterName));
                            this.strNewFilterName = '';
                      } 
                );

                
            },
            (err) => {
                const _this = this;
                this.snackbarService.add({
                    msg: 'Name is not unique',
                    timeout: 2000,
                    customClass: 'snackBarError',
                    action: {
                        text: 'X'
                    }
                });

            });
    }

    public editFilterName(theFilter: TnTFilter): void {
        this.blnEditFilterName = !this.blnEditFilterName;
    }

    public saveFilterName(): void {
        clearInterval(this.timer);
        this.timer = setInterval(() => {
            this.saveChangeFilter(this.selFilter);
            clearInterval(this.timer);
        }, 800);
    }

    public refreshFilters(): void {
        // Retrieve list of Filters
        this.svcAPI.getFilters().subscribe(
            filters => {
                this.lstFilters = filters;
            }
        );
    }
}
