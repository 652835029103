import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, NavigationExtras } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private oauthService: OAuthService, private svcRouter: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()) {
            return true;
        }

        const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'preserve',
            preserveFragment: true,
            state: {
                reqURL: state.url
            }
        };

        // Redirect the user
        this.svcRouter.navigate(['login'], navigationExtras);
        return false;
    }
}
