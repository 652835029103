import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../_services/';

@Component({
    styleUrls: ['../configuration.component.scss','./configuration_storageAccounts.component.scss'],
    templateUrl: './configuration_storageAccounts.component.html',
})
export class ConfigurationStorageAccountsComponent implements OnInit {
    // Loader
    public isLoading: boolean;

    public lstStorageAccount: Array<TnTStorageAccount> = [];
    public selStorageAccount: TnTStorageAccount; // Pointer to selected element in lstStorageAccount
    public edtStorageAccount: TnTStorageAccount; // Will contain edited values for selected setting until another row is clicked, the changes are saved & this value is reset
    public newStorageAccount: TnTStorageAccount = <TnTStorageAccount>{}; // Contains new setting values

    public strError: string = '';
    public strNewError: string = '';

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router) {}

    public ngOnInit() {
        this.refreshData();

    }

    public refreshData(): void {
        // Retrieve list of settings
        this.svcAPI.getStorageAccounts().subscribe(
            storageAccount => {
                this.lstStorageAccount = storageAccount;
            }
        );
    }

    public rowClicked(theStorageAccount: TnTStorageAccount) {
        if (this.selStorageAccount !== theStorageAccount) {
            this.strError = ''; // Reset error msg
            this.saveChanges();
            this.selStorageAccount = theStorageAccount;
            this.edtStorageAccount = Object.assign({}, theStorageAccount); // Copy properties, not just pointer to heap
        }
    }

    private saveChanges() {
        // API call to save changes
        if (this.selStorageAccount != null && this.edtStorageAccount != null) {
            if (this.selStorageAccount.Name !== this.edtStorageAccount.Name || this.selStorageAccount.ContainerName !== this.edtStorageAccount.ContainerName || this.selStorageAccount.ConnectionString !== this.edtStorageAccount.ConnectionString)
            {
                this.selStorageAccount = this.edtStorageAccount;
                this.svcAPI.changeStorageAccount(this.edtStorageAccount).subscribe(
                    (res) => {
                        this.refreshData();
                        this.selStorageAccount = null;
                    },
                (err) => {
                    this.strError = err.error;
                });
            }
        }
    }

    public removeStorageAccount(theStorageAccount: TnTStorageAccount): void {
        this.svcAPI.deleteStorageAccount(theStorageAccount).subscribe(
        (res) => {
            this.refreshData();
        },
        (err) => {
            this.selStorageAccount = theStorageAccount;
            this.strError = err.error;
        });
    }

    public addStorageAccount(): void {
        this.svcAPI.addStorageAccount(this.newStorageAccount).subscribe(
            (res) => {
                this.newStorageAccount = <TnTStorageAccount>{}; // Reset storageaccount
                this.refreshData();
            },
            (err) => {
                
            });
    }

    public validatenewStorageAccount(): boolean {
        let valid: boolean = true;
        //if (!this.isNumber(this.newStorageAccount.Id) || this.newStorageAccount.Id < 0 || this.newStorageAccount.Id > 255) valid = false;
        //if (!this.newStorageAccount.Logo) valid = false;
        //if (!this.newStorageAccount.PageTitle) valid = false;
        //if (!this.newStorageAccount.PageSubTitle) valid = false;

        return valid;
    }

    private isNumber(value: string | number): boolean {
        return ((value != null) && !isNaN(Number(value.toString())));
    }
}
