<!-- Header -->
<div class="container-fluid paddingTop">
    <h1 class="display-4 display">Storage accounts</h1>
</div>

<!-- Content -->
<div class="row paddingTable container-fluid">
    <div class="col-md-12 paddingBottomTable">
        <div class="card">
            <div class="table-responsive" style="overflow: inherit">
                <table class="table table-hover p-3 mb-5 tableLikeCard" style="overflow: inherit">
                    <thead class="text-primary-inverse">
                        <tr>
                            <th class="smallTD-6 firstCell centerText">ID</th>
                            <th>Name</th>
                            <th>Container Name</th>
                            <th>Connection String</th>
                            <th class="smallTD"></th>
                        </tr>
                    </thead>
                    <tbody class="pointer">
                        <!-- Messages -->
                        <tr>
                            <td colspan="5" class="nomsg text-muted" *ngIf="!isLoading && lstStorageAccount.length == 0">
                                No storageaccounts found.
                            </td>
                            <td colspan="5" *ngIf="isLoading">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                        Loading storageaccounts
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- Add settings row -->
                        <tr id="addRow">
                            <td class="smallTD-6 firstCell centerText"></td>
                            <td class="smallTD">
                                <input name="newStorageAccountName"
                                       class="form-control"
                                       [(ngModel)]="newStorageAccount.Name"
                                       width="5" />
                            </td>
                            <td class="smallTD">
                                <input name="newStorageAccountContainerName"
                                       class="form-control"
                                       [(ngModel)]="newStorageAccount.ContainerName"
                                       width="5" />
                            </td>
                            <td class="smallTD">
                                <input name="newStorageAccountConnectionString"
                                       class="form-control"
                                       [(ngModel)]="newStorageAccount.ConnectionString"
                                       width="5" />
                            </td>
                            <td class="smallTD">
                                <button class="btn btn-outline-primary" type="submit" [disabled]="!validatenewStorageAccount()" (click)="validatenewStorageAccount() && addStorageAccount()">
                                    <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
                                </button>
                            </td>
                        </tr>

                        <!-- Data rows -->
                        <ng-container *ngIf="!isLoading">
                            <tr *ngFor="let theStorageAccount of lstStorageAccount" class="statustable" [class.active]="selStorageAccount != null && theStorageAccount.ID == selStorageAccount.ID" (click)="rowClicked(theStorageAccount)">
                                <td class="smallTD-6 firstCell centerText">{{theStorageAccount.ID}}</td>
                                <td>
                                    <span *ngIf="selStorageAccount == null || theStorageAccount.ID != selStorageAccount.ID">{{theStorageAccount.Name}}</span>
                                    <input #inputSetting class="form-control" *ngIf="selStorageAccount != null && theStorageAccount.ID == selStorageAccount.ID" [(ngModel)]="edtStorageAccount.Name" />
                                    <p class="small errorStatus" *ngIf="selStorageAccount != null && selStorageAccount.ID == theStorageAccount.ID && strError != ''">{{strError}}</p>
                                </td>
                                <td class="ellipsis">
                                    <span *ngIf="selStorageAccount == null || theStorageAccount.ID != selStorageAccount.ID">{{theStorageAccount.ContainerName}}</span>
                                    <input #inputSetting class="form-control" *ngIf="selStorageAccount != null && theStorageAccount.ID == selStorageAccount.ID" [(ngModel)]="edtStorageAccount.ContainerName" />
                                    <p class="small errorStatus" *ngIf="selStorageAccount != null && selStorageAccount.ID == theStorageAccount.ID && strError != ''">{{strError}}</p>
                                </td>
                                <td class="ellipsis">
                                    <span *ngIf="selStorageAccount == null || theStorageAccount.ID != selStorageAccount.ID">{{theStorageAccount.ConnectionString}}</span>
                                    <input #inputSetting class="form-control" *ngIf="selStorageAccount != null && theStorageAccount.ID == selStorageAccount.ID" [(ngModel)]="edtStorageAccount.ConnectionString" />
                                    <p class="small errorStatus" *ngIf="selStorageAccount != null && selStorageAccount.ID == theStorageAccount.ID && strError != ''">{{strError}}</p>
                                </td>
                                <td class="smallTD">
                                    <div class="form-group">
                                        <span (click)="removeStorageAccount(theStorageAccount)" class="hoverTrashIcon"><i-feather name="trash"></i-feather></span>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
