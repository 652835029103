import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigurationService } from '../_services';

import { OAuthService, AuthConfig} from 'angular-oauth2-oidc';

@Component({
    styleUrls: ['./login.component.scss'],
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
    private reqURL;

    constructor(public svcConfiguration: ConfigurationService,
        private svcRouter: Router,
        private oauthService: OAuthService) {
        this.reqURL = '/';
        
        if (svcRouter.getCurrentNavigation().extras.state !== undefined && 'reqURL' in svcRouter.getCurrentNavigation().extras.state) {
            this.reqURL = svcRouter.getCurrentNavigation().extras.state['reqURL'];
        }

        this.svcConfiguration.SETTINGS.subscribe((res) => {
            if (res) {
                this.configureWithNewConfigApi(res);
                this.oauthService.loadDiscoveryDocument(this.oauthService.issuer +  '/.well-known/openid-configuration').then((doc) => {
                    this.oauthService.tryLogin().then(() => {
                        this.svcRouter.navigateByUrl(decodeURIComponent(this.oauthService.state));
                    }).then(() => {
                        if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
                            this.oauthService.initCodeFlow(this.reqURL);
                        }
                    });
                });   
            }
        });
    }

    private configureWithNewConfigApi(theSettings: TnTSettings) {
        const URL = window.location.origin;

        const dIMAuthConfig: AuthConfig = {
            // Url of the Identity Provider
            issuer: theSettings.ADServer,
            
            // URL of the SPA to redirect the user to after login
            redirectUri: URL + '/login',

            silentRefreshRedirectUri: URL + '/assets/silent-refresh.html',
            logoutUrl: URL + '/logout',

            // The SPA's id. The SPA is registerd with this id at the auth-server
            clientId: theSettings.ClientID,

            requireHttps: false,

            skipIssuerCheck: false, 

            disableAtHashCheck: false,

            // Set the scope for the permissions the client should request
            scope: 'profile openid api://' + theSettings.ClientID + '/dimapi',        //   api://{clientid}/dimapi

            // Use responseType 'code' to use code flow
            responseType: 'code',

            strictDiscoveryDocumentValidation: false, // Unsupported for Azure AD

            postLogoutRedirectUri: URL + '/logout'
        };

        this.oauthService.configure(dIMAuthConfig);

        //set state so user is redirected to requested page after login
        this.oauthService.state = encodeURIComponent(this.reqURL);
        
        this.oauthService.setupAutomaticSilentRefresh();

    }

    ngOnInit() {
    }
}
