<ng-template #filterParty>
    <div class="filterwidth">
        <input type="text" #inputPartySearch class="form-control" placeholder="Party" [(ngModel)]="srcParty" (keyup.enter)="getParties(); popoverParty.hide()">
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="getParties(); popoverParty.hide();"><i-feather name="filter" class="icon-small"></i-feather></button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearSearchParty(); popoverParty.hide();"><i-feather name="trash" class="icon-small"></i-feather></button>
        </div>
    </div>
</ng-template>

<ng-template #filterPartyBusinessName>
    <div class="filterwidth">
        <ng-select [items]="lstPartiesBusinessNames"
                   [multiple]="false"
                   [clearable]="true"
                   bindLabel="BusinessName"
                   [(ngModel)]="selPartyBnFilter">
        </ng-select>
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="getParties(); popoverPartyBusinessName.hide();"><i-feather name="filter" class="icon-small"></i-feather></button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearSearchPartyBusinessName(); popoverPartyBusinessName.hide();"><i-feather name="trash" class="icon-small"></i-feather></button>
        </div>
    </div>
</ng-template>

<div class="container-fluid paddingTop">
    <h1 class="display-4 display">Party administration</h1>
    <button class="btn btn-primary float-right addButton" type="button" (click)="btnAddEnabledClicked()">
        <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
    </button>
</div>
<div class="row paddingTable container-fluid">
    <div class="col-md-12 paddingBottomTable">
        <div class="card">
            <div class="table-responsive" style="overflow: inherit">
                <table class="table table-hover p-3 mb-5 tableLikeCard">
                    <thead class="text-primary-inverse">
                        <tr>
                            <th class="col-6 firstCell">Party <i-feather name="filter" [popover]="filterParty" placement="right" #popoverParty="bs-popover" class="filterIcon" [ngClass]="{'fill': blnSearchPartyApplied}" (onShown)="focusInputSearchParty()"></i-feather></th>
                            <th class="col-6">Businessname <i-feather name="filter" [popover]="filterPartyBusinessName" #popoverPartyBusinessName="bs-popover" class="filterIcon" [ngClass]="{'fill': blnSearchPartyBusinessNameApplied}"></i-feather></th>
                            <th  class="smallTD-6 centerText"></th>
                        </tr>
                    </thead>
                    <tbody class="pointer">
                        <tr>
                            <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstParties.length == 0">
                                No parties found.
                            </td>
                            <td colspan="12" *ngIf="isLoading">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                        Loading parties
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr id="lastTR" *ngIf="AddEnabled">
                            <td class="col-6">
                                <input #inputNewStatus class="form-control" [(ngModel)]="strNewParty" placeholder="Party name" />
                            </td>
                            <td class="col-6">
                                <ng-select placeholder="Select business name"
                                           [items]="lstPartiesBusinessNames"
                                           [multiple]="false"
                                           [clearable]="true"
                                           bindLabel="BusinessName"
                                           [(ngModel)]="selNewPartyBn">
                                </ng-select>
                            </td>
                            <td class="smallTD-6 centerText">
                                <button class="btn btn-outline-primary" type="button" (click)="btnAddPartyClicked()">
                                    <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
                                </button>
                            </td>
                        </tr>

                        <tr *ngFor="let party of lstParties; let i = index" [class.active]="i == selectedRow" (click)="partyClicked(i,undefined,undefined)">
                            <ng-container *ngIf="!isLoading">
                                <td class="firstCell ellipsis col-6" (click)="partyClicked(i,party,undefined)">
                                    <div class="form-group">
                                        <span *ngIf="selPartyId != party.Id">{{party.PartyName}}</span>
                                        <input #inputParty class="form-control" *ngIf="selPartyId == party.Id" [(ngModel)]="party.PartyName" (input)="partyChanged(party)" />
                                    </div>
                                </td>

                                <td class="col-6" (click)="partyClicked(i,undefined,party)" [ngClass]="{'ellipsis': selPartyIdOfBn != party.Id}">
                                    <span *ngIf="selPartyIdOfBn != party.Id"> {{party.PartyBusinessName}}</span>

                                    <ng-select *ngIf="selPartyIdOfBn == party.Id"
                                               [items]="lstPartiesBusinessNames"
                                               [multiple]="false"
                                               [clearable]="true"
                                               bindLabel="BusinessName"
                                               bindValue="Id"
                                               [(ngModel)]="selPartyBnId"
                                               (ngModelChange)="bnPartyChanged(party)">
                                    </ng-select>

                                </td>
                                <td class="smallTD-6 centerText">
                                    <span (click)="removeParty(party)" class="hoverTrashIcon"><i-feather name="trash"></i-feather></span>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
