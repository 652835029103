import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../_services/';

@Component({
    styleUrls: ['./userportal.component.scss'],
    templateUrl: './userportal.component.html',
    encapsulation: ViewEncapsulation.None
})

export class UserportalComponent implements OnInit {
    constructor(private svcAPI: TnTAPIService, private svcRouter: Router) { }

    public activeUsers: boolean;
    public activeGroups: boolean;

    public ngOnInit() {
        switch (this.svcRouter.url) {
            case '/portal/users':
                {
                    this.activeUsers = true;
                    this.activeGroups = false;
                    break;
                }
            case '/portal/groups':
                {
                    this.activeGroups = true;
                    this.activeUsers = false;
                    break;
                }
        }
    }

    public clickUsers() {
        this.svcRouter.navigate(['/portal/users']);
    }

    public clickGroups() {
        this.svcRouter.navigate(['/portal/groups']);
    }
}
