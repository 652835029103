<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="detail-header" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title overflow">
                    <div class="c-detail-wrapper">
                        <h4 id="detail-header" *ngIf="theCondition">
                            {{ theCondition.Name }}
                        </h4>
                    </div>
                </div>
                <div class="pull-right">
                    <button type="button" class="close" aria-label="Close" (click)="hideChildModal()">
                        <i-feather name="x" class="modaltitlebutton"></i-feather>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <div class="detail-content" *ngIf="!isLoading">
                    <!-- General type details -->
                    <div class="row paddingUPContent">
                        <div class="col-md-3">
                            <div class="card">
                                <div class="card-header card-header-groups text-primary-inverse">
                                    Groups
                                </div>
                                <div class="card-body nopadding scrollCardNoFooter pointer">
                                    <ul class="list-group">
                                        <li *ngFor="let group of lstGroups" (click)="selectGroup(group)" [value]="group.id" [class.active]="selGroupId == group.id" class="list-group-item list-group-item-action">
                                            <div class="form-row">
                                                <div class="col-md-11">
                                                    <span>{{group.text}}</span>
                                                </div>
                                                <div class=" col-md-1">
                                                    <span *ngIf="theCondition.Group.Id == group.id"><i-feather name="check-square"></i-feather></span>
                                                    <span *ngIf="theCondition.Group.Id != group.id" class="hoverEditIcon" (click)="editGroup(group)"><i-feather name="square"></i-feather></span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="selGroup != null">
                            <div class="card">
                                <div class="card-header bg-primary text-primary-inverse pointer" (click)="clickSelectAll('messagetypes')">
                                    <i-feather name="square" *ngIf="!allSelectedMessageTypes"></i-feather>
                                    <i-feather name="check-square" *ngIf="allSelectedMessageTypes"></i-feather>
                                    <span class="permissionsheader"><strong>{{selGroup.Groupname}}: </strong>MessageTypes</span>
                                </div>
                                <div class="card-body nopadding scrollCardNoFooter pointer">
                                    <ul class="list-group rights">
                                        <li class="list-group-item list-group-item-action" *ngFor="let messageType of lstMessageTypes" (click)="messageTypeClicked(messageType)">
                                            <span tooltip="{{messageType.MessageType.MessageType}}">
                                                <i-feather name="square" *ngIf="messageType.Active == 0"></i-feather>
                                                <i-feather name="check-square" *ngIf="messageType.Active != 0"></i-feather>
                                                {{messageType.MessageType.BusinessName}}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="selGroup != null">
                            <div class="card">
                                <div class="card-header bg-primary text-primary-inverse pointer" (click)="clickSelectAll('senders')">
                                    <i-feather name="square" *ngIf="!allSelectedSenders"></i-feather>
                                    <i-feather name="check-square" *ngIf="allSelectedSenders"></i-feather>
                                    <strong>{{selGroup.Groupname}}: </strong>Senders
                                </div>
                                <div class="card-body nopadding scrollCardNoFooter pointer">
                                    <ul class="list-group rights">
                                        <li class="list-group-item list-group-item-action" *ngFor="let party of lstSenders" [value]="party.Id" (click)="senderClicked(party)">
                                            <span>
                                                <i-feather name="square" *ngIf="party.Active == 0"></i-feather>
                                                <i-feather name="check-square" *ngIf="party.Active != 0"></i-feather>
                                                {{party.Party.PartyBusinessName}}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="selGroup != null">
                            <div class="card">
                                <div class="card-header bg-primary text-primary-inverse pointer" (click)="clickSelectAll('receivers')">
                                    <i-feather name="square" *ngIf="!allSelectedReceivers"></i-feather>
                                    <i-feather name="check-square" *ngIf="allSelectedReceivers"></i-feather>
                                    <strong>{{selGroup.Groupname}}: </strong>Receivers
                                </div>
                                <div class="card-body nopadding scrollCardNoFooter pointer">
                                    <ul class="list-group rights">
                                        <li class="list-group-item list-group-item-action" *ngFor="let party of lstReceivers" [value]="party.Id" (click)="receiverClicked(party)">
                                            <span>
                                                <i-feather name="square" *ngIf="party.Active == 0"></i-feather>
                                                <i-feather name="check-square" *ngIf="party.Active != 0"></i-feather>
                                                {{party.Party.PartyBusinessName}}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 aligner" *ngIf="selGroup == null">
                            <h1 class="display-4 display aligner-item">Select a group to edit messagetypes and parties</h1>
                        </div>
                    </div>

                </div>
                <div class="container-fluid" *ngIf="isLoading">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                            Loading
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row col-12">
                        <button class="btn btn-secondary col-5" (click)="hideChildModal()">
                            Close
                        </button>
                        <span class="col-2"></span>
                        <button class="btn btn-primary col-5" (click)="save()" [disabled]="showLoadingIcon">
                            <span *ngIf="!showLoadingIcon">Save</span>
                            <i-feather class="loader" *ngIf="showLoadingIcon"></i-feather>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>