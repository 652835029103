import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TnTAPIService } from '../_services/';
import { ModalDirective } from 'ngx-bootstrap';
import { Observable } from 'rxjs';

import { OAuthService } from 'angular-oauth2-oidc';

import * as srcDoc from 'srcdoc-polyfill';

@Component({
    styleUrls: ['./businessview.component.scss'],
    templateUrl: './businessview.component.html',
    selector: 'app-tnt-businessview'
})

export class BusinessviewComponent {
    @ViewChild('childModal', { static: true }) public childModal: ModalDirective;
    @ViewChild('frame', { static: true }) public frame: ElementRef;

    public theMessage: TnTMessage;
    public isLoading: boolean = true;
    public usrToken: string;

    constructor(private oauthService: OAuthService, private svcAPI: TnTAPIService, private svcRouter: Router) { }

    public showChildModal(retrMessage: TnTMessage): void {
        this.isLoading = true;
        this.theMessage = retrMessage;

        this.usrToken = this.oauthService.getAccessToken();

        // Retrieve businessview content for message
        this.svcAPI.getBusinessview(this.theMessage.Id).subscribe(
            bv => {
                // Set content
                srcDoc.set(this.frame.nativeElement, bv);
                // Disable loading indicator
                this.isLoading = false;
            }
        );

        // Show modal to user
        this.childModal.show();
    }

    public hideChildModal(): void {
        this.childModal.hide();
    }

    public printBusinessview(): void {
        window.frames['bview'].focus();
        window.frames['bview'].print();
    }
}
