import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

import { Observable, pipe, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class TnTAPIService {
    DeleteAlertingSubscriptionEndpoints(theEndpoint: TnTAlertingSubscriptionEndpoint) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.delete(this.baseURL + '/alerting/deleteendpoint/' + theEndpoint.Id, { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    DeleteAlertingSubscriptionEndpointHeader(theHeader: TnTAlertingSubscriptionEndpointHeader) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.delete(this.baseURL + '/alerting/deleteendpointheader/' + theHeader.Id, { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    DeleteAlertingCondition(theCondition: TnTAlertingCondition) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.delete(this.baseURL + '/alerting/deletecondition/' + theCondition.Id, { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    DeleteAlertingScheduler(theScheduler: TnTAlertingScheduler) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.delete(this.baseURL + '/alerting/deletescheduler/' + theScheduler.Id, { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    DeleteAlertingAction(theAction: TnTAlertingAction) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.delete(this.baseURL + '/alerting/deleteaction/' + theAction.Id, { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    AddAlertingAction(theAction: TnTAlertingAction) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.post(this.baseURL + '/alerting/addaction', JSON.stringify(theAction), { headers: headers }).pipe(
            map((res) => {
                return res;
            }));
    }
    AddAlertingScheduler(theScheduler: TnTAlertingScheduler) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.post(this.baseURL + '/alerting/addscheduler', JSON.stringify(theScheduler), { headers: headers }).pipe(
            map((res) => {
                return res;
            }));
    }
    AddAlertingCondition(condition: TnTAlertingCondition) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.post(this.baseURL + '/alerting/addcondition', JSON.stringify(condition), { headers: headers }).pipe(
            map((res) => {
                return res
            }));
    }
    addConditionMessageType(id: number, mtb: TnTMessageType) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.post(this.baseURL + '/alerting/addconditionmessagetype/' + id, JSON.stringify(mtb), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    deleteConditionMessageType(conditionId: number, messageTypeId: number) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.delete(this.baseURL + '/alerting/deleteconditionmessagetype/' + conditionId + '&' + messageTypeId, { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    addConditionSender(id: number, sender: TnTParty) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.post(this.baseURL + '/alerting/addconditionsender/' + id, JSON.stringify(sender), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    deleteConditionSender(conditionId: number, senderId: number) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.delete(this.baseURL + '/alerting/deleteconditionsender/' + conditionId + '&' + senderId, { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    addConditionReceiver(id: number, receiver: TnTParty) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.post(this.baseURL + '/alerting/addconditionreceiver/' + id, JSON.stringify(receiver), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    deleteConditionReceiver(conditionId: number, receiverId: number) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.delete(this.baseURL + '/alerting/deleteconditionreceiver/' + conditionId + '&' + receiverId, { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    addActionEndpoint(id: number, endpoint: TnTAlertingSubscriptionEndpoint) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.post(this.baseURL + '/alerting/addactionendpoint/' + id, JSON.stringify(endpoint), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    deleteActionEndpoint(actionId: number, endpointId: number) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.delete(this.baseURL + '/alerting/deleteactionendpoint/' + actionId + '&' + endpointId, { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    SaveEndpoints(endpoints: Array<TnTAlertingSubscriptionEndpoint>) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.post(this.baseURL + '/alerting/subscriptions/saveEndpoints', JSON.stringify(endpoints), { headers: headers }).pipe(
            map((res) => {
                return res
            }));
    }
    getAlertingEmails() {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<Array<TnTAlertingSubscriptionEndpoint>>(this.baseURL + '/alerting/subscriptions/emails', { params: params });
    }
    getAlertingWebhooks() {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<Array<TnTAlertingSubscriptionEndpoint>>(this.baseURL + '/alerting/subscriptions/webhooks', { params: params });
    }
    modifyAlerting(mt: TnTAlerting) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.put(this.baseURL + '/alerting/modifyalerting', JSON.stringify(mt), { headers: headers }).pipe(
            map((res) => {
                return res;
            }));
    }
    modifyAlertingSubscriptions(mt: TnTAlertingSubscription) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.put(this.baseURL + '/alerting/modifysubscription', JSON.stringify(mt), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    modifyAlertingCondition(condition: TnTAlertingCondition) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.put(this.baseURL + '/alerting/modifycondition', JSON.stringify(condition), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    modifyAlertingConditionAllMessageTypes(condition: TnTAlertingCondition) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.post(this.baseURL + '/alerting/modifyconditionallmessagetypes/' + condition.Id, { headers: headers }).pipe(
            map((res) => {
                if(res['Message'] == 'Success'){
                    return res['Value'];
                }
                return null;
            }));
    }
    modifyAlertingConditionAllSenderParties(condition: TnTAlertingCondition) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.post(this.baseURL + '/alerting/modifyconditionallsenderparties/' + condition.Id, { headers: headers }).pipe(
            map((res) => {
                if(res['Message'] == 'Success'){
                    return res['Value'];
                }
                return null;
            }));
    }
    modifyAlertingConditionAllReceiverParties(condition: TnTAlertingCondition) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.post(this.baseURL + '/alerting/modifyconditionallreceiverparties/' + condition.Id, { headers: headers }).pipe(
            map((res) => {
                if(res['Message'] == 'Success'){
                    return res['Value'];
                }
                return null;
            }));
    }
    modifyAlertingConditionGroup(condition: TnTAlertingCondition) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.put(this.baseURL + '/alerting/modifyconditiongroup', JSON.stringify(condition), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    modifyAlertingAction(action: TnTAlertingAction) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.put(this.baseURL + '/alerting/modifyaction', JSON.stringify(action), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    modifyAlertingScheduler(scheduler: TnTAlertingScheduler) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        console.log(scheduler)
        return this.authHttp.put(this.baseURL + '/alerting/modifyscheduler', JSON.stringify(scheduler), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public baseURL: string;

    constructor(private authHttp: HttpClient) {
        this.baseURL = './api';
    }


    public getAlertingSubscriptions(): Observable<Array<TnTAlertingSubscription>> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<Array<TnTAlertingSubscription>>(this.baseURL + '/alerting/getsubscriptions', { params: params });
    }
    public getAlertingActions(): Observable<Array<TnTAlertingAction>> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<Array<TnTAlertingAction>>(this.baseURL + '/alerting/getactions', { params: params });
    }
    public getAlertingSchedulers(): Observable<Array<TnTAlertingScheduler>> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<Array<TnTAlertingScheduler>>(this.baseURL + '/alerting/getschedulers', { params: params });
    }
    public getAlertingConditions(): Observable<Array<TnTAlertingCondition>> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<Array<TnTAlertingCondition>>(this.baseURL + '/alerting/getconditions', { params: params });
    }
    public getAlertingConditionMessageTypes(id:number): Observable<Array<TnTMessageType>> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<Array<TnTMessageType>>(this.baseURL + '/alerting/getconditionmessagetypes/' + id, { params: params });
    }
    public getAlertingConditionSenderParties(id:number): Observable<Array<TnTParty>> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<Array<TnTParty>>(this.baseURL + '/alerting/getconditionsenderparties/' + id, { params: params });
    }
    public getAlertingConditionReceiverParties(id:number): Observable<Array<TnTParty>> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<Array<TnTParty>>(this.baseURL + '/alerting/getconditionreceiverparties/' + id, { params: params });
    }
    public getAlerting(): Observable<TnTAlerting> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<TnTAlerting>(this.baseURL + '/alerting/getalerting', { params: params });
    }
    public getAlertingHistory(): Observable<Array<TnTAlertingHistory>> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<Array<TnTAlertingHistory>>(this.baseURL + '/alerting/getalertinghistory', { params: params });
    }

    public deletePurgingCustom(thePurgingCustomId: number): Observable<string> {
        return this.authHttp.delete(this.baseURL + '/purging/deletecustom/' + thePurgingCustomId).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public addPurgingCustom(thePurgingCustom: TnTPurgingCustom): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.post(this.baseURL + '/purging/addcustom', JSON.stringify(thePurgingCustom), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public getAllPurgingCustoms(): Observable<TnTPurgingCustom[]> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<TnTPurgingCustom[]>(this.baseURL + '/purging/customs', { params: params });
    }

    public getDefaultDays(): Observable<TnTPurgingDefault[]> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<TnTPurgingDefault[]>(this.baseURL + '/purging/defaultdays', { params: params });
    }

    public getPurgingHistory(interval: String): Observable<FETnTPurgingHistoryAmountPerType[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('interval',interval.toString());
        return this.authHttp.get<FETnTPurgingHistoryAmountPerType[]>(this.baseURL + '/purging/history', { params: params });
    }

    public getPurgingStatus(): Observable<TnTPurgingStatus[]> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<TnTPurgingStatus[]>(this.baseURL + '/purging/status', { params: params });
    }

    public addTransactionStatus(theStatus: TnTTransactionStatus): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/transactions/transactionstatus', JSON.stringify(theStatus), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Added']);
            }));
    }

    public deleteTransactionStatus(theStatus: TnTTransactionStatus): Observable<boolean> {

        return this.authHttp.delete(this.baseURL + '/transactions/transactionstatus/' + theStatus.Id).pipe(
            map((res) => {
                return Boolean(res['Deleted']);
            }));
    }

    public changeTransactionStatus(theStatus: TnTTransactionStatus): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/transactions/transactionstatus/' + theStatus.Id, JSON.stringify(theStatus), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Changed']);
            }));
    }

    public getTransactionStatusTypes(): Observable<TnTTransactionStatus[]> {
        return this.authHttp.get<TnTTransactionStatus[]>(this.baseURL + '/transactions/transactionstatus');
    }

    public getUserInfo(): Observable<TnTUserInfo> {
        return this.authHttp.get<TnTUserInfo>(this.baseURL + '/configuration/userinfo');
    }

    public getBusinessview(theMessageId: number): Observable<string> {
        return this.authHttp.get(this.baseURL + '/businessview/' + String(theMessageId), { responseType: 'text' });
    }

    public getBusinessViewValues(theMessageId: number): Observable<TnTBusinessView> {
        return this.authHttp.get<TnTBusinessView>(this.baseURL + '/businessview/' + String(theMessageId) + '/values');
    }

    public getAllMessageTypesThatHasBusinessView(): Observable<number[]> {
        return this.authHttp.get<number[]>(this.baseURL + '/businessview/allMessageTypesThatHasBusinessView');
    }

    public getBusinessViewsAvailable(theMessageId: number): Observable<boolean> {
        return this.authHttp.get(this.baseURL + '/businessview/' + String(theMessageId) + '/hasviews').pipe(
            map((res) => {
                return Boolean(res['HasViews']);
            }));
    }

    public getProcess(theProcessId: number): Observable<TnTProcess> {
        return this.authHttp.get<TnTProcess>(this.baseURL + '/process/' + String(theProcessId));
    }

    public getParentProcesses(theProcessId: number): Observable<TnTProcess[]> {
        return this.authHttp.get<TnTProcess[]>(this.baseURL + '/process/' + String(theProcessId) + '/parentprocesses');
    }


    public getVersion(): Observable<string> {
        return this.authHttp.get<string>(this.baseURL + '/configuration/fileversion').pipe(
            catchError((err) => {
                return of(null);
            }));
    }

    public portalRemGroup(theGroup: TnTGroup): Observable<boolean> {

        return this.authHttp.delete(this.baseURL + '/perm/groups/' + theGroup.Id).pipe(
            map((res) => {
                return Boolean(res['Deleted']);
            }));
    }

    public portalAddGroup(theGroup: TnTGroup): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/perm/groups/', JSON.stringify(theGroup), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Added']);
            }));
    }

    public portalModGroup(theGroup: TnTGroup): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/perm/groups/' + theGroup.Id, JSON.stringify(theGroup), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Changed']);
            }));
    }

    public portalAddMessageTypeBusinessName(theMessageTypeBusinessName: TnTMessageTypeBusinessName) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/messagetypes/addBusinessName/', JSON.stringify(theMessageTypeBusinessName), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Added']);
            }));
    }

    public portalAddMessageType(theMessageType: TnTMessageType) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/messagetypes/addMessageType/', JSON.stringify(theMessageType), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public portalSetGroupMessageTypes(theGroup: TnTGroup, theMessageTypes: number[]): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/perm/groups/' + theGroup.Id + '/messagetypes', JSON.stringify(theMessageTypes), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Added']);
            }));
    }

    public portalSetGroupParties(theGroup: TnTGroup, theParties: number[]): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/perm/groups/' + theGroup.Id + '/parties', JSON.stringify(theParties), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Added']);
            }));
    }

    public portalGetGroupParties(theGroupId: number): Observable<TnTParty[]> {
        return this.authHttp.get<TnTParty[]>(this.baseURL + '/perm/groups/' + theGroupId + '/parties');
    }

    public portalGetGroupMessageTypes(theGroupId: number): Observable<TnTMessageType[]> {
        return this.authHttp.get<TnTMessageType[]>(this.baseURL + '/perm/groups/' + theGroupId + '/messagetypes');
    }

    public portalGetGroups(): Observable<TnTGroup[]> {
        return this.authHttp.get<TnTGroup[]>(this.baseURL + '/perm/groups');
    }

    public portalGetGroupsFromUser(theUser: TnTUser): Observable<TnTGroup[]> {
        return this.authHttp.get<TnTGroup[]>(this.baseURL + '/perm/groups/fromUser/' + theUser.Id);
    }

    public portalSetUserUserGroups(theUser: TnTUser, theMessageTypes: number[]): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/perm/groups/' + theUser.Id + '/groupIds', JSON.stringify(theMessageTypes), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Added']);
            }));
    }

    public portalRemUser(theUser: TnTUser): Observable<boolean> {
        return this.authHttp.delete(this.baseURL + '/perm/users/' + theUser.Id).pipe(
            map((res) => {
                return Boolean(res['Deleted']);
            }));
    }

    public portalAddUser(theUser: TnTUser): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/perm/users/', JSON.stringify(theUser), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Added']);
            }));
    }

    public portalModUser(theUser: TnTUser): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/perm/users/' + theUser.Id, JSON.stringify(theUser), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Changed']);
            }));
    }

    public portalGetUsers(): Observable<TnTUser[]> {
        return this.authHttp.get<TnTUser[]>(this.baseURL + '/perm/users');
    }

    public portalGetUserCount(): Observable<number> {
        return this.authHttp.get(this.baseURL + '/perm/users/count').pipe(
            map((res) => {
                return Number(res['Count']);
            }));
    }


    public getProcessInclParentsWithMessages(theProcessId: number): Observable<TnTProcessWithMessages[]> {
        return this.authHttp.get<any>(this.baseURL + '/process/' + String(theProcessId) + '/inclParentsAndMessages');
    }

    public getTransactions(theProcessId: number): Observable<TnTTransaction[]> {
        return this.authHttp.get<TnTTransaction[]>(this.baseURL + '/transactions/p/' + String(theProcessId));
    }

    public getTransactionsTimeLine(theProcessId: number): Observable<TnTTransaction[]> {
        return this.authHttp.get<TnTTransaction[]>(this.baseURL + '/transactions/tl/' + String(theProcessId));
    }

    public getMessage(theMessageId: number): Observable<TnTMessage> {
        return this.authHttp.get<TnTMessage>(this.baseURL + '/messages/' + String(theMessageId));
    }

    public getFilters(): Observable<TnTFilter[]> {
        return this.authHttp.get<TnTFilter[]>(this.baseURL + '/filters/all');
    }

    public changeFilter(theFilter: TnTFilter): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/filters/' + theFilter.Id, JSON.stringify(theFilter), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Changed']);
            }));
    }

    public addFilter(theFilter: TnTFilter): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/filters', JSON.stringify(theFilter), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Added']);
            }));
    }

    public deleteFilter(theFilter: TnTFilter): Observable<boolean> {

        return this.authHttp.delete(this.baseURL + '/filters/' + theFilter.Id).pipe(
            map((res) => {
                return Boolean(res['Deleted']);
            }));
    }

    public getMessageDownload(theMessageId: number): Observable<any> {
        return this.authHttp.get(this.baseURL + '/messages/' + String(theMessageId) + '/download', { responseType: 'blob', observe: 'response' });
    }
    
    public getZipDownload(messageIdLst: number[]): Observable<any> {
        return this.authHttp.post(this.baseURL + '/messages/' + 'download/multi', messageIdLst, { responseType: 'blob', observe: 'response' });
    }

    public getExport(messageIdLst: number[]): Observable<any> {
        return this.authHttp.post(this.baseURL + '/messages/' + 'export', messageIdLst, { responseType: 'blob', observe: 'response' });
    }

    public getParties(usePermissions: boolean = false, srcParty?: string, selectPartyBusinessNameSearch?: TnTPartyBusinessName): Observable<TnTParty[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('usePermissions', String(usePermissions));
        if (srcParty && srcParty !== '') params = params.append('srcParty', srcParty);
        if (selectPartyBusinessNameSearch && selectPartyBusinessNameSearch !== null) params = params.append('srcPartyBusinessNameSearch', selectPartyBusinessNameSearch.Id.toString());

        return this.authHttp.get<TnTParty[]>(this.baseURL + '/parties', { params: params });
    }

    public getPartiesBusinessNames(usePermissions: boolean = false, srcPartyBn?: string): Observable<TnTPartyBusinessName[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('usePermissions', String(usePermissions));
        if (srcPartyBn && srcPartyBn !== '') params = params.append('srcPartyBn', srcPartyBn);

        return this.authHttp.get<TnTPartyBusinessName[]>(this.baseURL + '/parties/businessNames', { params: params });
    }

    public modPartyName(p: TnTParty): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/parties', JSON.stringify(p), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public modPartyPartyBusinessName(p: TnTParty): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/parties/modPartyPartyBusinessName', JSON.stringify(p), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }


    public changePartyBusinessName(bn: TnTMessageTypeBusinessName): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/parties/businessNames', JSON.stringify(bn), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }


    public portalAddParty(theParty: TnTParty) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/parties', JSON.stringify(theParty), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public portalAddPartyBusinessName(thePartyBusinessName: TnTPartyBusinessName) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/parties/addBusinessName/', JSON.stringify(thePartyBusinessName), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Added']);
            }));
    }

    public portalRemPartyBusinessName(theBusinessName: TnTPartyBusinessName): Observable<string> {
        return this.authHttp.delete(this.baseURL + '/parties/businessName/' + theBusinessName.Id).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public portalRemParty(theParty: TnTParty): Observable<string> {
        return this.authHttp.delete(this.baseURL + '/parties/' + theParty.Id).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public getMessageTypes(usePermissions: boolean = false, onlySearchable: boolean = true): Observable<TnTMessageType[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('usePermissions', String(usePermissions));
        params = params.append('onlySearchable', String(onlySearchable));

        return this.authHttp.get<TnTMessageType[]>(this.baseURL + '/messagetypes', { params: params });
    }

    public getMessageTypesUserPortal(usePermissions: boolean = false, srcMessageType?: string, selectMessageTypeBusinessNameSearch?: TnTMessageTypeBusinessName): Observable<TnTMessageType[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('usePermissions', String(usePermissions));
        if (srcMessageType && srcMessageType !== '') params = params.append('srcMessageType', srcMessageType);
        if (selectMessageTypeBusinessNameSearch && selectMessageTypeBusinessNameSearch !== null) params = params.append('srcMessageTypeBusinessName', selectMessageTypeBusinessNameSearch.Id.toString());

        return this.authHttp.get<TnTMessageType[]>(this.baseURL + '/messagetypes/userportal', { params: params });
    }

    public getMessageTypesReprocessTrigger(usePermissions: boolean = false, includeMessageType?: number): Observable<TnTMessageType[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('usePermissions', String(usePermissions));
        if (includeMessageType != undefined) params = params.append('includeMessageType', String(includeMessageType));

        return this.authHttp.get<TnTMessageType[]>(this.baseURL + '/messagetypes/reprocess', { params: params });
    }

    public getBusinessNamesPurgingCustom(usePermissions: boolean = false, includeBusinessNameId?: number): Observable<TnTMessageTypeBusinessName[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('usePermissions', String(usePermissions));
        if (includeBusinessNameId != undefined) params = params.append('includeBusinessNameId', String(includeBusinessNameId));

        return this.authHttp.get<TnTMessageTypeBusinessName[]>(this.baseURL + '/purging/availableBN', { params: params });
    }

    public getMessageTypesBusinessNames(usePermissions: boolean = false, srcMessageTypeBn?: string): Observable<TnTMessageTypeBusinessName[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('usePermissions', String(usePermissions));
        if (srcMessageTypeBn && srcMessageTypeBn !== '') params = params.append('srcMessageTypeBn', srcMessageTypeBn);

        return this.authHttp.get<TnTMessageTypeBusinessName[]>(this.baseURL + '/messagetypes/businessName', { params: params });
    }

    public getMessageTypesBusinessNamesSearcheable(usePermissions: boolean = false, srcMessageTypeBn?: string): Observable<TnTMessageTypeBusinessName[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('usePermissions', String(usePermissions));
        if (srcMessageTypeBn && srcMessageTypeBn !== '') params = params.append('srcMessageTypeBn', srcMessageTypeBn);

        return this.authHttp.get<TnTMessageTypeBusinessName[]>(this.baseURL + '/messagetypes/businessNameSearcheable', { params: params });
    }

    public getMessageTypesReprocessTriggerDescription(usePermissions: boolean = false, includeMessageType?: number): Observable<TnTMessageType[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('usePermissions', String(usePermissions));
        if (includeMessageType != undefined) params = params.append('includeMessageType', String(includeMessageType));

        return this.authHttp.get<TnTMessageType[]>(this.baseURL + '/messagetypes/reprocessDescription', { params: params });
    }

    

    public getPurgingDefault(usePermissions: boolean = false): Observable<TnTPurgingDefault[]> {
        let params: HttpParams = new HttpParams();
        return this.authHttp.get<TnTPurgingDefault[]>(this.baseURL + '/purging/defaultdays', { params: params });
    }

    private convertSearchToQuery(srcParams: TnTMessageSearchParameters): HttpParams {
        let params = new HttpParams();
        if (srcParams.senders.length > 0) params = params.append('sender', srcParams.senders.map(item => item.Id).join());
        if (srcParams.receivers.length > 0) params = params.append('receiver', srcParams.receivers.map(item => item.Id).join());
        if (srcParams.messageTypes.length > 0) params = params.append('messageType', srcParams.messageTypes.map(item => item.Id).join());
        if (srcParams.status.length > 0) params = params.append('status', srcParams.status.map(item => item.Id).join());
        if (srcParams.startDate) params = params.append('fromD', srcParams.startDate.toISOString());
        if (srcParams.endDate) params = params.append('toD', srcParams.endDate.toISOString());
        if (srcParams.reference) params = params.append('reference', String(srcParams.reference));
        params = params.append('alert', String(srcParams.alert));

        return params;
    }

    public getMessageCount(srcParams: TnTMessageSearchParameters): Observable<number> {
        const params: HttpParams = this.convertSearchToQuery(srcParams);

        return this.authHttp.get(this.baseURL + '/messages/count', { params: params }).pipe(
            map((res) => {
                return Number(res['Count']);
            }));
    }

    public getMessageTotalCount(): Observable<number> {
        return this.authHttp.get(this.baseURL + '/messages/totalCount').pipe(
            map((res) => {
                return Number(res['Count']);
            }));
    }

    public getMessages(srcParams: TnTMessageSearchParameters, pageNumber: number = 1, itemsPerPage: number = 25): Observable<TnTMessage[]> {
        let params: HttpParams = this.convertSearchToQuery(srcParams);
        params = params.append('pageNumber', String(pageNumber));
        params = params.append('itemsPerPage', String(itemsPerPage));
        return this.authHttp.get<TnTMessage[]>(this.baseURL + '/messages', { params: params });
    }


    public portalRemBusinessName(theBusinessName: TnTMessageTypeBusinessName): Observable<string> {
        return this.authHttp.delete(this.baseURL + '/messagetypes/businessName/' + theBusinessName.Id).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public portalRemPurging(theBusinessName: TnTMessageTypeBusinessName): Observable<string> {
        return this.authHttp.delete(this.baseURL + '/deletetrigger/delete/' + theBusinessName.Id).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public portalRemoveMessageType(tnTMessageType: TnTMessageType) {
        return this.authHttp.delete(this.baseURL + '/messagetypes/' + tnTMessageType.Id).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public changeMessageTypes(theMessageType: TnTMessageType): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/messagetypes', JSON.stringify(theMessageType), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public changeMessageTypeBusinessName(bn: TnTMessageTypeBusinessName): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/messagetypes/businessName', JSON.stringify(bn), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public changeBusinessView(bvChanged: TnTBusinessView): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/businessview', JSON.stringify(bvChanged), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public getAllReprocessTriggers(selMessageTypeFilter?: TnTMessageType, srcURL?: string): Observable<TnTReprocessTrigger[]> {
        let params: HttpParams = new HttpParams();
        if (srcURL && srcURL !== '') params = params.append('srcURL', srcURL);
        if (selMessageTypeFilter && selMessageTypeFilter !== null) params = params.append('selMessageTypeFilter', selMessageTypeFilter.Id.toString());

        return this.authHttp.get<TnTReprocessTrigger[]>(this.baseURL + '/reprocessing/triggers', { params: params });
    }

    public getAllReprocessDescriptions(selMessageTypeFilter?: TnTMessageType): Observable<TnTReprocessTrigger[]> {
        let params: HttpParams = new HttpParams();
        if (selMessageTypeFilter && selMessageTypeFilter !== null) params = params.append('selMessageTypeFilter', selMessageTypeFilter.Id.toString());

        return this.authHttp.get<TnTReprocessTrigger[]>(this.baseURL + '/reprocessing/descriptions', { params: params });
    }

    public changeReprocessTrigger(triggerChanged: TnTReprocessTrigger): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/reprocessing/modifytrigger', JSON.stringify(triggerChanged), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public changePurgingCustom(customChanged: TnTPurgingCustom): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.put(this.baseURL + '/purging/modifycustom', JSON.stringify(customChanged), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public changePurgingDefault(defaultChanged: TnTPurgingDefault): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.authHttp.put(this.baseURL + '/purging/modifydefault', JSON.stringify(defaultChanged), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public addReprocessMessage(tntReprocessQueueMsg: TnTReprocessQueue): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/reprocessing/addreprocessmessage', JSON.stringify(tntReprocessQueueMsg), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public deleteReprocessTrigger(theReprocessTriggerId: number, onlyDescription: boolean): Observable<string> {

        return this.authHttp.delete(this.baseURL + '/reprocessing/deletetrigger/' + theReprocessTriggerId + '/' + onlyDescription).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }

    public addReprocessTrigger(theReprocessTrigger: TnTReprocessTrigger): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/reprocessing/addtrigger', JSON.stringify(theReprocessTrigger), { headers: headers }).pipe(
            map((res) => {
                return String(res['Message']);
            }));
    }
    public getStorageAccounts(): Observable<TnTStorageAccount[]> {
        return this.authHttp.get<TnTStorageAccount[]>(this.baseURL + '/storageaccounts');
    }

    public addStorageAccount(theStorageAccount: TnTStorageAccount): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/storageaccounts', JSON.stringify(theStorageAccount), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Added']);
            }));
    }

    public deleteStorageAccount(theStorageAccount: TnTStorageAccount): Observable<boolean> {

        return this.authHttp.delete(this.baseURL + '/storageaccounts/' + theStorageAccount.ID).pipe(
            map((res) => {
                return Boolean(res['Deleted']);
            }));
    }

    public changeStorageAccount(theStorageAccount: TnTStorageAccount): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/storageaccounts/' + theStorageAccount.ID, JSON.stringify(theStorageAccount), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Changed']);
            }));
    }

    public getSettings(): Observable<TnTSettings[]> {
        return this.authHttp.get<TnTSettings[]>(this.baseURL + '/settings');
    }

    public addSetting(theSetting: TnTSettings): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.post(this.baseURL + '/settings', JSON.stringify(theSetting), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Added']);
            }));
    }

    public deleteSetting(theSetting: TnTSettings): Observable<boolean> {

        return this.authHttp.delete(this.baseURL + '/settings/' + theSetting.Id).pipe(
            map((res) => {
                return Boolean(res['Deleted']);
            }));
    }

    public changeSetting(theSetting: TnTSettings): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.authHttp.put(this.baseURL + '/settings/' + theSetting.Id, JSON.stringify(theSetting), { headers: headers }).pipe(
            map((res) => {
                return Boolean(res['Changed']);
            }));
    }
}
