import { PipeTransform, Pipe } from '@angular/core';

// https://gist.github.com/adamrecsko/0f28f474eca63e0279455476cc11eca7
@Pipe({
    name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
    transform(text: string, search): string {
        if (search && text) {
            var amended_search = search.replaceAll('*','');
            let pattern = amended_search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
            pattern = pattern.split(' ').filter((t) => {
                return t.length > 0;
            }).join('|');
            const regex = new RegExp(pattern, 'gi');

            return text.replace(regex, (match) => `<span class="search-highlight">${match}</span>`);
        } else {
            return text;
        }
    }
}