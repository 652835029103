<ng-template #filterMessagetypeBn>
    <div class="filterwidth">
        <input type="text" #inputMessageTypeBnSearch class="form-control" placeholder="MessageType businessname" [(ngModel)]="srcMessageTypeBn" (keyup.enter)="getMessageTypeBusinessNames(); popoverMessageTypeBn.hide()">
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="getMessageTypeBusinessNames(); popoverMessageTypeBn.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearSearchMessageTypeBn(); popoverMessageTypeBn.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<ng-template #filterPartyBn>
    <div class="filterwidth">
        <input type="text" #inputPartyBnSearch class="form-control" placeholder="Party businessname" [(ngModel)]="srcPartyBn" (keyup.enter)="getPartiesBusinessNames(); popoverPartyBn.hide()">
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="getPartiesBusinessNames(); popoverPartyBn.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearSearchPartyBn(); popoverPartyBn.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<div class="container-fluid paddingTop">
    <h1 class="display-4 display">Business name administration</h1>
</div>
<div class="row paddingTable container-fluid">
    <div class="col-md-6">
        <div class="card">
            <div class="card-header card-header-groups text-primary-inverse">
                MessageType businessname <i-feather name="filter" [popover]="filterMessagetypeBn" placement="right" #popoverMessageTypeBn="bs-popover" class="filterIcon" [ngClass]="{'fill': blnSearchMessageTypeBnApplied}" (onShown)="focusInputSearchMessageTypeBn()"></i-feather>
            </div>
            <div class="card-body nopadding scrollCardFooter pointer">
                <ul class="list-group">
                    <li class="nomsg text-muted" *ngIf="!isLoading && lstMessageTypesBusinessNames.length == 0">
                        No messagetype businessnames found.
                    </li>
                    <li *ngFor="let bn of lstMessageTypesBusinessNames" (click)="businessNameClicked(bn)" [value]="bn.Id" [class.active]="selBnId == bn.Id" class="list-group-item list-group-item-action">
                        <div class="form-row">
                            <div class="form-group col-md-10">
                                <span *ngIf="selBnId != bn.Id">{{bn.BusinessName}}</span>
                                <input #inputMessageTypeBusinessName class="form-control" *ngIf="selBnId == bn.Id" [(ngModel)]="bn.BusinessName" (input)="bnChanged(bn)"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="right" (click)="removeBusinessName()"><i-feather name="trash" class="hoverTrashIcon iconPaddingTop" *ngIf="selBnId == bn.Id"></i-feather></span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card-footer">
                <form name="addbn" (ngSubmit)="f.form.valid && addMessageTypeBusinessName()" #f="ngForm" novalidate>
                    <div class="input-group mb-4 addFooter">
                        <input [(ngModel)]="strNewBusinessName" name="strNewBusinessName" #businessName="ngModel" id="businessName" required type="text" class="form-control" placeholder="New messagetype businessname" aria-label="New messagetype businessname" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <button class="btn btn-outline-primary" type="button" id="btn-add-messagetypebn" type="submit" [disabled]="!businessName.valid">
                                <i-feather name="plus-circle"></i-feather>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-header card-header-groups text-primary-inverse">
                Party businessname  <i-feather name="filter" [popover]="filterPartyBn" placement="right" #popoverPartyBn="bs-popover" class="filterIcon" [ngClass]="{'fill': blnSearchPartyBnApplied}" (onShown)="focusInputSearchPartyBn()"></i-feather>
            </div>
            <div class="card-body nopadding scrollCardFooter pointer">
                <ul class="list-group">
                    <li class="nomsg text-muted" *ngIf="!isLoading && lstPartiesBusinessNames.length == 0">
                        No party businessnames found.
                    </li>
                    <li *ngFor="let bn of lstPartiesBusinessNames" (click)="partyBusinessNameClicked(bn)" [value]="bn.Id" [class.active]="selPartyBnId == bn.Id" class="list-group-item list-group-item-action">
                        <div class="form-row">
                            <div class="form-group col-md-10">
                                <span *ngIf="selPartyBnId != bn.Id">{{bn.BusinessName}}</span>
                                <input #inputPartyBusinessName class="form-control" *ngIf="selPartyBnId == bn.Id" [(ngModel)]="bn.BusinessName" (input)="partyBnChanged(bn)"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="right" (click)="removePartyBusinessName(); removePurgingCustom()"><i-feather name="trash" class="hoverTrashIcon iconPaddingTop" *ngIf="selPartyBnId == bn.Id"></i-feather></span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card-footer">
                <form name="addPartyBn" (ngSubmit)="bn.form.valid && addPartyBusinessName()" #bn="ngForm" novalidate>
                    <div class="input-group mb-4 addFooter">
                        <input [(ngModel)]="strNewPartyBusinessName" name="strNewPartyBusinessName" #partyBusinessName="ngModel" id="partyBusinessName" required type="text" class="form-control" placeholder="New party businessname" aria-label="New party businessname" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <button class="btn btn-outline-primary" type="submit" id="btn-add-partybn" [disabled]="!partyBusinessName.valid">
                                <i-feather name="plus-circle"></i-feather>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
