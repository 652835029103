import {
    Component,
    OnInit,
    ViewEncapsulation,
    ViewContainerRef,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ConfigurationService } from "./_services/index";
import { HttpClient } from "@angular/common/http";
import pkg from "../../package.json";

import { OAuthService, AuthConfig } from "angular-oauth2-oidc";
import {
    JwksValidationHandler,
    NullValidationHandler,
} from "angular-oauth2-oidc";

@Component({
    selector: "app-component",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./app.component.scss"],
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
    // Om bootstrap modals te laten werken
    private viewContainerRef: ViewContainerRef;

    public constructor(
        viewContainerRef: ViewContainerRef,
        public svcTitle: Title,
        public svcConfiguration: ConfigurationService,
        public oauthService: OAuthService
    ) {
        this.viewContainerRef = viewContainerRef;
    }

    ngOnInit() {
        this.svcConfiguration.SETTINGS.subscribe((res) => {
            if (res) {
                this.svcTitle.setTitle(res.PageTitle);
                pkg.version = res.VersionSetting;
            }
        });
    }
}
