import { Component, OnInit, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../_services/';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SnackbarService } from 'ngx-snackbar';


@Component({
    styleUrls: ['../configuration.component.scss','./configuration_businessNames.component.scss'],
    templateUrl: './configuration_businessNames.component.html',
})

export class ConfigurationBusinessNamesComponent implements OnInit, AfterViewInit {

    // Loader
    public isLoading: boolean;

    // Lists
    public lstMessageTypesBusinessNames: Array<any> = [];
    public lstPartiesBusinessNames: Array<any> = [];

    // Timer
    private timer;

    // MessageType BusinessNames vars
    public selBn: TnTMessageTypeBusinessName;
    public selBnId: number = -1;

    public strNewBusinessName: string = '';
    public strPrevBusinessName: string = '';

    // Party BusinessNames var
    public selPartyBn: TnTMessageTypeBusinessName;
    public selPartyBnId: number = -1;

    public strNewPartyBusinessName: string = '';
    public strPrevPartyBusinessName: string = '';

    // Filter vars
    public srcMessageTypeBn: string = '';
    public blnSearchMessageTypeBnApplied = false;

    public srcPartyBn: string = '';
    public blnSearchPartyBnApplied = false;

    @ViewChild('inputMessageTypeBusinessName', { static: false }) inputMessageTypeBusinessName: any;
    @ViewChild('inputPartyBusinessName', { static: false }) inputPartyBusinessName: any;
    @ViewChild('inputMessageTypeBnSearch', { static: false }) inputMessageTypeBnSearch: any;
    @ViewChild('inputPartyBnSearch', { static: false }) inputPartyBnSearch: any;


    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private snackbarService: SnackbarService) { }

    public ngOnInit() {
        this.isLoading = true;
        this.getMessageTypeBusinessNames();
        this.getPartiesBusinessNames();
        this.isLoading = false;
    }

    ngAfterViewInit(): void {

    }

    public getMessageTypeBusinessNames(): void {
        this.svcAPI.getMessageTypesBusinessNames(false, this.srcMessageTypeBn)
            .pipe(catchError((error) => {
                return of([]); // Return empty mt set
            }))
            .subscribe(
            bn => {
                this.lstMessageTypesBusinessNames = bn;
            }
        );


        this.blnSearchMessageTypeBnApplied = this.srcMessageTypeBn !== '';
    }

    public businessNameClicked(clickedBN: any): void {
        this.lstMessageTypesBusinessNames.forEach((bn) => {
            if (bn.Id === clickedBN.Id) {
                this.selBn = bn;
                this.selBnId = bn.Id;
                this.selPartyBn = null;
                this.selPartyBnId = -1;
                this.strPrevBusinessName = bn.BusinessName;
                setTimeout(() => {
                    this.inputMessageTypeBusinessName.nativeElement.focus();
                }, 10);
            }
        });
    }

    public addMessageTypeBusinessName(): void {
        const theBusinessName: TnTMessageTypeBusinessName = <TnTMessageTypeBusinessName>{
            BusinessName: this.strNewBusinessName
        };

        const existingMessageType = this.lstMessageTypesBusinessNames.find(bn => bn.BusinessName === this.strNewBusinessName);

        if (existingMessageType == null) {
            this.svcAPI.portalAddMessageTypeBusinessName(theBusinessName).subscribe(
                result => {
                    if (result) { // BN added

                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Added',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });

                        this.getMessageTypeBusinessNames();
                        this.strNewBusinessName = '';
                        this.selBn = null;
                        this.selBnId = -1;
                    }
                }
            );

        } else {
            this.selBn = existingMessageType;
            this.selBnId = this.selBn.Id;

            const _this = this;
            this.snackbarService.add({
                msg: 'This business name already exists',
                timeout: 2000,
                customClass: 'snackBarError',
                action: {
                    text: 'X'
                }
            });
        }


    }

    public removeBusinessName(): void {
        this.svcAPI.portalRemBusinessName(this.selBn).subscribe(
            result => {
                if (result === 'Succes') {

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Removed',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });

                    this.getMessageTypeBusinessNames();
                    this.selBn = null;
                    this.selBnId = -1;
                    this.strPrevBusinessName = '';
                } else {
                    const _this = this;
                    this.snackbarService.add({
                        msg: result,
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });

                }
            }
        );
    }

    public removePurgingCustom(): void {
        this.svcAPI.portalRemPurging(this.selBn).subscribe(
            result => {
                if (result === 'Succes') {

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Removed',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });

                    this.getMessageTypeBusinessNames();
                    this.selBn = null;
                    this.selBnId = -1;
                    this.strPrevBusinessName = '';
                } else {
                    const _this = this;
                    this.snackbarService.add({
                        msg: result,
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });

                }
            }
        );
    }

    public bnChanged(bn: TnTMessageTypeBusinessName): void {
        if (bn != null) {

            clearInterval(this.timer);
            this.timer = setInterval(() => {

                if (bn != null) {
                    this.svcAPI.changeMessageTypeBusinessName(bn).subscribe(
                        result => {
                            if (result !== 'Succes') {
                                bn.BusinessName = this.strPrevBusinessName;

                                const _this = this;
                                this.snackbarService.add({
                                    msg: result,
                                    timeout: 2000,
                                    customClass: 'snackBarError',
                                    action: {
                                        text: 'X'
                                    }
                                });

                            } else {

                                const _this = this;
                                this.snackbarService.add({
                                    msg: 'Saved',
                                    timeout: 2000,
                                    customClass: 'snackBarSucces',
                                    action: {
                                        text: 'X'
                                    }
                                });

                            }
                        }
                    );
                }
                clearInterval(this.timer);
            }, 800);
        }
    }

    public clearSearchMessageTypeBn(): void {
        this.srcMessageTypeBn = '';
        this.getMessageTypeBusinessNames();
    }

    public focusInputSearchMessageTypeBn(): void {
        setTimeout(() => {
            this.inputMessageTypeBnSearch.nativeElement.focus();
        }, 10);
    }

    public getPartiesBusinessNames(): void {
        this.svcAPI.getPartiesBusinessNames(false, this.srcPartyBn)
            .pipe(catchError((error) => {
                return of([]); // Return empty pt set
            }))
            .subscribe(
            bn => {
                this.lstPartiesBusinessNames = bn;
            }
        );

        this.blnSearchPartyBnApplied = this.srcPartyBn !== '';

    }

    public partyBusinessNameClicked(clickedBN: any): void {
        this.lstPartiesBusinessNames.forEach((bn) => {
            if (bn.Id === clickedBN.Id) {
                this.selPartyBn = bn;
                this.selPartyBnId = bn.Id;
                this.selBn = null;
                this.selBnId = -1;
                this.strPrevPartyBusinessName = bn.BusinessName;
                setTimeout(() => {
                    this.inputPartyBusinessName.nativeElement.focus();
                }, 10);
            }
        });

    }

    public addPartyBusinessName(): void {
        const theBusinessName: TnTPartyBusinessName = <TnTPartyBusinessName>{
            BusinessName: this.strNewPartyBusinessName
        };

        const existingMessageType = this.lstPartiesBusinessNames.find(bn => bn.BusinessName === this.strNewPartyBusinessName);

        if (existingMessageType == null) {
            this.svcAPI.portalAddPartyBusinessName(theBusinessName).subscribe(
                result => {
                    if (result) { // BN added

                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Added',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });

                        this.getPartiesBusinessNames();
                        this.strNewPartyBusinessName = '';
                        this.selPartyBn = null;
                        this.selPartyBnId = -1;
                    }
                }
            );
        } else {
            this.selPartyBn = existingMessageType;
            this.selPartyBnId = this.selPartyBn.Id;

            const _this = this;
            this.snackbarService.add({
                msg: 'This business name already exists',
                timeout: 2000,
                customClass: 'snackBarError',
                action: {
                    text: 'X'
                }
            });
        }

    }

    public removePartyBusinessName(): void {
        this.svcAPI.portalRemPartyBusinessName(this.selPartyBn).subscribe(
            result => {
                if (result === 'Succes') {

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Removed',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });

                    this.getPartiesBusinessNames();
                    this.selPartyBn = null;
                    this.selPartyBnId = -1;
                    this.strPrevPartyBusinessName = '';
                } else {

                    const _this = this;
                    this.snackbarService.add({
                        msg: result,
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });

                }
            }
        );
    }

    public partyBnChanged(bn: TnTMessageTypeBusinessName): void {

        if (bn != null) {

            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.svcAPI.changePartyBusinessName(bn).subscribe(
                    result => {
                        if (result !== 'Succes') {
                            bn.BusinessName = this.strPrevPartyBusinessName;

                            const _this = this;
                            this.snackbarService.add({
                                msg: result,
                                timeout: 2000,
                                customClass: 'snackBarError',
                                action: {
                                    text: 'X'
                                }
                            });

                        } else {

                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Saved',
                                timeout: 2000,
                                customClass: 'snackBarSucces',
                                action: {
                                    text: 'X'
                                }
                            });

                        }
                    }
                );
                clearInterval(this.timer);
            }, 800);
        }
    }

    public clearSearchPartyBn(): void {
        this.srcPartyBn = '';
        this.getPartiesBusinessNames();
    }

    public focusInputSearchPartyBn(): void {
        setTimeout(() => {
            this.inputPartyBnSearch.nativeElement.focus();
        }, 10);
    }
}
