<!-- Header -->
<div class="container-fluid paddingTop">
    <h1 class="display-4 display">Filter configuration</h1>
</div>

<div class="row paddingUPContent">
    <div class="col-md-4">
        <div class="card">
            <div class="card-header card-header-groups text-primary-inverse">
                Filters
            </div>
            <div class="card-body nopadding scrollCardFooter pointer">
                <ul class="list-group">
                    <li *ngFor="let theFilter of lstFilters" (click)="filterSelected(theFilter)" [value]="theFilter.Id" [class.active]="selFilterId == theFilter.Id" class="list-group-item list-group-item-action">
                        <div class="form-row">
                            <div class="col-md-10 ellipsis">
                                <span *ngIf="selFilterId != theFilter.Id">{{theFilter.Name}}</span>
                                <span *ngIf="!blnEditFilterName && selFilterId == theFilter.Id" class="ellipsis">{{theFilter.Name}}</span>
                                <input *ngIf="blnEditFilterName && selFilterId == theFilter.Id" class="form-control" [(ngModel)]="theFilter.Name" (input)="saveFilterName();" />                            </div>
                            <div class=" col-md-2">
                                <span (click)="removeFilter(theFilter)" class="right"><i-feather  [ngClass]="{'iconPaddingTop':blnEditFilterName}"  class="hoverTrashIcon" name="trash" *ngIf="selFilterId == theFilter.Id"></i-feather></span>
                                <span (click)="editFilterName(theFilter)" class="right"><i-feather [ngClass]="{'iconPaddingTop':blnEditFilterName}"  class="hoverEditIcon" name="edit-2" *ngIf="selFilterId == theFilter.Id"></i-feather></span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card-footer">
                <form name="addFilter" (ngSubmit)="f.form.valid && addNewFilter()" #f="ngForm" novalidate>
                    <div class="input-group mb-3 addFilter">
                        <input [(ngModel)]="strNewFilterName" name="newFilterName" #filtername="ngModel" id="filtername" required type="text" class="form-control" placeholder="New filter name" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <button class="btn btn-outline-primary" type="submit" id="button-addon2" [disabled]="!filtername.valid">
                                <i-feather name="plus-circle"></i-feather>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-md-4" *ngIf="selFilter != null">
        <div class="card">
            <div class="card-header bg-primary text-primary-inverse">
                <span class="permissionsheader">Filter: <strong>{{selFilter.Name}}</strong></span>
            </div>
            <div class="card-body nopadding scrollCardNoFooter pointer">
                <ul class="list-group rights">
                    <li class="list-group-item list-group-item-action" [class.active]="selProperty == 'Message types'" (click)="propteryClicked('Message types')">
                        <span>
                            <i-feather name="filter" [ngClass]="{'filter-active': selFilter.MessageTypes != null && selFilter.MessageTypes != ''}"></i-feather>
                            Message type(s)<span *ngIf="selFilter.MessageTypes == null || selFilter.MessageTypes != ''">:</span>{{getMTBusinessNameById(selFilter.MessageTypes)}}
                        </span>
                    </li>
                    <li class="list-group-item list-group-item-action"  [class.active]="selProperty == 'Senders'"  (click)="propteryClicked('Senders')">
                        <span>
                            <i-feather name="filter" [ngClass]="{'filter-active': selFilter.Senders != null && selFilter.Senders != ''}"></i-feather>
                            Sender(s)<span *ngIf="selFilter.Senders == null || selFilter.Senders != ''">:</span> {{getPartyBusinessNameById(selFilter.Senders)}}
                        </span>
                    </li>
                    <li class="list-group-item list-group-item-action" [class.active]="selProperty == 'Receivers'" (click)="propteryClicked('Receivers')">
                        <span>
                            <i-feather name="filter" [ngClass]="{'filter-active': selFilter.Receivers != null && selFilter.Receivers != ''}"></i-feather>
                            Receiver(s)<span *ngIf="selFilter.Receivers == null || selFilter.Receivers != ''">:</span> {{getPartyBusinessNameById(selFilter.Receivers)}}
                        </span>
                    </li>
                    <li class="list-group-item list-group-item-action" [class.active]="selProperty == 'Reference'" (click)="propteryClicked('Reference')">
                        <span>
                            <i-feather name="filter" [ngClass]="{'filter-active': selFilter.Reference != null && selFilter.Reference != ''}"></i-feather>
                            Reference<span *ngIf="selFilter.Reference == null || selFilter.Reference != ''">:</span> {{selFilter.Reference}}
                        </span>
                    </li>
                    <li class="list-group-item list-group-item-action" [class.active]="selProperty == 'Transactionstatusses'" (click)="propteryClicked('Transactionstatusses')">
                        <span>
                            <i-feather name="filter" [ngClass]="{'filter-active': selFilter.TransactionStatus != null && selFilter.TransactionStatus != ''}"></i-feather>
                            Transactionstatus<span *ngIf="selFilter.TransactionStatus == null || selFilter.TransactionStatus != ''">:</span> {{getTransactionBusinessNameById(selFilter.TransactionStatus)}}
                        </span>
                    </li>
                    <li class="list-group-item list-group-item-action" (click)="propteryClicked('Failed Messages')">
                        <span>
                            <i-feather name="filter" [ngClass]="{'filter-active': selFilter.FailedMessages != null && selFilter.FailedMessages != ''}"></i-feather>
                            Failed Messages
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-4" *ngIf="selProperty != '' && selFilter != null">
        <div class="card" *ngIf="selProperty == 'Message types'">
            <div class="card-header bg-primary text-primary-inverse pointer" (click)="clickSelectAll('Message types')">
                <i-feather name="square" *ngIf="!allSelectedMessageTypes"></i-feather>
                <i-feather name="check-square" *ngIf="allSelectedMessageTypes"></i-feather>
                Property: <strong>{{selProperty}}</strong>
            </div>
            <div class="card-body nopadding scrollCardNoFooter pointer">
                <ul class="list-group rights">
                    <li *ngFor="let theMessageType of lstPropertyMessageTypeBN" (click)="propMessageTypeClick(theMessageType)" [value]="theMessageType.MessageType.Id" class="list-group-item list-group-item-action">
                        <i-feather name="square" *ngIf="theMessageType.Active == 0"></i-feather>
                        <i-feather name="check-square" *ngIf="theMessageType.Active != 0"></i-feather>
                        {{theMessageType.MessageType.BusinessName}}
                    </li>
                </ul>
            </div>
        </div>

        <div class="card" *ngIf="selProperty == 'Senders'">
            <div class="card-header bg-primary text-primary-inverse pointer" (click)="clickSelectAll('Senders')">
                <i-feather name="square" *ngIf="!allSelectedSenders"></i-feather>
                <i-feather name="check-square" *ngIf="allSelectedSenders"></i-feather>
                Property: <strong>{{selProperty}}</strong>
            </div>
            <div class="card-body nopadding scrollCardNoFooter pointer">
                <ul class="list-group rights">
                    <li *ngFor="let theSender of lstPropertySenderBN" (click)="propSendersClick(theSender)" [value]="theSender.Party.Id" class="list-group-item list-group-item-action">
                        <i-feather name="square" *ngIf="theSender.Active == 0"></i-feather>
                        <i-feather name="check-square" *ngIf="theSender.Active != 0"></i-feather>
                        {{theSender.Party.BusinessName}}
                    </li>
                </ul>
            </div>
        </div>
        <div class="card" *ngIf="selProperty == 'Receivers'">
            <div class="card-header bg-primary text-primary-inverse pointer" (click)="clickSelectAll('Receivers')">
                <i-feather name="square" *ngIf="!allSelectedReceivers"></i-feather>
                <i-feather name="check-square" *ngIf="allSelectedReceivers"></i-feather>
                Property: <strong>{{selProperty}}</strong>
            </div>
            <div class="card-body nopadding scrollCardNoFooter pointer">
                <ul class="list-group rights">
                    <li *ngFor="let theReceiver of lstPropertyReceiverBN" (click)="propReceiversClick(theReceiver)" [value]="theReceiver.Party.Id" class="list-group-item list-group-item-action">
                        <i-feather name="square" *ngIf="theReceiver.Active == 0"></i-feather>
                        <i-feather name="check-square" *ngIf="theReceiver.Active != 0"></i-feather>
                        {{theReceiver.Party.BusinessName}}
                    </li>
                </ul>
            </div>
        </div>

        <div class="card" *ngIf="selProperty == 'Transactionstatusses'">
            <div class="card-header bg-primary text-primary-inverse pointer" (click)="clickSelectAll('Transactionstatusses')">
                <i-feather name="square" *ngIf="!allSelectedTransactionstatusses"></i-feather>
                <i-feather name="check-square" *ngIf="allSelectedTransactionstatusses"></i-feather>
                Property: <strong>{{selProperty}}</strong>
            </div>
            <div class="card-body nopadding scrollCardNoFooter pointer">
                <ul class="list-group rights">
                    <li *ngFor="let theTransactionStatus of lstPropertyTransactionStatusBN" (click)="propTransactionStatusClick(theTransactionStatus)" [value]="theTransactionStatus.TransactionStatus.Id" class="list-group-item list-group-item-action">
                        <i-feather name="square" *ngIf="theTransactionStatus.Active == 0"></i-feather>
                        <i-feather name="check-square" *ngIf="theTransactionStatus.Active != 0"></i-feather>
                        {{theTransactionStatus.TransactionStatus.TransactionStatus}}
                    </li>
                </ul>
            </div>
        </div>

        <div class="card" *ngIf="selProperty == 'Reference'">
            <div class="card-header bg-primary text-primary-inverse">
                Property: <strong>{{selProperty}}</strong>
            </div>
            <div class="card-footer">
                <div class="input-group mb-4 addFooter">
                    <input class="form-control" [(ngModel)]="selFilter.Reference" placeholder="New reference" (input)="saveFilterReference();" />
                </div>
            </div>
        </div>

    </div>
    <div class="col-md-8 aligner" *ngIf="selFilter == null">
        <h1 class="display-4 display aligner-item">Select a filter to edit.</h1>
    </div>
</div>
