<ng-template #filterMessagetype>
    <div class="filterwidth">
        <ng-select [items]="lstAllMessageTypes"
                   [multiple]="false"
                   [clearable]="true"
                   bindLabel="BusinessName"
                   [(ngModel)]="srcMessageTypeFilter">
        </ng-select>
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="getAllReprocessTriggers(); popoverMessageType.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearSearchMessagetype(); popoverMessageType.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<div class="row paddingTable container-fluid">
    <div class="col-md-12 paddingBottomTable">
        <div class="card">
            <div class="table-responsive" style="overflow: inherit">
                <table class="table table-hover p-3 mb-5 tableLikeCard">
                    <thead class="text-primary-inverse">
                        <tr>
                            <th class="col-5 firstCell">MessageType <i-feather name="filter" [popover]="filterMessagetype" placement="right" #popoverMessageType="bs-popover" class="filterIcon" [ngClass]="{'fill': blnSearchMessageTypeApplied}"></i-feather></th>
                            <th class="col-5">Description</th>
                            <th class="smallTD-6 centerText"></th> <!--placeholder delete-->
                        </tr>
                    </thead>
                    <tbody class="pointer">
                        <tr id="lastTR">

                            <td class="col-5" (click)="newDescriptionClicked(); $event.stopPropagation();">
                                <ng-select [items]="lstFEMessageTypes"
                                           [multiple]="false"
                                           [clearable]="true"
                                           bindLabel="BusinessName"
                                           [(ngModel)]="selNewReprocessDescriptionBn">
                                </ng-select>
                            </td>

                            <td class="col-5 ellipsis">
                                <div class="form-group">
                                    <input class="form-control" [(ngModel)]="strNewReprocessDescription" />
                                </div>
                            </td>

                            <td class="smallTD-6 centerText">
                                <button class="btn btn-outline-primary" type="button" (click)="btnAddReprocessDescriptionClicked()">
                                    <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstReprocessTriggers.length == 0">
                                No reprocess descriptions found.
                            </td>
                            <td colspan="12" *ngIf="isLoading">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                        Loading reprocess descriptions
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr *ngFor="let Description of getlstReprocessTriggers(); let i = index" [class.active]="i == selectedRow">
                            <ng-container>
                                <td class="firstCell col-5" [ngClass]="{'ellipsis': selDescriptionMtId != Description.Id}" (click)="DescriptionClicked(i, 'MessageType', Description); $event.stopPropagation();">
                                    <span> {{Description.MessageTypeBusinessName}}</span>
                                </td>

                                <td class="col-5 ellipsis" [ngClass]="selDescriptionId != Description.Id" (click)="DescriptionClicked(i, 'Description', Description); $event.stopPropagation();">
                                    <div class="form-group">
                                        <span *ngIf="selDescriptionId != Description.Id">{{Description.Description}}</span>
                                        <input #inputDescription class="form-control" *ngIf="selDescriptionId == Description.Id" [(ngModel)]="Description.Description" (input)="DescriptionChanged(Description)" />
                                    </div>
                                </td>

                                <!--Delete-->
                                <td class="smallTD-6 centerText">
                                    <span (click)="removeReprocessTrigger(Description)" class="hoverTrashIcon"><i-feather name="trash"></i-feather></span>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
