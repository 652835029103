<div class="col-md-12 paddingTop" style="text-align: right; padding-bottom: 5px">
</div>
<div class="col-12 paddingBottomTable">
    <div class="card">
        <div class="table-responsive" style="overflow: inherit">
            <table class="table table-hover p-3 mb-5 tableLikeCard">
                <thead class="text-primary-inverse">
                    <tr>
                        <th class="col-3">Type</th>
                        <th class="col-8">Description</th>
                        <th class="col-1 centerText">Settings</th>
                    </tr>
                </thead>
                <tbody class="pointer">
                    <tr>
                        <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstAlertingSubscriptions.length == 0">
                            No messagetypes found.
                        </td>
                        <td colspan="12" *ngIf="isLoading">
                            <div class="progress">
                                <div class="
                                        progress-bar
                                        progress-bar-striped
                                        progress-bar-animated
                                    " role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                    Loading messages
                                </div>
                            </div>
                        </td>
                    </tr>

                    <!-- ROW EDIT -->
                    <tr *ngFor="let theSubscription of lstAlertingSubscriptions; let i = index" [class.active]="i == selectedRow" (click)="typeClicked(theSubscription)">
                        <ng-container *ngIf="!isLoading">
                            <!--Type-->
                            <td class="col-3">
                                <span>{{ theSubscription.Type }}</span>
                            </td>
                            <!--Description-->
                            <td class="col-8" (click)="typeClicked(i, 'Description', theSubscription)">
                                <span *ngIf="selDescriptionId != theSubscription.Id">{{ theSubscription.Description }}</span>
                                <input class="form-control" *ngIf="selDescriptionId == theSubscription.Id" [(ngModel)]="theSubscription.Description" (input)="descriptionChanged(theSubscription)" />
                            </td>
                            <td class="smallTD-6 centerText">
                                <span (click)="openDetail(theSubscription)" class="hoverEditIcon"><i-feather name="settings"></i-feather></span>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-tnt-configuration_alerting_subscriptions_detail></app-tnt-configuration_alerting_subscriptions_detail>