import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../_services/';

@Component({
    styleUrls: ['./configuration.component.scss'],
    templateUrl: './configuration.component.html'
})

export class ConfigurationComponent {
    constructor(private svcAPI: TnTAPIService, private svcRouter: Router) { }
}
