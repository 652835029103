import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { Location } from "@angular/common";
import { TnTAPIService, ConfigurationService } from "../../../../_services";
import { SnackbarService } from "ngx-snackbar";
import { ModalDirective } from "ngx-bootstrap";

import { Observable, forkJoin, pipe, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { OAuthService } from "angular-oauth2-oidc";
import { findStaticQueryIds } from "@angular/compiler";

@Component({
    styleUrls: ["./configuration_alerting_schedulers_detail.component.scss"],
    templateUrl: "./configuration_alerting_schedulers_detail.component.html",
    selector: "app-tnt-configuration_alerting_schedulers_detail",
})
export class ConfigurationAlertingSchedulerDetailComponent implements AfterViewInit {
    @ViewChild("childModal", { static: true })
    public childModal: ModalDirective;
    public theScheduler: TnTAlertingScheduler;
    // Timer
    private timer;

    // Loader
    public isLoading: boolean;
    public addEndpointEnabled: boolean = false;
    public selectedRow: Number;
    public selection: string;

    // STRING INPUT
    public strNewEndpointUrl: string = "";

    // SELECTED ATTRIBUTES
    public selEndpointUrl: TnTAlertingSubscriptionEndpoint;
    public selEndpointUrlId: number = -1;

    public SETTINGS: TnTSettings;

    public lstSettings: Array<string> = ["One Time", "Daily", "Weekly", "Monthly"];
    public lstDays: Array<string> = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    public lstMonths: Array<string> = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    public lstMonthsDays: Array<number> = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        31,
    ];

    public monthlyDate: string;
    public lstDates: Array<string> = [];

    public startDate: string;
    public endDate: string;

    constructor(
        private oauthService: OAuthService,
        private svcAPI: TnTAPIService,
        private svcRouter: Router,
        public svcConfig: ConfigurationService,
        public svcDomSanitization: DomSanitizer,
        private snackbarService: SnackbarService,
        private svcLocation: Location
    ) {
        this.svcConfig.SETTINGS.subscribe((res) => {
            if (res) {
                this.SETTINGS = res;
            }
        });
    }

    ngOnInit() {
        this.isLoading = true;
        this.theScheduler = <TnTAlertingScheduler>{
            Id: -1,
            Name: "",
            Mode: false,
            Status: 0,
            Action: null,
            Description: "",
            LastChangedOn: null,
            Settings: "Daily",
            StartDate: new Date(),
            EndDate: new Date(),
            Recurrency: 0,
            MonthlyDates: [],
            Days: [],
        };
        this.isLoading = false;
    }

    save() {
        const _this = this;
        this.theScheduler.StartDate = new Date(
            Date.UTC(
                new Date(this.startDate).getUTCFullYear(),
                new Date(this.startDate).getUTCMonth(),
                new Date(this.startDate).getUTCDate(),
                new Date(this.startDate).getUTCHours(),
                new Date(this.startDate).getUTCMinutes(),
                new Date(this.startDate).getUTCSeconds()
            )
        );
        if (this.theScheduler.Settings === "One Time") {
            this.theScheduler.EndDate = new Date(9999,11,31,0,0,0,0);
            this.endDate = this.formatDate(this.theScheduler.EndDate);
        } else {
            this.theScheduler.EndDate = new Date(
                Date.UTC(
                    new Date(this.endDate).getUTCFullYear(),
                    new Date(this.endDate).getUTCMonth(),
                    new Date(this.endDate).getUTCDate(),
                    new Date(this.endDate).getUTCHours(),
                    new Date(this.endDate).getUTCMinutes(),
                    new Date(this.endDate).getUTCSeconds()
                )
            );
        }
        console.log(this.theScheduler);
        // console.log("Save start date " + this.startDate)
        // console.log("Save end date " + this.endDate)
        this.svcAPI.modifyAlertingScheduler(this.theScheduler).subscribe((result) => {
            if (result === "Success") {
                const _this = this;
                this.snackbarService.add({
                    msg: "Saved",
                    timeout: 2000,
                    customClass: "snackBarSucces",
                    action: {
                        text: "X",
                    },
                });
            } else {
                const _this = this;
                this.snackbarService.add({
                    msg: result,
                    timeout: 2000,
                    customClass: "snackBarError",
                    action: {
                        text: "X",
                    },
                });
            }
        });
    }

    ngAfterViewInit() {
        this.childModal.onHidden.subscribe(() => {
            // Set correct route parameter (permalinks)
            this.svcLocation.replaceState("/");
        });
    }

    showChildModal(scheduler: TnTAlertingScheduler): void {
        // Set correct route parameter (permalinks)
        this.svcLocation.replaceState("/scheduler/" + scheduler.Id);

        // Load stuff
        this.isLoading = true;
        this.theScheduler = <TnTAlertingScheduler>{
            Id: scheduler.Id,
            Name: scheduler.Name,
            Mode: scheduler.Mode,
            Status: scheduler.Status,
            Action: scheduler.Action,
            Description: scheduler.Description,
            LastChangedOn: scheduler.LastChangedOn,
            Settings: scheduler.Settings,
            StartDate: scheduler.StartDate,
            EndDate: scheduler.EndDate,
            Recurrency: scheduler.Recurrency,
            MonthlyDates: scheduler.MonthlyDates,
            Days: scheduler.Days,
        };

        var tempStartDate = new Date(scheduler.StartDate);
        this.startDate = this.formatDate(tempStartDate);
        console.log("StartDate " + this.startDate)
        var tempEndDate = new Date(scheduler.EndDate);
        this.endDate = this.formatDate(tempEndDate);
        console.log("EndDate " + this.endDate)

        this.isLoading = false;
        // Show modal to user
        this.childModal.show();
    }

    formatDate(date: Date) {
        return date.getFullYear() + "-" + this.addZ(date.getMonth() + 1) + "-" + this.addZ(date.getDate()) + "T" + this.addZ(date.getHours()) + ":" + this.addZ(date.getMinutes());
    }

    addZ(n) {
        return n < 10 ? "0" + n : "" + n;
    }

    hideChildModal(): void {
        // Hide modal
        this.childModal.hide();
    }

    getMaxLength(theArray: string[]): number {
        let theLength: number = 0;
        for (const el of theArray) {
            if (el.length > theLength) theLength = el.length;
        }
        return theLength;
    }

    resetClicked() {
        this.selEndpointUrl = null;
        this.selEndpointUrlId = -1;
    }

    btnToggleEndpointClicked() {
        this.addEndpointEnabled = !this.addEndpointEnabled;
    }

    addMonthlyDate() {
        this.theScheduler.MonthlyDates.push(new Date(this.monthlyDate));
    }
}
