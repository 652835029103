<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="detail-header" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title overflow">
                    <div class="c-detail-wrapper">
                        <h4 id="detail-header" *ngIf="theScheduler">
                            {{ theScheduler.Name }}
                        </h4>
                    </div>
                </div>
                <div class="pull-right">
                    <button type="button" class="close" aria-label="Close" (click)="hideChildModal()">
                        <i-feather name="x" class="modaltitlebutton"></i-feather>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <div class="detail-content" *ngIf="!isLoading">
                    <!-- General type details -->
                    <div class="container-fluid">
                        <div class="row">
                            <h6 class="col-4 textRight">Settings</h6>
                            <div class="col-5">
                                <ng-select placeholder="Select settings" [items]="lstSettings" [multiple]="false" [clearable]="false" bindLabel="Settings" [(ngModel)]="theScheduler.Settings"></ng-select>
                            </div>
                        </div>
                        <div class="row" *ngIf="theScheduler.Settings === 'Monthly' || theScheduler.Settings === 'Weekly'">
                            <h6 class="col-4 textRight">Additional settings</h6>
                            <div class="col-5" *ngIf="theScheduler.Settings === 'Weekly'">
                                <ng-select placeholder="Select additional settings" [items]="lstDays" [multiple]="true" [clearable]="false" bindLabel="Days" [(ngModel)]="theScheduler.Days"></ng-select>
                            </div>
                            <div class="col-2" *ngIf="theScheduler.Settings === 'Monthly'">
                                <input #inputFromDate type="date" [(ngModel)]="monthlyDate" class="form-control" [ngClass]="{'is-invalid': blnFromInvalid}">
                            </div>
                            <div class="col-1 centerText" *ngIf="theScheduler.Settings === 'Monthly'">
                                <button class="btn btn-primary addButtonTypeDetail" type="button" (click)="addMonthlyDate()">
                                    <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
                                </button>
                            </div>
                            <div class="col-2" *ngIf="theScheduler.Settings === 'Monthly'">
                                <ng-select placeholder="Select dates" [items]="theScheduler.MonthlyDates" [multiple]="true" [clearable]="false" [(ngModel)]="theScheduler.MonthlyDates"></ng-select>
                            </div>
                        </div>
                        <div class="row">
                            <h6 class="col-4 textRight">Start date</h6>
                            <div class="col-2">
                                <input #inputFromDate type="datetime-local" [(ngModel)]="startDate" class="form-control" [ngClass]="{'is-invalid': blnFromInvalid}">
                            </div>
                            <div class="col-1" style="vertical-align: middle; text-align: center; padding-top: 7px;">
                                <h6>End date</h6>
                            </div>
                            <div class="col-2">
                                <input #inputFromDate type="datetime-local" [disabled]="theScheduler.Settings === 'One Time'" [(ngModel)]="endDate" class="form-control" [ngClass]="{'is-invalid': blnFromInvalid}">
                            </div>
                        </div>
                        <!-- <div class="row">
                            <h6 class="col-4 textRight">Start hour</h6>
                            <div class="col-2">
                                <input #inputFromDate type="time" [(ngModel)]="theScheduler.StartTime" value="theScheduler.StartTime" class="form-control" [ngClass]="{'is-invalid': blnFromInvalid}">
                            </div>
                            <div class="col-1" style="vertical-align: middle; text-align: center; padding-top: 7px;">
                                <h6>End hour</h6>
                            </div>
                            <div class="col-2">
                                <input #inputFromDate type="time" [disabled]="theScheduler.Settings === 'One Time'" [(ngModel)]="theScheduler.EndTime" value="theScheduler.EndTime" class="form-control" [ngClass]="{'is-invalid': blnFromInvalid}">
                            </div>
                        </div> -->
                        <div class="row">
                            <h6 class="col-4 textRight">Recurrency (in minutes)</h6>
                            <div class="col-5">
                                <input #inputFromDate type="number" [(ngModel)]="theScheduler.Recurrency" class="form-control" [disabled]="theScheduler.Settings === 'One Time'">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" *ngIf="isLoading">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                            Loading
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row col-12">
                        <button class="btn btn-secondary col-5" (click)="hideChildModal()">
                            Close
                        </button>
                        <span class="col-2"></span>
                        <button class="btn btn-primary col-5" (click)="save()">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>