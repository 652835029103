import { Injectable, Pipe, PipeTransform } from '@angular/core'; // Pipe

@Pipe({
    name: 'hidedelgroup',
    pure: false
})
export class HideDeletedGroupPipe implements PipeTransform {
    transform(items: any[], delGroupId: number): any {
        // filter items array, items which match and return true will be kept, false will be filtered out
        return items.filter(item => item.Id !== delGroupId);
    }
}
