<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="detail-header" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title overflow">
                    <div class="c-detail-wrapper">
                        <h4 id="detail-header" *ngIf="theMessage">{{theMessage.MessageTypeBusinessName}} ({{theMessage.Id}})</h4>
                        <h6 class="text-muted" *ngIf="theProcess">ProcessID: {{theProcess.ProcessName}}</h6>
                        <p *ngIf="theMessage.SenderPartyBusinessName != null || theMessage.ReceiverPartyBusinessName != null" class="o-flex o-flex--align-center u-margin-bottom-small">
                            <span *ngIf="theMessage.SenderPartyBusinessName != null">{{theMessage.SenderPartyBusinessName}}</span>
                            <span class="a-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 50 42" fill="none">
                                    <path d="M49.2008 19.2012L31.1441 1.14407C30.6286 0.628628 29.9416 0.345703 29.2091 0.345703C28.4758 0.345703 27.7892 0.629034 27.2738 1.14407L25.6343 2.78389C25.1193 3.29852 24.8356 3.98592 24.8356 4.71884C24.8356 5.45135 25.1193 6.16191 25.6343 6.67654L36.1684 17.2338H2.7012C1.19227 17.2338 0 18.4151 0 19.9244V22.2427C0 23.752 1.19227 25.0524 2.7012 25.0524H36.2879L25.6347 35.6686C25.1197 36.184 24.836 36.8527 24.836 37.5856C24.836 38.3177 25.1197 38.9962 25.6347 39.5112L27.2742 41.1458C27.7896 41.6612 28.4762 41.9421 29.2095 41.9421C29.942 41.9421 30.629 41.6576 31.1445 41.1421L49.2012 23.0854C49.7179 22.5683 50.002 21.878 50 21.1443C50.0016 20.4081 49.7179 19.7175 49.2008 19.2012Z" fill="black" />
                                </svg>
                            </span>
                            <span *ngIf="theMessage.ReceiverPartyBusinessName != null">{{theMessage.ReceiverPartyBusinessName}}</span>
                        </p>
                        <div class="a-timestamp">{{theMessage.DateCreated | date: this.SETTINGS.DateFormat}}</div>
                    </div>
                </div>
                <div class="pull-right">
                    <button type="button" class="close" aria-label="Close" (click)="hideChildModal()">
                        <i-feather name="x" class="modaltitlebutton"></i-feather>
                    </button>
                    <button *ngIf="theMessage && theMessage.HasViews" type="button" class="close" aria-label="Businessview" (click)="eyeClicked(theMessage); $event.stopPropagation();">
                        <i-feather name="eye" class="modaltitlebutton"></i-feather>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <div class="detail-content" *ngIf="!isLoading">
                    <!-- General message details -->
                    <div class="container-fluid">
                        <div class="row">
                            <!-- References -->
                            <div class="c-table-view">
                                <ul class="c-table-view__list o-list">
                                    <ng-container *ngFor="let theReference of theMessage.References">
                                        <li class="c-table-view-item o-list-item">
                                            <div class="c-table-view-item__label">
                                                {{theReference.Key}}
                                            </div>
                                            <div class="c-table-view-item__values">
                                                <ul class="o-list">
                                                    <li class="o-list-item" *ngFor="let theValue of (refVisibleState[theReference.Key] === false ? theReference.Value.slice(0, refVisibleAmount) : theReference.Value)">
                                                        {{theValue}}
                                                    </li>
                                                </ul>
                                                <div *ngIf="refVisibleState[theReference.Key] === false && theReference.Value.length > refVisibleAmount" class="c-table-view__button">
                                                    <a (click)="refVisibleState[theReference.Key] = true" [routerLink]="" class="c-btn">
                                                        View more
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <hr *ngIf="theMessage.References.length > 0" />
                    <!-- Transaction timeline -->
                    <div class="container-fluid centered" *ngIf="!isLoading && lstTransactionsTimeLine.length > 0 && lstTransactionsTimeLine.length <= 6">
                        <ul class="timeline" aria-hidden="true">
                            <li class="start text-muted">
                                <div class="stepicon">
                                    <div class="stepiconcontainer" tooltip="{{theMessage.SenderPartyBusinessName}}">
                                        <i-feather name="play" class="stepiconicon"></i-feather>
                                    </div>
                                </div>
                            </li>
                            <li *ngFor="let theTransaction of lstTransactionsTimeLine" class="trans">
                                <div class="stepicon" [ngClass]="theTransaction.TransactionStatus.Text">
                                    <div [ngSwitch]="theTransaction.TransactionStatus.Icon" class="stepiconcontainer" tooltip="{{theTransaction.TransactionName}}">
                                        <i-feather name="check-circle" class="stepiconicon" *ngSwitchCase="'i-check-circle'"></i-feather>
                                        <i-feather name="x-circle" class="stepiconicon" *ngSwitchCase="'i-x-circle'"></i-feather>
                                        <i-feather name="clock" class="stepiconicon" *ngSwitchCase="'i-clock'"></i-feather>
                                    </div>
                                </div>
                            </li>
                            <li class="end text-muted">
                                <div class="stepicon">
                                    <div class="stepiconcontainer" tooltip="{{theMessage.ReceiverPartyBusinessName}}">
                                        <i-feather name="square" class="stepiconicon"></i-feather>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <hr *ngIf="!isLoading && lstTransactions.length > 0 && lstTransactions.length <= 6" />
                    <!-- Transaction table -->
                    <div class="container-fluid">
                        <div class="table-responsive c-table-view">
                            <table class="table table-borderless table-sm table-striped transactiontable" *ngIf="!isLoading && lstTransactions.length > 0">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let theTransaction of lstTransactions">
                                        <td class="fit">{{theTransaction.DateCreated | date: this.SETTINGS.DateFormat}}</td>
                                        <td>
                                            <span [ngSwitch]="theTransaction.TransactionStatus.Icon" class="icon" [ngClass]="theTransaction.TransactionStatus.Text">
                                                <i-feather name="check-circle" *ngSwitchCase="'i-check-circle'"></i-feather>
                                                <i-feather name="x-circle" *ngSwitchCase="'i-x-circle'"></i-feather>
                                                <i-feather name="clock" *ngSwitchCase="'i-clock'"></i-feather>
                                            </span>
                                            {{theTransaction.TransactionName}} <br />
                                            <span class="transerrorinfo" *ngIf="theTransaction.TransactionInfo" [innerHTML]="'<pre>' + theTransaction.TransactionInfo + '</pre>'"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr />
                    <!-- Repository (sender, receiver) -->
                    <div class="container-fluid" *ngIf="!isLoading">
                        <div class="table-responsive">
                            <table class="table table-borderless table-sm table-striped" *ngFor="let prc of lstProcessesIncludingMessages">
                                <thead>
                                    <tr>
                                        <th>Archived messages in process: <span class="bold">{{prc.Process.ProcessName}}</span></th>
                                        <th *ngIf="this.downloadReferenceEnabled">Reference</th>
                                        <th></th> <!-- Empty column header for column containing download buttons -->
                                        <th></th> <!-- Empty column header for column containing reprocess buttons -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="downloadtable" *ngFor="let msg of prc.Messages">
                                        <td [class.highlightdlmessage]="msg.Id == theMessage.Id" class="overflow maxwidth0">
                                            {{msg.MessageTypeBusinessName}}
                                        </td>
                                        <td *ngIf="this.downloadReferenceEnabled" class="fit reference">
                                            <span *ngIf="msg.ReprocessTriggerStatus" [innerHTML]="msg.DownloadReference.Value[0]" tooltip="{{msg.DownloadReference.Key}}"></span>
                                        </td>
                                        <ng-template #reproBlock>
                                            <a *ngIf="msg.ReprocessTriggerStatus == 'ready'" (click)="reprocessClicked(msg); $event.stopPropagation();">
                                                <i-feather name="repeat" class="text-muted" [ngClass]="{'loading': theReprocessMessageStatus == 'loading'}"></i-feather>
                                            </a>
                                            <a *ngIf="msg.ReprocessTriggerStatus == 'description' " tooltip="{{msg.ReprocessDescription}}">
                                                <i-feather name="repeat" class="text-muted" ngClass="not-allowed"></i-feather>
                                            </a>
                                            <a *ngIf="msg.ReprocessTriggerStatus == 'queued'" class="not-allowed">
                                                <i-feather name="check" class="txtGreen"></i-feather>
                                            </a>
                                            <a *ngIf="msg.ReprocessTriggerStatus == 'fail'" class="not-allowed">
                                                <i-feather name="x" class="txtRed"></i-feather>
                                            </a>
                                        </ng-template>
                                        <ng-template #viewBlock>
                                            <a (click)="eyeClicked(msg); $event.stopPropagation();">
                                                <i-feather name="eye" class="text-muted"></i-feather>
                                            </a>
                                        </ng-template>

                                        <td *ngIf="msg.HasViews && msg.ReprocessTriggerStatus != 'notapplicable'" class="fit icon">
                                            <ng-container *ngTemplateOutlet="viewBlock"></ng-container>
                                        </td>
                                        <td *ngIf="msg.HasViews && msg.ReprocessTriggerStatus != 'notapplicable'" class="fit icon">
                                            <ng-container *ngTemplateOutlet="reproBlock"></ng-container>
                                        </td>

                                        <td *ngIf="!msg.HasViews && msg.ReprocessTriggerStatus != 'notapplicable'" class="fit icon"></td>
                                        <td *ngIf="!msg.HasViews && msg.ReprocessTriggerStatus != 'notapplicable'" class="fit icon">
                                            <ng-container *ngTemplateOutlet="reproBlock"></ng-container>
                                        </td>

                                        <td *ngIf="msg.HasViews && msg.ReprocessTriggerStatus == 'notapplicable'" class="fit icon">
                                        </td>
                                        <td *ngIf="msg.HasViews && msg.ReprocessTriggerStatus == 'notapplicable'" class="fit icon">
                                            <ng-container *ngTemplateOutlet="viewBlock"></ng-container>
                                        </td>

                                        <td *ngIf="!msg.HasViews && msg.ReprocessTriggerStatus == 'notapplicable'" class="fit icon"></td>
                                        <td *ngIf="!msg.HasViews && msg.ReprocessTriggerStatus == 'notapplicable'" class="fit icon"></td>

                                        <td class="fit downloadbtn">
                                            <button tooltip="Copy URL to share" *ngIf="msg.Id == theMessage.Id" class="btn btn-primary sharebtn" (click)="btnShareUrl(msg)">
                                                <i-feather name="link"></i-feather>
                                            </button>
                                            <button tooltip="Download message" [disabled]="!msg.HasContent" [ngClass]="{'btn-primary': msg.Id == theMessage.Id, 'btn-secondary': msg.Id != theMessage.Id}" class="btn downloadbtn" (click)="btnDownloadClick(msg)">
                                                <i-feather name="download"></i-feather>
                                            </button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" *ngIf="isLoading">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                            Loading message info.
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="modal-body">
                <div class="container-fluid">
                    <button class="btn btn-secondary btn-block" (click)="hideChildModal()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Businessview modal placeholder -->
<app-tnt-businessview></app-tnt-businessview>
