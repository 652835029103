<nav class="navbar navbar-expand-sm bg-primary">
    <a class="navbar-brand" href="#">
      <img class="d-inline-block align-top logo align-middle" src="assets/img/clientLogo.png" onError="this.src='./assets/img/logo.png';" alt="Logo">
    </a>
    <button class="navbar-toggler text-primary-inverse collapsed" type="button" (click)="isCollapsed = !isCollapsed" aria-expanded="false">
        <i-feather name="menu"></i-feather>
    </button>
    <div class="navbar-collapse collapse" [collapse]="isCollapsed">
        <ul class="navbar-nav" *ngIf="this.oauthService.hasValidIdToken()">
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a class="nav-link text-primary-inverse align-middle" routerLink="/">Messages</a></li>
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a class="nav-link text-primary-inverse align-middle" *ngIf="showAdminLinks" routerLink="/portal">Permissions</a></li>
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"><a class="nav-link text-primary-inverse align-middle" *ngIf="showAdminLinks" routerLink="/config">Configuration</a></li>
        </ul>
        <ul class="navbar-nav ml-auto" *ngIf="this.oauthService.hasValidIdToken()">
            <li class="nav-item">
                <div class="btn-group" dropdown>
                    <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-light dropdown-toggle logoutdropdown" aria-controls="dropdown-basic">
                        <i-feather name="user"></i-feather> {{this.userName}} <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem"><a class="dropdown-item" [routerLink]="" (click)="logoutClicked()"><i-feather name="log-out"></i-feather> Logout</a></li>
                        <li role="menuitem" id="liVersion"><span class="version small">Version: {{this.dIMVersion}}</span></li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</nav>
