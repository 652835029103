<ng-template #filterMessagetype>
    <div class="filterwidth">
        <ng-select [items]="lstAllMessageTypes"
                   [multiple]="false"
                   [clearable]="true"
                   bindLabel="BusinessName"
                   [(ngModel)]="srcMessageTypeFilter">
        </ng-select>
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="getAllReprocessTriggers(); popoverMessageType.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearSearchMessagetype(); popoverMessageType.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<ng-template #filterURL>
    <div class="filterwidth">
        <input type="text" #inputURLSearch class="form-control" placeholder="URL" [(ngModel)]="srcURL" (keyup.enter)="getAllReprocessTriggers(); popoverURL.hide()">
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="getAllReprocessTriggers(); popoverURL.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearSearchURL(); popoverURL.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<div class="row paddingTable container-fluid">
    <div class="col-md-12 paddingBottomTable">
        <div class="card">
            <div class="table-responsive" style="overflow: inherit">
                <table class="table table-hover p-3 mb-5 tableLikeCard">
                    <thead class="text-primary-inverse">
                        <tr>
                            <th class="col-3 firstCell">MessageType <i-feather name="filter" [popover]="filterMessagetype" placement="right" #popoverMessageType="bs-popover" class="filterIcon" [ngClass]="{'fill': blnSearchMessageTypeApplied}"></i-feather></th>
                            <th class="col-5">URL<i-feather name="filter" [popover]="filterURL" placement="right" #popoverURL="bs-popover" class="filterIcon" [ngClass]="{'fill': blnSearchURLApplied}" (onShown)="focusInputSearchURL()"></i-feather></th>
                            <th class="smallTD-6 centerText">Payload</th>
                            <th class="smallTD-6 centerText">Metadata</th>
                            <th class="smallTD-6 centerText">Process</th>
                            <th class="smallTD-6 centerText"></th> <!--placeholder delete-->
                        </tr>
                    </thead>
                    <tbody class="pointer">
                        <tr id="lastTR">

                            <td class="col-5" (click)="newTriggerClicked(); $event.stopPropagation();">
                                <ng-select [items]="lstFEMessageTypes"
                                           [multiple]="false"
                                           [clearable]="true"
                                           bindLabel="BusinessName"
                                           [(ngModel)]="selNewReprocessTriggerBn">
                                </ng-select>
                            </td>

                            <td class="col-5 ellipsis">
                                <div class="form-group">
                                    <input class="form-control" [(ngModel)]="strNewReprocessTriggerURL" />
                                </div>
                            </td>

                            <td (click)="PayloadNewReprocessTriggerClicked(); $event.stopPropagation();" class="centerText smallTD-6">
                                <i-feather name="square" *ngIf="blnIncludePayloadNewReprocessTrigger == false"></i-feather>
                                <i-feather name="check-square" *ngIf="blnIncludePayloadNewReprocessTrigger == true"></i-feather>
                            </td>

                            <td (click)="MetadataNewReprocessTriggerClicked(); $event.stopPropagation();" class="centerText smallTD-6">
                                <i-feather name="square" *ngIf="blnIncludeMetadataNewReprocessTrigger == false"></i-feather>
                                <i-feather name="check-square" *ngIf="blnIncludeMetadataNewReprocessTrigger == true"></i-feather>
                            </td>

                            <td (click)="ProcessNewReprocessTriggerClicked(); $event.stopPropagation();" class="centerText smallTD-6">
                                <i-feather name="square" *ngIf="blnIncludeProcessNewReprocessTrigger == false"></i-feather>
                                <i-feather name="check-square" *ngIf="blnIncludeProcessNewReprocessTrigger == true"></i-feather>
                            </td>
                            <td class="smallTD-6 centerText">
                                <button class="btn btn-outline-primary" type="button" (click)="btnAddReprocessTriggerClicked()">
                                    <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstReprocessTriggers.length == 0">
                                No reprocess triggers found.
                            </td>
                            <td colspan="12" *ngIf="isLoading">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                        Loading reprocess triggers
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr *ngFor="let trigger of lstReprocessTriggers; let i = index" [class.active]="i == selectedRow">
                            <ng-container>
                                <td class="firstCell col-5" [ngClass]="{'ellipsis': selTriggerMtId != trigger.Id}" (click)="triggerClicked(i, 'MessageType', trigger); $event.stopPropagation();">
                                    <span *ngIf="selTriggerMtId != trigger.Id"> {{trigger.MessageTypeBusinessName}}</span>
                                    <ng-select *ngIf="selTriggerMtId == trigger.Id"
                                               [items]="lstFEMessageTypes"
                                               [multiple]="false"
                                               [clearable]="true"
                                               bindLabel="BusinessName"
                                               [(ngModel)]="selTriggerBn"
                                               (ngModelChange)="bnTriggerChanged(trigger)">
                                    </ng-select>
                                </td>

                                <td class="col-5 ellipsis" [ngClass]="selTriggerUrlId != trigger.Id" (click)="triggerClicked(i, 'URL', trigger); $event.stopPropagation();">
                                    <div class="form-group">
                                        <span *ngIf="selTriggerUrlId != trigger.Id">{{trigger.URL}}</span>
                                        <input #inputURL class="form-control" *ngIf="selTriggerUrlId == trigger.Id" [(ngModel)]="trigger.URL" (input)="URLChanged(trigger)"/>
                                    </div>
                                </td>

                                <td (click)="PayloadClicked(trigger); $event.stopPropagation();" class="centerText smallTD-6">
                                    <i-feather name="square" *ngIf="trigger.IncludePayload == false"></i-feather>
                                    <i-feather name="check-square" *ngIf="trigger.IncludePayload == true"></i-feather>
                                </td>

                                <td (click)="MetadataClicked(trigger); $event.stopPropagation();" class="centerText smallTD-6 ">
                                    <i-feather name="square" *ngIf="trigger.IncludeMetadata == false"></i-feather>
                                    <i-feather name="check-square" *ngIf="trigger.IncludeMetadata == true"></i-feather>
                                </td>

                                <td (click)="ProcessClicked(trigger); $event.stopPropagation();" class="centerText smallTD-6 ">
                                    <i-feather name="square" *ngIf="trigger.IncludeProcess == false"></i-feather>
                                    <i-feather name="check-square" *ngIf="trigger.IncludeProcess == true"></i-feather>
                                </td>
                                <!--Delete-->
                                <td class="smallTD-6 centerText">
                                        <span (click)="removeReprocessTrigger(trigger)" class="hoverTrashIcon"><i-feather name="trash"></i-feather></span>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
