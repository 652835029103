<ng-template #filterMessagetype>
    <div class="filterwidth">
        <input type="text" #inputMessageTypeSearch class="form-control" placeholder="MessageType" [(ngModel)]="srcMessageType" (keyup.enter)="getMessageType(); popoverMessageType.hide()">
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="getMessageType(); popoverMessageType.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearSearchMessageType(); popoverMessageType.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<div class="container-fluid paddingTop">
    <h1 class="display-4 display">Business view administration</h1>
</div>
<div class="row paddingTable container-fluid">
    <div class="col-md-6">
        <div class="card">
            <div class="card-header card-header-groups text-primary-inverse">
                MessageType
                <i-feather name="filter" [popover]="filterMessagetype" placement="right" #popoverMessageType="bs-popover" class="filterIcon" [ngClass]="{'fill': blnSearchMessageTypeApplied}" (onShown)="focusInputSearchMessageTypeBn()"></i-feather>
            </div>
            <div class="card-body nopadding scrollCardNoFooter pointer">
                <ul class="list-group">
                    <li class="nomsg text-muted" *ngIf="!isLoading && lstMessageTypes.length == 0">
                        No messagetypes found.
                    </li>
                    <li *ngFor="let mt of lstMessageTypes" (click)="messageTypeClicked(mt)" [value]="mt.Id" [class.active]="selMtId == mt.Id" class="list-group-item list-group-item-action">
                        <div class="form-row">
                            <div class="form-group col-md-10">
                                <span>{{mt.MessageType}} ({{mt.BusinessName}})</span>
                            </div>
                            <div class="col-md-2 middleMarginAuto">
                                <span class="right">
                                    <i-feather name="eye" *ngIf="lstMessageTypeIdsThatHasBv.indexOf(mt.Id) > -1"></i-feather>
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="col-md-6" *ngIf="selMtId !== -1">
        <textarea class="form-control text-area-fullscreen" *ngIf="selMtId !== -1" [ngModel]="selBvXSLnew" (ngModelChange)="businessViewChanged($event)"></textarea>
        <!--<ngx-monaco-editor class="xsl-code-editor" [options]="editorOptions" [ngModel]="selBvXSLnew" (ngModelChange)="businessViewChanged($event)"></ngx-monaco-editor>-->

        <div class="alignRight" *ngIf="selMtId !== -1">
            <button type="button" class="btn btn-success saveBtn" (click)="saveBv()"><i-feather name="save"></i-feather> Save</button>
            <button type="button" class="btn btn-danger" (click)="discardBv()"><i-feather name="x-circle"></i-feather> Discard</button>
        </div>
    </div>

    
</div>
