import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserportalService } from './userportal.service';
import { SnackbarService } from 'ngx-snackbar';

import { TnTAPIService } from '../_services/';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { log } from 'util';

@Component({
    styleUrls: ['./userportal_groups.component.scss'],
    templateUrl: './userportal_groups.component.html',
    providers: [UserportalService]
})

export class UserportalGroupsComponent implements OnInit {
    // Businessview modal

    public lstGroups: Array<any> = [];

    public blnLoadingDetails: boolean = true;
    private blnLoadingDetailsParties: boolean = true;
    private blnLoadingDetailsMsgType: boolean = true;
    public blnLoadingGroups: boolean = true;
    public blnEditGroupName: boolean = false;

    // Timer
    private timer;

    // Selected group
    // - Details
    public selGroup: TnTGroup = null;
    public selGroupId: number = -1;
    public deletedGroup: string = '';

    public lstOriginalParties: any;
    public lstParties: any;
    public lstOriginalMessageTypes: any;
    public lstMessageTypes: any;

    public newGroupName: string = '';

    public event: string = '';
    public addedGroupName: string = '';
    public mvUserGroupId: number = null;
    public blnCanDelete: boolean = false;

    public allSelectedMessageTypes: boolean = false;
    public allSelectedParties: boolean = false;

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private userportalService: UserportalService, private snackbarService: SnackbarService) {

        userportalService.newGroup$.subscribe(
            group => { this.getGroups(); }
        );

        userportalService.deletedGroup$.subscribe(
            group => {
                this.selGroup = null;
                this.deletedGroup = group;
                this.getGroups();
            }
        );
    }

    public ngOnInit() {
        this.getGroups();
        this.getMessageTypes();
        this.getParties();
    }

    public groupSelected(theGroup: any): void {
        this.deletedGroup = '';
        this.selGroup = { Id: theGroup.id, Groupname: theGroup.text };
        this.selGroupId = theGroup.id;
        this.getGroupDetails(this.selGroup);
    }

    public getMessageTypes(): void {
        this.svcAPI.getMessageTypes(false, false).subscribe(
            messagetypes => {
                this.lstOriginalMessageTypes = [];
                for (const messagetype of messagetypes) {
                    const data = { 'MessageType': messagetype, 'Active': 0 };
                    this.lstOriginalMessageTypes.push(data);
                }
            }
        );
    }

    public getParties(): void {
        this.svcAPI.getParties().subscribe(
            parties => {
                this.lstOriginalParties = [];
                for (const party of parties) {
                    const data = { 'Party': party, 'Active': 0 };
                    this.lstOriginalParties.push(data);
                }
            }
        );
    }

    public saveParties(): void {
        const activePartyIds: number[] = [];
        this.lstParties.forEach((party, idx) => {
            if (party.Active === 1) {
                activePartyIds.push(party.Party.Id);
            }
        });

        if (this.selGroup != null) {
            this.svcAPI.portalSetGroupParties(this.selGroup, activePartyIds)
                .pipe(catchError((error) => {
                    return of(null); // Return empty message
                }))
                .subscribe(
                    response => {
                        if (response == null) {
                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Failed to add permission.',
                                timeout: 2000,
                                customClass: 'snackBarError',
                                action: {
                                    text: 'X'
                                }
                            });

                            this.getGroupDetails(this.selGroup);
                        } else {

                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Saved',
                                timeout: 2000,
                                customClass: 'snackBarSucces',
                                action: {
                                    text: 'X'
                                }
                            });

                        }
                    });
        }
    }

    public groupRemoved(): void {
        this.selGroup = null;
    }

    public saveMessageTypes(): void {
        const activeMessageTypeIds: number[] = [];
        this.lstMessageTypes.forEach((messagetype, idx) => {
            if (messagetype.Active === 1) {
                activeMessageTypeIds.push(messagetype.MessageType.Id);
            }
        });
        if (this.selGroup != null) {
            this.svcAPI.portalSetGroupMessageTypes(this.selGroup, activeMessageTypeIds)
                .pipe(catchError((error) => {
                    return of(null); // Return empty message
                }))
                .subscribe(
                    response => {
                        if (response == null) {
                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Failed to add permission.',
                                timeout: 2000,
                                customClass: 'snackBarError',
                                action: {
                                    text: 'X'
                                }
                            });

                            this.getGroupDetails(this.selGroup);
                        } else {

                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Saved',
                                timeout: 2000,
                                customClass: 'snackBarSucces',
                                action: {
                                    text: 'X'
                                }
                            });

                        }
                    });
        }
    }

    public messageTypeClicked(clickedMessageType: any): void {
        this.lstMessageTypes.forEach((messagetype, idx) => {
            if (messagetype.MessageType.Id === clickedMessageType.MessageType.Id) {
                if (clickedMessageType.Active === 0)
                    this.lstMessageTypes[idx].Active = 1;
                else
                    this.lstMessageTypes[idx].Active = 0;
            }
        });
        this.saveMessageTypes();
    }

    public partyClicked(clickedParty: any): void {
        this.lstParties.forEach((party, idx) => {
            if (party.Party.Id === clickedParty.Party.Id) {
                if (clickedParty.Active === 0)
                    this.lstParties[idx].Active = 1;
                else
                    this.lstParties[idx].Active = 0;
            }
        });
        this.saveParties();
    }

    private getGroupDetails(theGroup: TnTGroup): void {
        this.blnLoadingDetails = true;
        this.blnLoadingDetailsParties = true;
        this.blnLoadingDetailsMsgType = true;

        // Assignment keeps reference to the previous array.
        this.lstMessageTypes = this.lstOriginalMessageTypes.map(x => Object.assign({}, x));

        // Retrieve active messagetypes
        this.svcAPI.portalGetGroupMessageTypes(theGroup.Id).subscribe(
            messagetypes => {
                this.blnLoadingDetailsMsgType = false;
                this.allSelectedMessageTypes = this.lstMessageTypes.length === messagetypes.length;
                this.lstMessageTypes.forEach((messagetype, idx) => {
                    messagetypes.forEach((activeMessagetype) => {
                        if (messagetype.MessageType.Id === activeMessagetype.Id) {
                            this.lstMessageTypes[idx].Active = 1;
                        }
                    });
                });

                if (!this.blnLoadingDetailsParties) {
                    this.blnLoadingDetails = false;
                }
            }
        );

        // Assignment keeps reference to the previous array.
        this.lstParties = this.lstOriginalParties.map(x => Object.assign({}, x));

        // Retrieve active parties
        this.svcAPI.portalGetGroupParties(theGroup.Id).subscribe(
            parties => {
                this.blnLoadingDetailsMsgType = false;
                this.allSelectedParties = this.lstParties.length === parties.length;
                this.lstParties.forEach((party, idx) => {
                    parties.forEach((activeParties) => {
                        if (party.Party.Id === activeParties.Id) {
                            this.lstParties[idx].Active = 1;
                        }
                    });
                });

                if (!this.blnLoadingDetailsMsgType) {
                    this.blnLoadingDetails = false;
                }
            }
        );
    }

    private getGroups(): void {
        this.blnLoadingGroups = true;
        this.svcAPI.portalGetGroups().subscribe(
            groups => {
                this.lstGroups = [];
                for (const group of groups) {
                    const theGroup = <TnTGroup>group;
                    this.lstGroups.push({
                        id: theGroup.Id,
                        text: theGroup.Groupname
                    });
                }
                this.blnLoadingGroups = false;
            }
        );
    }

    public addNewGroup(): void {
        const theGroup: TnTGroup = <TnTGroup>{
            Groupname: this.newGroupName
        };
        this.svcAPI.portalAddGroup(theGroup).subscribe(
            result => {
                if (result) { // user added
                    this.userportalService.emitNewGroup(this.newGroupName);
                    this.addedGroupName = this.newGroupName;
                    this.newGroupName = '';

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Added',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });

                }
            }
        );
    }

    public removeGroup(): any {
        this.svcAPI.portalRemGroup(this.selGroup).subscribe(
            result => {
                if (result) {

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Removed',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });

                    this.getGroups();
                    this.selGroup = null;
                }
            }
        );
    }

    public editGroup(): any {
        this.blnEditGroupName = !this.blnEditGroupName;
    }

    public saveGroupName(newname: string): void {
        clearInterval(this.timer);
        this.timer = setInterval(() => {
            this.selGroup.Groupname = newname;
            this.saveChangeGroup(this.selGroup);
            clearInterval(this.timer);
        }, 800);
    }

    //Change Group
    public saveChangeGroup(group: TnTGroup) {
        if (group != null) {
            this.svcAPI.portalModGroup(group)
                .pipe(catchError((error) => {
                    return of(null); // Return empty message
                }))
                .subscribe(
                    response => {
                        if (response == null) {

                        }
                    });
        }
    }

    public clickSelectAll(type: string) {
        if (type === 'messagetype') {
            this.allSelectedMessageTypes = !this.allSelectedMessageTypes;
            if (this.allSelectedMessageTypes) {
                this.lstMessageTypes.forEach((messagetype, idx) => {
                    this.lstMessageTypes[idx].Active = 1;
                });
                this.saveMessageTypes();
            } else {
                this.lstMessageTypes.forEach((messagetype, idx) => {
                    this.lstMessageTypes[idx].Active = 0;
                });
                this.saveMessageTypes();
            }
        } else if (type === 'party') {
            this.allSelectedParties = !this.allSelectedParties;
            if (this.allSelectedParties) {
                this.lstParties.forEach((party, idx) => {
                    this.lstParties[idx].Active = 1;
                });
                this.saveParties();
            } else {
                this.lstParties.forEach((party, idx) => {
                    this.lstParties[idx].Active = 0;
                });
                this.saveParties();
            }
        }
    }
}
