import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../_services/';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SnackbarService } from 'ngx-snackbar';


@Component({
    styleUrls: ['../configuration.component.scss','./configuration_parties.component.scss'],
    templateUrl: './configuration_parties.component.html',
})
export class ConfigurationPartiesComponent implements OnInit {
    // Loader
    public isLoading: boolean;

    selectedRow: Number;

    // Lists
    public lstParties: Array<TnTParty> = [];
    public lstPartiesBusinessNames: Array<TnTPartyBusinessName> = [];

    // Timer
    private timer;

    // Party BusinessNames var
    public selParty: TnTParty;
    public selPartyId: number = -1;

    public selPartyBn: TnTPartyBusinessName;
    public selPartyBnId: number = -1;

    // id of the party which you have selected the businessname from
    public selPartyIdOfBn: number = -1;

    public strNewPartyName: string = '';
    public strPrevPartyName: string = '';

    public selNewPartyBn: TnTPartyBusinessName;
    public strNewParty: string = '';
    public blnNewPartySearchable: boolean = false;

    // filters var
    public srcParty: string = '';
    public blnSearchPartyApplied = false;

    public selPartyBnFilter: TnTPartyBusinessName = null;
    public blnSearchPartyBusinessNameApplied = false;

    // Add
    public AddEnabled: boolean = false;

    @ViewChild('inputParty', { static: false }) inputParty: any;
    @ViewChild('inputPartySearch', { static: false }) inputPartySearch: any;

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private snackbarService: SnackbarService) { }

    public ngOnInit() {
        this.isLoading = true;
        this.getParties();
        this.getPartyBusinessNames();
        this.isLoading = false;
    }

    public getParties(): void {
        this.svcAPI.getParties(false, this.srcParty, this.selPartyBnFilter)
            .pipe(catchError((error) => {
                return of([]); // Return empty message set
            }))
            .subscribe(
            parties => {
                this.lstParties = <TnTParty[]>(parties);
            }
        );
        this.blnSearchPartyApplied = this.srcParty !== '';
        this.blnSearchPartyBusinessNameApplied = this.selPartyBnFilter !== null;
    }

    public getPartyBusinessNames(): void {
        this.svcAPI.getPartiesBusinessNames().subscribe(
            bn => {
                this.lstPartiesBusinessNames = bn;
            }
        );
    }

    public partyClicked(i: number, clickedParty?: TnTParty, clickedPartyBn?: TnTParty): void {
        this.selectedRow = i;

        if (clickedParty !== undefined) {
            this.lstParties.forEach((p) => {
                if (p.Id === clickedParty.Id) {
                    this.selParty = clickedParty;
                    this.selPartyId = clickedParty.Id;
                    this.selPartyBn = null;
                    this.selPartyIdOfBn = -1;
                    this.selPartyBnId = -1;
                    this.strPrevPartyName = clickedParty.PartyName;
                    setTimeout(() => {
                        this.inputParty.nativeElement.focus();
                    }, 10);
                }
            });
        }
        if (clickedPartyBn !== undefined) {
            this.lstPartiesBusinessNames.forEach((bnP) => {
                if (clickedPartyBn.PartyBusinessNameId === bnP.Id) {
                    const selPartyBusinessName: TnTPartyBusinessName = <TnTPartyBusinessName>{
                        Id: clickedPartyBn.PartyBusinessNameId,
                        BusinessName: clickedPartyBn.PartyBusinessName
                    };
                    this.selParty = null;
                    this.selPartyId = -1;
                    this.selPartyBn = selPartyBusinessName;
                    this.selPartyIdOfBn = clickedPartyBn.Id;
                    this.selPartyBnId = selPartyBusinessName.Id;
                }
            });
        }
    }

    public bnPartyClicked(clickedbnP: TnTParty): void {}

    // changed functions
    public partyChanged(p: TnTParty): void {
        if (p != null) {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.svcAPI.modPartyName(p).subscribe(
                    result => {
                        if (result !== 'Succes') {
                            p.PartyName = this.strPrevPartyName;
                            const _this = this;
                            this.snackbarService.add({
                                msg: result,
                                timeout: 2000,
                                customClass: 'snackBarError',
                                action: {
                                    text: 'X'
                                }
                            });

                        } else {

                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Saved',
                                timeout: 2000,
                                customClass: 'snackBarSucces',
                                action: {
                                    text: 'X'
                                }
                            });

                        }
                    }
                );
                clearInterval(this.timer);
            }, 800);
        }
    }

    public bnPartyChanged(p: TnTParty): void {
        if (this.selPartyBn != null) {
            p.PartyBusinessNameId = this.selPartyBnId;
            this.svcAPI.modPartyPartyBusinessName(p).subscribe(
                result => {
                    this.getParties();
                }
            );
        }
    }

    public btnAddPartyClicked(): void {
        if (this.strNewParty) {
            this.strNewParty = this.strNewParty.trim();
            if (this.selNewPartyBn == null) {

                const _this = this;
                this.snackbarService.add({
                    msg: 'Please select or create a businessname for the messagetype.',
                    timeout: 2000,
                    customClass: 'snackBarError',
                    action: {
                        text: 'X'
                    }
                });

            } else {
                const theParty: TnTParty = <TnTParty>{
                    Id: -1,
                    PartyName: this.strNewParty,
                    PartyBusinessNameId: this.selNewPartyBn.Id,
                    PartyBusinessName: this.selNewPartyBn.BusinessName
                };

                this.svcAPI.portalAddParty(theParty).subscribe(
                    result => {
                        if (result === 'Succes') {
                            this.getParties();
                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Added',
                                timeout: 2000,
                                customClass: 'snackBarSucces',
                                action: {
                                    text: 'X'
                                }
                            });

                            this.blnNewPartySearchable = false;
                            this.strNewParty = null;
                            this.selNewPartyBn = null;
                        } else {
                            const _this = this;
                            this.snackbarService.add({
                                msg: result,
                                timeout: 2000,
                                customClass: 'snackBarError',
                                action: {
                                    text: 'X'
                                }
                            });

                        }
                    }
                );
            }
        } else {

            const _this = this;
            this.snackbarService.add({
                msg: 'Please fill in a name for the party.',
                timeout: 2000,
                customClass: 'snackBarError',
                action: {
                    text: 'X'
                }
            });

        }
    }

    public removeParty(theParty: TnTParty): void {
        this.svcAPI.portalRemParty(theParty).subscribe(
            result => {
                if (result === 'Succes') {
                    this.getParties();

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Removed',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });

                } else {
                    this.selParty = theParty;
                    this.selPartyId = theParty.Id;
                    const _this = this;
                    this.snackbarService.add({
                        msg: result,
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });

                }
            }
        );
    }

    public clearSearchParty(): void {
        this.srcParty = '';
        this.getParties();
        this.blnSearchPartyApplied = false;
    }

    public focusInputSearchParty(): void {
        setTimeout(() => {
            this.inputPartySearch.nativeElement.focus();
        }, 10);
    }

    public clearSearchPartyBusinessName(): void {
        this.selPartyBnFilter = null;
        this.getParties();
    }

    public btnAddEnabledClicked(): void {
        this.AddEnabled = !this.AddEnabled;
    }

}
