import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    private _SETTINGS: BehaviorSubject<TnTSettings> = new BehaviorSubject(null);
    public readonly SETTINGS: Observable<TnTSettings> = this._SETTINGS.asObservable(); 

    constructor(private svcHttp: HttpClient) {
        if (this._SETTINGS.value === null) {
            this.svcHttp.get(window.origin + '/api/configuration')
                .subscribe((res) => {
                    this._SETTINGS.next(<TnTSettings>res);
                });
        }
    }
}
