<div class="container-fluid paddingTop">
    <h1 class="display-4 display">Group administration</h1>
</div>

<div class="row paddingUPContent">
    <div class="col-md-4">
        <div class="card">
            <div class="card-header card-header-groups text-primary-inverse">
                Groups
            </div>
            <div class="card-body nopadding scrollCardFooter pointer">
                <ul class="list-group">
                    <!--<div class="input-group mb-3 addgroup">
                        <input type="text" class="form-control" placeholder="Search group" aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <button class="btn btn-outline-dark" type="button" id="button-addon2">
                                <i data-feather="search"></i>
                            </button>
                        </div>
                    </div>-->

                    <li *ngFor="let group of lstGroups" (click)="groupSelected(group)" [value]="group.id" [class.active]="selGroupId == group.id" class="list-group-item list-group-item-action">
                        <div class="form-row">
                            <div class="col-md-10">
                                <span *ngIf="selGroupId != group.id">{{group.text}}</span>
                                <span *ngIf="!blnEditGroupName && selGroupId == group.id">{{group.text}}</span>
                                <input *ngIf="blnEditGroupName && selGroupId == group.id" class="form-control" [(ngModel)]="group.text" (input)="saveGroupName(group.text);" />
                            </div>
                            <div class=" col-md-2">
                                    <span class="right" (click)="removeGroup()"><i-feather name="trash" *ngIf="selGroupId == group.id"></i-feather></span>
                                    <span class="hoverEditIcon" (click)="editGroup()"><i-feather name="edit-2" *ngIf="selGroupId == group.id"></i-feather></span>
                            </div>
                        </div>
      
                    </li>
                </ul>
            </div>
            <div class="card-footer">
                <form name="addgroup" (ngSubmit)="f.form.valid && addNewGroup()" #f="ngForm" novalidate>
                    <div class="input-group mb-3 addgroup">
                        <input [(ngModel)]="newGroupName" name="newGroupName" #groupname="ngModel" id="groupname" required type="text" class="form-control" placeholder="New group name" aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <button class="btn btn-outline-primary" type="submit" id="button-addon2" [disabled]="!groupname.valid">
                                <i-feather name="plus-circle"></i-feather>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-md-4" *ngIf="selGroup != null">
        <div class="card">
            <div class="card-header bg-primary text-primary-inverse pointer" (click)="clickSelectAll('messagetype')">
                <i-feather name="square" *ngIf="!allSelectedMessageTypes"></i-feather>
                <i-feather name="check-square" *ngIf="allSelectedMessageTypes"></i-feather>
                <span class="permissionsheader"><strong>{{selGroup.Groupname}}: </strong>MessageTypes</span>
            </div>
            <div class="card-body nopadding scrollCardNoFooter pointer">
                <ul class="list-group rights">
                    <li class="list-group-item list-group-item-action" *ngFor="let messageType of lstMessageTypes" [value]="messageType.MessageType.Id" (click)="messageTypeClicked(messageType)">
                        <span tooltip="{{messageType.MessageType.MessageType}}">
                            <i-feather name="square" *ngIf="messageType.Active == 0"></i-feather>
                            <i-feather name="check-square" *ngIf="messageType.Active != 0"></i-feather>
                            {{messageType.MessageType.BusinessName}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-4" *ngIf="selGroup != null">
        <div class="card">
            <div class="card-header bg-primary text-primary-inverse pointer" (click)="clickSelectAll('party')">
                <i-feather name="square" *ngIf="!allSelectedParties"></i-feather>
                <i-feather name="check-square" *ngIf="allSelectedParties"></i-feather>
                <strong>{{selGroup.Groupname}}: </strong>Parties
            </div>
            <div class="card-body nopadding scrollCardNoFooter pointer">
                <ul class="list-group rights">
                    <li class="list-group-item list-group-item-action" *ngFor="let party of lstParties" [value]="party.Party.Id" (click)="partyClicked(party)">
                        <span>
                            <i-feather name="square" *ngIf="party.Active == 0"></i-feather>
                            <i-feather name="check-square" *ngIf="party.Active != 0"></i-feather>
                            {{party.Party.PartyBusinessName}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-8 aligner" *ngIf="selGroup == null">
        <h1 class="display-4 display aligner-item">Select a group to edit rights, permissions and parties</h1>
    </div>
</div>
