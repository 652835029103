import { Component, ViewChild, AfterViewInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { Location } from "@angular/common";
import { TnTAPIService, ConfigurationService } from "../../../../_services";
import { SnackbarService } from "ngx-snackbar";
import { ModalDirective } from "ngx-bootstrap";

import { Observable, forkJoin, pipe, of, Subject } from "rxjs";
import { catchError } from "rxjs/operators";

import { OAuthService } from "angular-oauth2-oidc";

@Component({
    styleUrls: ["./configuration_alerting_actions_detail.component.scss"],
    templateUrl: "./configuration_alerting_actions_detail.component.html",
    selector: "app-tnt-configuration_alerting_actions_detail",
})
export class ConfigurationAlertingActionsDetailComponent
    implements AfterViewInit {
    @ViewChild("childModal", { static: true })
    public childModal: ModalDirective;
    public theAction: TnTAlertingAction;
    // Timer
    private timer;

    // Loader
    public isLoading: boolean;
    public addEndpointEnabled: boolean = false;
    public selectedRow: Number;
    public selection: string;


    // SELECTED ATTRIBUTES
    public selEndpointUrl: TnTAlertingSubscriptionEndpoint;
    public selEndpointUrlId: number = -1;


    public SETTINGS: TnTSettings;

    public lstSelections: Array<string> = [];
    private lstAlertingActions: Array<TnTAlertingAction> = [];
    public lstFormats: Array<string> = [];

    public lstEmails: any = [];
    public lstWebhooks: any [];
    public lstOriginalEndpoints: any [];
    public allSelectedEndpoints: boolean = false;

    constructor(
        private oauthService: OAuthService,
        private svcAPI: TnTAPIService,
        private svcRouter: Router,
        public svcConfig: ConfigurationService,
        public svcDomSanitization: DomSanitizer,
        private snackbarService: SnackbarService,
        private svcLocation: Location,
        private router: Router
    ) {
        this.svcConfig.SETTINGS.subscribe((res) => {
            if (res) {
                this.SETTINGS = res;
            }
        });
    }

    ngOnInit() {
        this.theAction = <TnTAlertingAction>{
            Id: -1,
            Name:'',
            Status: 0,
            Subscription: <TnTAlertingSubscription>{Type:'', Description:'', Endpoints:null},
            Description: ''
        };
    }

    getAlertingActions() {
        this.svcAPI.getAlertingActions()
        .pipe(catchError((error) => {
            return of([]); // Return empty message set
        }))
        .subscribe(
            actions => {
                this.lstAlertingActions = actions
            }
        );
    }

    save() {
        const _this = this;
        this.svcAPI.modifyAlertingAction(this.theAction).subscribe(
            message => {
                console.log(message)
                if(message == 'Success'){
                this.snackbarService.add({
                    msg: "Saved",
                    timeout: 2000,
                    customClass: "snackBarSucces",
                    action: {
                        text: "X",
                    },
                });
                }else{
                    this.snackbarService.add({
                        msg: message,
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });
                }
            }
        );
    }

    // getSelections() {
    //     this.lstSelections = ["Group", "MessageTypeBusinessName", "PartiesBusinessName"];
    //     this.selection = this.lstSelections[0];
    // }

    ngAfterViewInit() {
        this.childModal.onHidden.subscribe(() => {
            // Set correct route parameter (permalinks)
            this.svcLocation.replaceState("/");
        });
    }

    private modelIsopened = true;

    showChildModal(action: TnTAlertingAction): void {
        // Set correct route parameter (permalinks)
        this.svcLocation.replaceState("/action/" + action.Id);
        // Load stuff
        this.isLoading = true;

        //this.getSelections();
        //this.getTypeAuthentications();
        
        this.theAction = <TnTAlertingAction>{
            Id: action.Id,
            Name: action.Name,
            Status: action.Status,
            Subscription: action.Subscription,
            Description: action.Description,
            Endpoints: action.Endpoints,
            Condition: action.Condition
        };
        if(this.theAction.Subscription.Type == 'Email'){
        this.svcAPI.getAlertingEmails()
        .pipe(catchError((error) => {
            return of([]); // Return empty message set
        }))
        .subscribe(
            endpoints => {
                    this.lstOriginalEndpoints = [];
                    for (const messagetype of endpoints) {
                        const data = { 'Endpoint': messagetype, 'Active': 0 };
                        this.lstOriginalEndpoints.push(data);
                    }
                    // Assignment keeps reference to the previous array.
                    this.lstEmails = this.lstOriginalEndpoints.map(x => Object.assign({}, x));
            
                    // Retrieve active endpoints
                    this.allSelectedEndpoints = this.lstEmails.length === this.theAction.Endpoints.length;
                    this.lstEmails.forEach((messagetype, idx) => {
                        this.theAction.Endpoints.forEach((activeMessagetype) => {
                            if (messagetype.Endpoint.Id === activeMessagetype.Id) {
                                        this.lstEmails[idx].Active = 1;
                            }
                        });
                    });
                
            }
        );
        }else if(this.theAction.Subscription.Type == 'Webhook'){
            this.svcAPI.getAlertingWebhooks()
            .pipe(catchError((error) => {
                return of([]); // Return empty message set
            }))
            .subscribe(
                endpoints => {
                    this.lstOriginalEndpoints = [];
                    for (const messagetype of endpoints) {
                        const data = { 'Endpoint': messagetype, 'Active': 0 };
                        this.lstOriginalEndpoints.push(data);
                    }
                    // Assignment keeps reference to the previous array.
                    this.lstWebhooks = this.lstOriginalEndpoints.map(x => Object.assign({}, x));
            
                    // Retrieve active endpoints
                    this.allSelectedEndpoints = this.lstWebhooks.length === this.theAction.Endpoints.length;
                    this.lstWebhooks.forEach((messagetype, idx) => {
                        this.theAction.Endpoints.forEach((activeMessagetype) => {
                            if (messagetype.Endpoint.Id === activeMessagetype.Id) {
                                        this.lstWebhooks[idx].Active = 1;
                            }
                        });
                    });
                }
            );
        }

        this.isLoading = false;
        // Show modal to user
        this.childModal.show();
    }

    hideChildModal(): void {
        // Hide modal
        this.childModal.hide();
    }

    getMaxLength(theArray: string[]): number {
        let theLength: number = 0;
        for (const el of theArray) {
            if (el.length > theLength) theLength = el.length;
        }
        return theLength;
    }

    resetClicked(){
        this.selEndpointUrl = null;
        this.selEndpointUrlId = -1;
    }

    btnToggleEndpointClicked() {
        this.addEndpointEnabled = !this.addEndpointEnabled;
    }

    removeEndpoint(endpoint){
        const index = this.theAction.Subscription.Endpoints.indexOf(endpoint, 0);
        if (index > -1) {
            this.theAction.Subscription.Endpoints.splice(index, 1);
        }
    }

    emailClicked(endpoint){
        var index: number = this.lstEmails.indexOf(endpoint);
        if(this.lstEmails[index].Active == 0){
            this.svcAPI.addActionEndpoint(this.theAction.Id, endpoint.Endpoint).subscribe(
                message => {
                    console.log(message)
                    if(message == "Success"){
                        this.theAction.Endpoints.push(endpoint);
                        this.lstEmails[index].Active = 1
                    }
                }
            );
        }else{
            this.svcAPI.deleteActionEndpoint(this.theAction.Id, endpoint.Endpoint.Id).subscribe(
                message => {
                    console.log(message)
                    this.theAction.Endpoints.splice(index, 1);
                    this.lstEmails[index].Active = 0
                }
            );
        }
    }
    
    webhookClicked(endpoint){
        var index: number = this.lstWebhooks.indexOf(endpoint);

        if(this.lstWebhooks[index].Active == 0){
            this.svcAPI.addActionEndpoint(this.theAction.Id, endpoint.Endpoint).subscribe(
                message => {
                    console.log(message)
                    if(message == "Success"){
                    this.theAction.Endpoints.push(endpoint);
                    this.lstWebhooks[index].Active = 1
                    }
                }
            );
        }else{
            this.svcAPI.deleteActionEndpoint(this.theAction.Id, endpoint.Endpoint.Id).subscribe(
                message => {
                    console.log(message)
                    this.theAction.Endpoints.splice(index, 1);
                    this.lstWebhooks[index].Active = 0
                }
            );
        }
    }
}
