<!-- Header -->
<div class="container-fluid paddingTop">
    <h1 class="display-4 display">Transaction status configuration</h1>
    <button class="btn btn-primary float-right addButton" type="button" (click)="btnAddEnabledClicked()">
        <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
    </button>
</div>

<!-- Content -->
<div class="row paddingTable container-fluid">
    <div class="col-md-12 paddingBottomTable">
        <div class="card">
            <div class="table-responsive" style="overflow: inherit">
                <table class="table table-hover p-3 mb-5 tableLikeCard">
                    <thead class="text-primary-inverse">
                        <tr >
                            <th class="smallTD-6 centerText">Status ID</th>
                            <th class="col-3">Status</th>
                            <th class="col-3">Text class</th>
                            <th class="col-3">Icon element</th>
                            <th class="smallTD-6 centerText">Preview</th>
                            <th class="smallTD-6 centerText"></th> <!-- Placeholder vuilbakske -->
                        </tr>
                    </thead>
                    <tbody class="pointer">
                    <!-- Messages -->
                    <tr>
                        <td colspan="5" class="nomsg text-muted" *ngIf="!isLoading && lstStatus.length == 0">
                            No transaction statuses found.
                        </td>
                        <td colspan="5" *ngIf="isLoading">
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                    Loading transaction statuses
                                </div>
                            </div>
                        </td>
                    </tr>

                    <!-- Add status row -->
                    <tr id="lastTR" *ngIf="AddEnabled">
                        <!-- Form fields -->
                        <td class="smallTD-6 firstCell centerText">
                            <input name="newStatusId"
                                   class="form-control"
                                   [(ngModel)]="newStatus.Id"
                                   width="5"
                                   placeholder="Id"/>
                        </td>
                        <td class="col-3">
                            <input name="newStatusTransactionStatus"
                                   class="form-control"
                                   [(ngModel)]="newStatus.TransactionStatus"
                                   placeholder="Transaction status"/>
                        </td>
                        <td class="col-3">
                            <ng-select placeholder="Select class"
                                       name="newStatusText"
                                       [items]="lstText"
                                       [multiple]="false"
                                       [clearable]="false"
                                       bindValue="Text"
                                       [(ngModel)]="newStatus.Text">
                            </ng-select>
                        </td>
                        <td class="col-3">
                            <ng-select placeholder="Select element"
                                       name="newStatusIcon"
                                       [items]="lstIcon"
                                       [multiple]="false"
                                       [clearable]="false"
                                       bindValue="Icon"
                                       [(ngModel)]="newStatus.Icon">
                            </ng-select>
                        </td>
                        <!-- Icon preview -->
                        <td [ngSwitch]="newStatus.Icon" class="smallTD-6 centerText" [ngClass]="newStatus.Text">
                            <i-feather name="check-circle" *ngSwitchCase="'i-check-circle'"></i-feather>
                            <i-feather name="x-circle" *ngSwitchCase="'i-x-circle'"></i-feather>
                            <i-feather name="clock" *ngSwitchCase="'i-clock'"></i-feather>
                        </td>
                        <!-- Add button -->
                        <td class="smallTD-6 centerText">
                            <button  class="btn btn-outline-primary" type="submit" [disabled]="!validateNewStatus()" (click)="validateNewStatus() && addStatus()">
                                <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
                            </button>
                        </td>
                    </tr>

                    <!-- Data rows -->
                    <ng-container *ngIf="!isLoading">
                        <tr *ngFor="let theStatus of lstStatus" class="statustable" [class.active]="selStatus != null && theStatus.Id == selStatus.Id" (click)="rowClicked(theStatus)">
                            <td class="smallTD-6 firstCell centerText">{{theStatus.Id}}</td>
                            <td class="col-3">
                                <span *ngIf="selStatus == null || theStatus.Id != selStatus.Id">{{theStatus.TransactionStatus}}</span>
                                <input #inputTransactionStatus class="form-control" *ngIf="selStatus != null && theStatus.Id == selStatus.Id" [(ngModel)]="edtStatus.TransactionStatus"/>
                            </td>
                            <td class="col-3">
                                <span *ngIf="selStatus == null || theStatus.Id != selStatus.Id">{{theStatus.Text}}</span>
                                <ng-select *ngIf="selStatus != null && theStatus.Id == selStatus.Id"
                                           [items]="lstText"
                                           [multiple]="false"
                                           [clearable]="false"
                                           bindValue="Text"
                                           [(ngModel)]="edtStatus.Text">
                                </ng-select>
                            </td>
                            <td class="col-3">
                                <span *ngIf="selStatus == null || theStatus.Id != selStatus.Id">{{theStatus.Icon}}</span>
                                <ng-select *ngIf="selStatus != null && theStatus.Id == selStatus.Id"
                                           [items]="lstIcon"
                                           [multiple]="false"
                                           [clearable]="false"
                                           bindValue="Icon"
                                           [(ngModel)]="edtStatus.Icon">
                                </ng-select>
                            </td>
                            <td [ngSwitch]="theStatus.Icon" class="icon smallTD-6 centerText" [ngClass]="theStatus.Text">
                                <i-feather name="check-circle" *ngSwitchCase="'i-check-circle'"></i-feather>
                                <i-feather name="x-circle" *ngSwitchCase="'i-x-circle'"></i-feather>
                                <i-feather name="clock" *ngSwitchCase="'i-clock'"></i-feather>
                            </td>
                            <td class="smallTD-6 centerText">
                                <div class="form-group">
                                    <span (click)="removeStatus(theStatus)" class="hoverTrashIcon"><i-feather name="trash"></i-feather></span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
