import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { Location } from "@angular/common";
import { TnTAPIService, ConfigurationService } from "../../../../_services";
import { SnackbarService } from "ngx-snackbar";
import { ModalDirective } from "ngx-bootstrap";

import { Observable, forkJoin, pipe, of } from "rxjs";
import { catchError, throwIfEmpty } from "rxjs/operators";

import { OAuthService } from "angular-oauth2-oidc";
import { getgroups } from "process";

@Component({
    styleUrls: ["./configuration_alerting_conditions_detail.component.scss"],
    templateUrl: "./configuration_alerting_conditions_detail.component.html",
    selector: "app-tnt-configuration_alerting_conditions_detail",
})
export class ConfigurationAlertingConditionsDetailComponent implements AfterViewInit {
    @ViewChild("childModal", { static: true })
    public childModal: ModalDirective;
    public theCondition: TnTAlertingCondition;

    public lstGroups: Array<any> = [];

    public blnLoadingDetails: boolean = true;
    private blnLoadingDetailsParties: boolean = true;
    private blnLoadingDetailsMsgType: boolean = true;
    public blnLoadingGroups: boolean = true;

    public selGroup: TnTGroup = null;
    public selGroupId: number = -1;
    public deletedGroup: string = "";

    public lstOriginalParties: any = [];
    public lstSenders: any;
    public lstReceivers: any;
    public lstOriginalMessageTypes: any = [];
    public lstMessageTypes: any;

    public newGroupName: string = "";

    public event: string = "";
    public addedGroupName: string = "";
    public mvUserGroupId: number = null;
    public blnCanDelete: boolean = false;

    public allSelectedMessageTypes: boolean = false;
    public allSelectedSenders: boolean = false;
    public allSelectedReceivers: boolean = false;
    // Timer
    private timer;

    // Loader
    public isLoading: boolean;
    public addEndpointEnabled: boolean = false;
    public selectedRow: Number;
    public selection: string;

    // SELECTED ATTRIBUTES
    public selEndpointUrl: TnTAlertingSubscriptionEndpoint;
    public selEndpointUrlId: number = -1;

    public selEndpointAuthenticationType: TnTAlertingSubscriptionEndpoint;
    public selEndpointAuthenticationTypeId: number = -1;

    public selEndpointAuthenticationValue: TnTAlertingSubscriptionEndpoint;
    public selEndpointAuthenticationValueId: number = -1;

    public SETTINGS: TnTSettings;

    public lstSelections: Array<string> = [];
    public lstTypeAuthentications: Array<string> = [];
    public lstCurrentMessageTypes: any = [];
    public lstCurrentParties: any = [];

    constructor(
        private oauthService: OAuthService,
        private svcAPI: TnTAPIService,
        private svcRouter: Router,
        public svcConfig: ConfigurationService,
        public svcDomSanitization: DomSanitizer,
        private snackbarService: SnackbarService,
        private svcLocation: Location
    ) {
        this.svcConfig.SETTINGS.subscribe((res) => {
            if (res) {
                this.SETTINGS = res;
            }
        });
    }

    ngOnInit() {
        this.theCondition = <TnTAlertingCondition>{
            Id: -1,
            Name: "",
            Type: "",
            Description: "",
            Group: <TnTGroup>{
                Id: -1,
                Groupname: "",
            },
            MessageTypes: [],
            Senders: [],
            Receivers: [],
            StatusCode: "",
            NumberOfMessages: "",
        };
        this.getGroups();
    }

    private getGroups(): void {
        this.blnLoadingGroups = true;
        this.svcAPI.portalGetGroups().subscribe((groups) => {
            this.lstGroups = [];
            for (const group of groups) {
                const theGroup = <TnTGroup>group;
                this.lstGroups.push({
                    id: theGroup.Id,
                    text: theGroup.Groupname,
                });
            }
            this.blnLoadingGroups = false;
        });
    }

    public getMessageTypes(theGroup: TnTGroup) {
        this.svcAPI.portalGetGroupMessageTypes(theGroup.Id).subscribe((messagetypes) => {
            this.lstCurrentMessageTypes = messagetypes;
            this.lstOriginalMessageTypes = [];
            for (const messagetype of messagetypes) {
                const data = { MessageType: messagetype, Active: 0 };
                this.lstOriginalMessageTypes.push(data);
            }
            // Assignment keeps reference to the previous array.
            this.lstMessageTypes = this.lstOriginalMessageTypes.map((x) => Object.assign({}, x));

            // Retrieve active messagetypes
            this.blnLoadingDetailsMsgType = false;
            this.allSelectedMessageTypes = this.lstMessageTypes.length === this.theCondition.MessageTypes.length;
            this.lstMessageTypes.forEach((messagetype, idx) => {
                this.theCondition.MessageTypes.forEach((activeMessagetype) => {
                    if (messagetype.MessageType.Id === activeMessagetype.Id) {
                        this.lstMessageTypes[idx].Active = 1;
                    }
                });
            });

            if (!this.blnLoadingDetailsParties) {
                this.blnLoadingDetails = false;
            }
        });
    }

    public getMessageTypesEmpty(theGroupId: number) {
        this.svcAPI.portalGetGroupMessageTypes(theGroupId).subscribe((messagetypes) => {
            this.lstCurrentMessageTypes = messagetypes;
            this.lstOriginalMessageTypes = [];
            for (const messagetype of messagetypes) {
                const data = { MessageType: messagetype, Active: 0 };
                this.lstOriginalMessageTypes.push(data);
            }
            // Assignment keeps reference to the previous array.
            this.lstMessageTypes = this.lstOriginalMessageTypes.map((x) => Object.assign({}, x));

            if (!this.blnLoadingDetailsParties) {
                this.blnLoadingDetails = false;
            }
        });
    }

    public getParties(theGroup: TnTGroup) {
        this.svcAPI.portalGetGroupParties(theGroup.Id).subscribe((parties) => {
            this.lstCurrentParties = parties;
            this.lstOriginalParties = [];
            for (const party of parties) {
                const data = { Party: party, Active: 0 };
                this.lstOriginalParties.push(data);
            }

            // Assignment keeps reference to the previous array.
            this.lstSenders = this.lstOriginalParties.map((x) => Object.assign({}, x));
            this.lstReceivers = this.lstOriginalParties.map((x) => Object.assign({}, x));

            // Retrieve active parties
            this.blnLoadingDetailsMsgType = false;
            this.allSelectedSenders = this.lstSenders.length === this.theCondition.Senders.length;
            this.lstSenders.forEach((party, idx) => {
                this.theCondition.Senders.forEach((activeParties) => {
                    if (party.Party.Id === activeParties.Id) {
                        this.lstSenders[idx].Active = 1;
                    }
                });
            });
            this.allSelectedReceivers = this.lstReceivers.length === this.theCondition.Receivers.length;
            this.lstReceivers.forEach((party, idx) => {
                this.theCondition.Receivers.forEach((activeParties) => {
                    if (party.Party.Id === activeParties.Id) {
                        this.lstReceivers[idx].Active = 1;
                    }
                });
            });

            if (!this.blnLoadingDetailsMsgType) {
                this.blnLoadingDetails = false;
            }
        });
    }

    public getPartiesEmpty(theGroupId: number) {
        this.svcAPI.portalGetGroupParties(theGroupId).subscribe((parties) => {
            this.lstCurrentParties = parties;
            this.lstOriginalParties = [];
            for (const party of parties) {
                const data = { Party: party, Active: 0 };
                this.lstOriginalParties.push(data);
            }

            // Assignment keeps reference to the previous array.
            this.lstSenders = this.lstOriginalParties.map((x) => Object.assign({}, x));
            this.lstReceivers = this.lstOriginalParties.map((x) => Object.assign({}, x));

            if (!this.blnLoadingDetailsMsgType) {
                this.blnLoadingDetails = false;
            }
        });
    }

    public loadGroup(theGroup: any): void {
        this.deletedGroup = "";
        this.selGroup = { Id: theGroup.id, Groupname: theGroup.text };
        this.selGroupId = theGroup.id;
        this.getGroupDetails(this.selGroup);
    }

    public selectGroup(theGroup: any): void {
        if (this.theCondition.Group.Id != theGroup.id) {
            this.deletedGroup = "";
            this.selGroup = { Id: theGroup.id, Groupname: theGroup.text };
            this.selGroupId = theGroup.id;
            this.blnLoadingDetails = true;
            this.blnLoadingDetailsParties = true;
            this.blnLoadingDetailsMsgType = true;
            this.getMessageTypesEmpty(theGroup.id);
            this.getPartiesEmpty(theGroup.id);
        } else {
            this.loadGroup(theGroup);
        }
    }

    private async getGroupDetails(theGroup: TnTGroup) {
        this.blnLoadingDetails = true;
        this.blnLoadingDetailsParties = true;
        this.blnLoadingDetailsMsgType = true;
        this.getMessageTypes(theGroup);
        this.getParties(theGroup);
    }

    public showLoadingIcon: boolean = false;
    save() {
        this.showLoadingIcon = true;
        alert("This may take a while");
        const _this = this;
        console.log(this.theCondition);
        this.svcAPI.modifyAlertingCondition(this.theCondition).subscribe((res) => {
            if (res == "Success") {
                this.snackbarService.add({
                    msg: "Saved",
                    timeout: 2000,
                    customClass: "snackBarSucces",
                    action: {
                        text: "X",
                    },
                });
                this.showLoadingIcon = false;
            } else {
                this.snackbarService.add({
                    msg: res,
                    timeout: 2000,
                    customClass: "snackBarError",
                    action: {
                        text: "X",
                    },
                });
                this.showLoadingIcon = false;
            }
        });
    }

    ngAfterViewInit() {
        this.childModal.onHidden.subscribe(() => {
            // Set correct route parameter (permalinks)
            this.svcLocation.replaceState("/");
        });
    }

    showChildModal(condition: TnTAlertingCondition): void {
        // Set correct route parameter (permalinks)
        this.svcLocation.replaceState("/condition/" + condition.Id);

        this.theCondition = <TnTAlertingCondition>{
            Id: condition.Id,
            Name: condition.Name,
            Type: condition.Type,
            Description: condition.Description,
            Group: condition.Group,
            StatusCode: condition.StatusCode,
            NumberOfMessages: condition.NumberOfMessages,
        };

        this.svcAPI.getAlertingConditionMessageTypes(condition.Id).subscribe((conditionDetails) => {
            if (conditionDetails != null) {
                this.theCondition.MessageTypes = conditionDetails;
                this.allSelectedMessageTypes = this.lstMessageTypes.length === this.theCondition.MessageTypes.length;
                this.lstMessageTypes.forEach((messagetype, idx) => {
                    this.theCondition.MessageTypes.forEach((activeMessagetype) => {
                        if (messagetype.MessageType.Id === activeMessagetype.Id) {
                            this.lstMessageTypes[idx].Active = 1;
                        } else {
                            this.lstMessageTypes[idx].Active = 1;
                        }
                    });
                });
            }
        });

        this.svcAPI.getAlertingConditionSenderParties(condition.Id).subscribe((conditionDetails) => {
            if (conditionDetails != null) {
                console.log(conditionDetails);
                this.theCondition.Senders = conditionDetails;
                this.allSelectedSenders = this.lstSenders.length === this.theCondition.Senders.length;
                this.lstSenders.forEach((party, idx) => {
                    this.theCondition.Senders.forEach((activeParties) => {
                        if (party.Party.Id === activeParties.Id) {
                            this.lstSenders[idx].Active = 1;
                        } else {
                            this.lstSenders[idx].Active = 1;
                        }
                    });
                });
            }
        });

        this.svcAPI.getAlertingConditionReceiverParties(condition.Id).subscribe((conditionDetails) => {
            if (conditionDetails != null) {
                this.theCondition.Receivers = conditionDetails;
                this.allSelectedReceivers = this.lstReceivers.length === this.theCondition.Receivers.length;
                this.lstReceivers.forEach((party, idx) => {
                    this.theCondition.Receivers.forEach((activeParties) => {
                        if (party.Party.Id === activeParties.Id) {
                            this.lstReceivers[idx].Active = 1;
                        } else {
                            this.lstReceivers[idx].Active = 1;
                        }
                    });
                });
            }
        });

        // Load stuff
        this.isLoading = true;
        this.theCondition = <TnTAlertingCondition>{
            Id: condition.Id,
            Name: condition.Name,
            Type: condition.Type,
            Description: condition.Description,
            Group: condition.Group,
            MessageTypes: condition.MessageTypes,
            Senders: condition.Senders,
            Receivers: condition.Receivers,
            StatusCode: condition.StatusCode,
            NumberOfMessages: condition.NumberOfMessages,
        };
        if (this.theCondition.Group != null) {
            this.loadGroup({ id: this.theCondition.Group.Id, text: this.theCondition.Group.Groupname });
        }

        this.isLoading = false;
        // Show modal to user
        this.childModal.show();
    }

    hideChildModal(): void {
        // Hide modal
        this.childModal.hide();
    }

    getMaxLength(theArray: string[]): number {
        let theLength: number = 0;
        for (const el of theArray) {
            if (el.length > theLength) theLength = el.length;
        }
        return theLength;
    }

    resetClicked() {
        this.selEndpointUrl = null;
        this.selEndpointUrlId = -1;
        this.selEndpointAuthenticationType = null;
        this.selEndpointAuthenticationTypeId = -1;
        this.selEndpointAuthenticationValue = null;
        this.selEndpointAuthenticationValueId = -1;
    }

    btnToggleEndpointClicked() {
        this.addEndpointEnabled = !this.addEndpointEnabled;
    }

    messageTypeClicked(messagetype) {
        if (this.theCondition.Group.Id == this.selGroup.Id) {
            var index: number = this.lstMessageTypes.indexOf(messagetype);
            if (this.lstMessageTypes[index].Active == 0) {
                this.theCondition.MessageTypes.push(messagetype.MessageType);
                this.lstMessageTypes[index].Active = 1;
            } else {
                this.theCondition.MessageTypes.splice(this.theCondition.Receivers.indexOf(messagetype.MessageType), 1);
                this.lstMessageTypes[index].Active = 0;
            }
        }
    }

    senderClicked(party) {
        if (this.theCondition.Group.Id == this.selGroup.Id) {
            var index: number = this.lstSenders.indexOf(party);
            if (this.lstSenders[index].Active == 0) {
                this.theCondition.Senders.push(party.Party);
                this.lstSenders[index].Active = 1;
            } else {
                this.theCondition.Senders.splice(this.theCondition.Receivers.indexOf(party.Party), 1);
                this.lstSenders[index].Active = 0;
            }
        }
    }

    receiverClicked(party) {
        if (this.theCondition.Group.Id == this.selGroup.Id) {
            var index: number = this.lstReceivers.indexOf(party);
            if (this.lstReceivers[index].Active == 0) {
                this.theCondition.Receivers.push(party.Party);
                this.lstReceivers[index].Active = 1;
            } else {
                this.theCondition.Receivers.splice(this.theCondition.Receivers.indexOf(party.Party), 1);
                this.lstReceivers[index].Active = 0;
            }
        }
    }

    editGroup(group) {
        const _this = this;
        this.theCondition.Group = group;
        this.theCondition.Group.Id = group.id;
        this.theCondition.MessageTypes = [];
        this.theCondition.Senders = [];
        this.theCondition.Receivers = [];
    }

    clickSelectAll(type) {
        if (this.theCondition.Group.Id == this.selGroup.Id) {
            let text = "Are you sure?";
            if (confirm(text) == true) {
                if (type == "messagetypes") {
                    if (this.lstCurrentMessageTypes.length === this.theCondition.MessageTypes.length) {
                        this.theCondition.MessageTypes = [];
                        this.allSelectedMessageTypes =
                            this.lstCurrentMessageTypes.length === this.theCondition.MessageTypes.length;
                        this.lstMessageTypes.forEach((messagetype, idx) => {
                            this.lstMessageTypes[idx].Active = 0;
                        });
                    } else {
                        this.theCondition.MessageTypes = this.lstCurrentMessageTypes;
                        this.allSelectedMessageTypes =
                            this.lstCurrentMessageTypes.length === this.theCondition.MessageTypes.length;
                        this.lstMessageTypes.forEach((messagetype, idx) => {
                            this.lstMessageTypes[idx].Active = 1;
                        });
                    }
                }
                if (type == "senders") {
                    if (this.lstCurrentParties.length === this.theCondition.Senders.length) {
                        this.theCondition.Senders = [];
                        this.allSelectedSenders = this.lstCurrentParties.length === this.theCondition.Senders.length;
                        this.lstSenders.forEach((receiver, idx) => {
                            this.lstSenders[idx].Active = 0;
                        });
                    } else {
                        this.theCondition.Senders = this.lstCurrentParties;
                        this.allSelectedSenders = this.lstCurrentParties.length === this.theCondition.Senders.length;
                        this.lstSenders.forEach((receiver, idx) => {
                            this.lstSenders[idx].Active = 1;
                        });
                    }
                }
                if (type == "receivers") {
                    if (this.lstCurrentParties.length === this.theCondition.Receivers.length) {
                        this.theCondition.Receivers = [];
                        this.allSelectedReceivers =
                            this.lstCurrentParties.length === this.theCondition.Receivers.length;
                        this.lstReceivers.forEach((receiver, idx) => {
                            this.lstReceivers[idx].Active = 0;
                        });
                    } else {
                        this.theCondition.Receivers = this.lstCurrentParties;
                        this.allSelectedReceivers =
                            this.lstCurrentParties.length === this.theCondition.Receivers.length;
                        this.lstReceivers.forEach((receiver, idx) => {
                            this.lstReceivers[idx].Active = 1;
                        });
                    }
                }
            }
        } else {
            this.snackbarService.add({
                msg: "Can only choose for selected group",
                timeout: 2000,
                customClass: "snackBarError",
                action: {
                    text: "X",
                },
            });
        }
    }
}
