import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../_services/';
import { SnackbarService } from 'ngx-snackbar';

@Component({
    styleUrls: ['../configuration.component.scss', './configuration_status.component.scss'],
    templateUrl: './configuration_status.component.html',
})
export class ConfigurationStatusComponent implements OnInit {
    // Loader
    public isLoading: boolean;

    public lstStatus: Array<TnTTransactionStatus> = [];
    public selStatus: TnTTransactionStatus; // Pointer to selected element in lstStatus
    public edtStatus: TnTTransactionStatus; // Will contain edited values for selected status until another row is clicked, the changes are saved & this value is reset
    public newStatus: TnTTransactionStatus = <TnTTransactionStatus>{}; // Contains new status values

    public lstText: Array<string> = [];
    public lstIcon: Array<string> = [];

    // Add
    public AddEnabled: boolean = false;

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private snackbarService: SnackbarService) { }

    public ngOnInit() {
        this.refreshData();

        this.lstText.push('text-danger');
        this.lstText.push('text-warning');
        this.lstText.push('text-success');

        this.lstIcon.push('i-check-circle');
        this.lstIcon.push('i-clock');
        this.lstIcon.push('i-x-circle');
    }

    public refreshData(): void {
        // Retrieve list of statuses
        this.svcAPI.getTransactionStatusTypes().subscribe(
            status => {
                this.lstStatus = status;
            }
        );
    }

    public rowClicked(theStatus: TnTTransactionStatus) {
        if (this.selStatus !== theStatus) {
            this.saveChanges();
            this.selStatus = theStatus;
            this.edtStatus = Object.assign({}, theStatus); // Copy properties, not just pointer to heap
        }
    }

    private saveChanges() {
        // API call to save changes
        if (this.selStatus != null && this.edtStatus != null) {
            if (this.selStatus.TransactionStatus !== this.edtStatus.TransactionStatus || this.selStatus.Text !== this.edtStatus.Text || this.selStatus.Icon !== this.edtStatus.Icon) {
                this.selStatus = this.edtStatus;
                this.svcAPI.changeTransactionStatus(this.edtStatus).subscribe(
                    (res) => {

                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Saved',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });

                        this.refreshData();
                        this.selStatus = null;
                    },
                    (err) => {

                        const _this = this;
                        this.snackbarService.add({
                            msg: err.error,
                            timeout: 2000,
                            customClass: 'snackBarError',
                            action: {
                                text: 'X'
                            }
                        });

                    });
            }
        }
    }

    public removeStatus(theStatus: TnTTransactionStatus): void {
        this.svcAPI.deleteTransactionStatus(theStatus).subscribe(
            (res) => {
                const _this = this;
                this.snackbarService.add({
                    msg: 'Removed',
                    timeout: 2000,
                    customClass: 'snackBarSucces',
                    action: {
                        text: 'X'
                    }
                });
                this.refreshData();
            },
            (err) => {
                this.selStatus = theStatus;
                const _this = this;
                this.snackbarService.add({
                    msg: err.error,
                    timeout: 2000,
                    customClass: 'snackBarError',
                    action: {
                        text: 'X'
                    }
                });
            });
    }

    public addStatus(): void {
        this.svcAPI.addTransactionStatus(this.newStatus).subscribe(
            (res) => {
                const _this = this;
                this.snackbarService.add({
                    msg: 'Added',
                    timeout: 2000,
                    customClass: 'snackBarSucces',
                    action: {
                        text: 'X'
                    }
                });
                this.newStatus = <TnTTransactionStatus>{}; // Reset status
                this.refreshData();
            },
            (err) => {
                const _this = this;
                this.snackbarService.add({
                    msg: err.error,
                    timeout: 2000,
                    customClass: 'snackBarError',
                    action: {
                        text: 'X'
                    }
                });
            });
    }

    public validateNewStatus(): boolean {
        let valid: boolean = true;
        if (!this.isNumber(this.newStatus.Id) || this.newStatus.Id < 0 || this.newStatus.Id > 255) valid = false;
        if (!this.newStatus.TransactionStatus) valid = false;
        if (!this.newStatus.Icon) valid = false;
        if (!this.newStatus.Text) valid = false;

        return valid;
    }

    private isNumber(value: string | number): boolean {
        return ((value != null) && !isNaN(Number(value.toString())));
    }

    public btnAddEnabledClicked(): void {
        this.AddEnabled = !this.AddEnabled;
    }

}
