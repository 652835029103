import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { ROUTES } from './app.routes';

import { AppComponent } from './app.component';
import { HomeComponent } from './home';
import { DetailComponent } from './detail';
import { BusinessviewComponent } from './businessview';
import { HeaderComponent } from './header';
import { UserportalComponent, UserportalUsersComponent, UserportalGroupsComponent } from './userportal';
import { ConfigurationComponent, ConfigurationMessageTypesComponent, ConfigurationBusinessNamesComponent, ConfigurationPartiesComponent, ConfigurationStatusComponent, ConfigurationPurgingComponent, ConfigurationBusinessViewsComponent, ConfigurationFiltersComponent, ConfigurationSettingsComponent, ConfigurationReprocessingComponent, ConfigurationStorageAccountsComponent, ConfigurationAlertingComponent } from './configuration';
import { ConfigurationReprocessingDescriptionComponent, ConfigurationReprocessingTriggermechComponent } from './configuration/reprocessing';
import { LoginComponent } from './login';
import { NoContentComponent } from './no-content';
import { LogoutComponent } from './logout';

import { AuthGuard } from './_guards';
import { TnTAPIService } from './_services';
import { HideDeletedGroupPipe, HighlightPipe, CeilPipe, GroupIsActivePipe } from './_pipes';
import { IconsModule } from './_modules';
import { AuthInterceptor } from './_interceptors';

import { TabsModule, ButtonsModule, PaginationModule, ModalModule, TooltipModule, CollapseModule, PopoverModule, BsDropdownModule, BsDatepickerModule, TimepickerModule } from 'ngx-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgxMaskModule } from 'ngx-mask';

import { SnackbarModule } from 'ngx-snackbar';
import { ConfigurationAlertingActionsComponent, ConfigurationAlertingSchedulerComponent, ConfigurationAlertingConditionsComponent, ConfigurationAlertingSubscriptionsComponent } from './configuration/alerting';
import { ConfigurationAlertingSubscriptionsDetailComponent } from './configuration/alerting/subscriptions/detail';
import { ConfigurationAlertingConditionsDetailComponent } from './configuration/alerting/conditions/detail';
import { ConfigurationAlertingActionsDetailComponent } from './configuration/alerting/actions/detail';
import { ConfigurationAlertingSchedulerDetailComponent } from './configuration/alerting/scheduler/detail';
import * as moment from "moment-timezone";

moment.tz.setDefault("Europe/Brussels");
export function tokenGetter() {
    return localStorage.getItem('access_token');
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        HomeComponent,
        NoContentComponent,
        DetailComponent,
        BusinessviewComponent,
        HeaderComponent,
        UserportalComponent,
        UserportalUsersComponent,
        UserportalGroupsComponent,
        ConfigurationComponent,
        ConfigurationMessageTypesComponent,
        ConfigurationBusinessNamesComponent,
        ConfigurationBusinessViewsComponent,
        ConfigurationPurgingComponent,
        ConfigurationAlertingComponent,
        ConfigurationAlertingConditionsComponent,
        ConfigurationAlertingActionsComponent,
        ConfigurationAlertingSubscriptionsComponent,
        ConfigurationAlertingSubscriptionsDetailComponent,
        ConfigurationAlertingConditionsDetailComponent,
        ConfigurationAlertingActionsDetailComponent,
        ConfigurationAlertingSchedulerDetailComponent,
        ConfigurationAlertingSchedulerComponent,
        ConfigurationPartiesComponent,
        ConfigurationStatusComponent,
        ConfigurationFiltersComponent,
        ConfigurationReprocessingComponent,
        ConfigurationReprocessingDescriptionComponent,
        ConfigurationReprocessingTriggermechComponent,
        ConfigurationStorageAccountsComponent,
        ConfigurationSettingsComponent,
        LoginComponent,
        LogoutComponent,
        HideDeletedGroupPipe,
        HighlightPipe,
        CeilPipe,
        GroupIsActivePipe,
    ],
    imports: [ // import Angular's modules
        BrowserModule,
        BrowserAnimationsModule,
        IconsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES),
        TabsModule.forRoot(),
        ButtonsModule,
        PaginationModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        NgSelectModule,
        CollapseModule.forRoot(),
        PopoverModule.forRoot(),
        BsDropdownModule.forRoot(),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: ['./api'],
                sendAccessToken: true
            }
        }),
        NgxMaskModule.forRoot(),
        SnackbarModule.forRoot()
        ],
    providers: [ // expose our Services and Providers into Angular's dependency injection
        TnTAPIService,
        Title,
        AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ]
})
export class AppModule { }
