import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe, Location } from "@angular/common";
import { TnTAPIService, ConfigurationService } from "../../../../_services";
import { SnackbarService } from "ngx-snackbar";
import { ModalDirective } from "ngx-bootstrap";

import { Observable, forkJoin, pipe, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { OAuthService } from "angular-oauth2-oidc";
import { mapToMapExpression } from "@angular/compiler/src/render3/util";

@Component({
    styleUrls: ["./configuration_alerting_subscriptions_detail.component.scss"],
    templateUrl: "./configuration_alerting_subscriptions_detail.component.html",
    selector: "app-tnt-configuration_alerting_subscriptions_detail",
})
export class ConfigurationAlertingSubscriptionsDetailComponent implements AfterViewInit {
    @ViewChild("childModal", { static: true })
    public childModal: ModalDirective;
    public theSubscription: TnTAlertingSubscription;
    public showSettings: boolean;
    public detailedEndpoint: TnTAlertingSubscriptionEndpoint = <TnTAlertingSubscriptionEndpoint>{ Id: -1 };
    public name: string;
    public password: string;
    public headerName: string;
    public headerValue: string;

    // Timer
    private timer;

    // Loader
    public isLoading: boolean;
    public addEndpointEnabled: boolean = false;
    public addHeaderEnabled: boolean = false;
    public selectedRow: Number;

    // STRING INPUT
    public strNewHeaderKey: string = null;
    public strNewHeaderValue: string = null;
    public strNewEndpointName: string = null;
    public strNewEndpointUrl: string = null;
    public selNewNotificationMode: string = null;

    // SELECTED ATTRIBUTES
    public selHeaderKey: TnTAlertingSubscriptionEndpointHeader;
    public selHeaderKeyId: number = -1;

    public selHeaderValue: TnTAlertingSubscriptionEndpointHeader;
    public selHeaderValueId: number = -1;

    public selEndpointName: TnTAlertingSubscriptionEndpoint;
    public selEndpointNameId: number = -1;

    public selEndpointUrl: TnTAlertingSubscriptionEndpoint;
    public selEndpointUrlId: number = -1;

    public selNotificationMode: TnTAlertingSubscriptionEndpoint;
    public selNotificationModeId: number = -1;

    public SETTINGS: TnTSettings;

    public lstEndpoints: Array<TnTAlertingSubscriptionEndpoint> = [];
    public lstHeaders: Array<TnTAlertingSubscriptionEndpointHeader> = [];
    public lstTypes: Array<TnTAlertingSubscription> = [];
    public lstTypeNames: Array<string> = [];
    public lstNotificationModes: Array<string> = ["Single", "Cumulated"];

    constructor(
        private oauthService: OAuthService,
        private svcAPI: TnTAPIService,
        private svcRouter: Router,
        public svcConfig: ConfigurationService,
        public svcDomSanitization: DomSanitizer,
        private snackbarService: SnackbarService,
        private svcLocation: Location,
        public datepipe: DatePipe
    ) {
        this.svcConfig.SETTINGS.subscribe((res) => {
            if (res) {
                this.SETTINGS = res;
            }
        });
    }

    ngOnInit() {
        this.theSubscription = <TnTAlertingSubscription>{
            Id: -1,
            Name: "",
            Type: "",
            Description: "",
            Endpoints: [],
        };
        this.getTypes();
    }

    showDetail(theEndpoint: TnTAlertingSubscriptionEndpoint) {
        this.detailedEndpoint = theEndpoint;
        this.lstHeaders = this.detailedEndpoint.Headers;
        this.showSettings = true;
    }

    passwordTypeChange() {
        var x = document.getElementById("password");
        if ((<HTMLInputElement>x).type === "password") {
            (<HTMLInputElement>x).type = "text";
        } else {
            (<HTMLInputElement>x).type = "password";
        }
    }

    save() {
        const _this = this;
        if (typeof this.name === "undefined") {
            this.name = "";
        }
        if (typeof this.password === "undefined") {
            this.password = "";
        }
        this.svcAPI
            .SaveEndpoints(this.lstEndpoints)
            .pipe(
                catchError((error) => {
                    return of([]); // Return empty message set
                })
            )
            .subscribe((res) => {
                if (res["Message"] == "Success") {
                    const _this = this;
                    console.log(res);
                    this.snackbarService.add({
                        msg: "Saved",
                        timeout: 2000,
                        customClass: "snackBarSucces",
                        action: {
                            text: "X",
                        },
                    });
                    this.addEndpointEnabled = false;
                    this.lstEndpoints = res["Value"];
                    this.childModal.hide();
                    this.strNewEndpointUrl = null;
                    this.strNewEndpointName = null;
                    this.selNotificationMode = null;
                } else {
                    const _this = this;
                    this.snackbarService.add({
                        msg: res["Message"],
                        timeout: 2000,
                        customClass: "snackBarError",
                        action: {
                            text: "X",
                        },
                    });
                }
            });
    }

    getTypes() {
        this.svcAPI
            .getAlertingSubscriptions()
            .pipe(
                catchError((error) => {
                    return of([]); // Return empty message set
                })
            )
            .subscribe((types) => {
                this.lstTypes = types;
                types.forEach((type) => {
                    this.lstTypeNames.push(type.Type);
                });
            });
    }

    ngAfterViewInit() {
        this.childModal.onHidden.subscribe(() => {
            // Set correct route parameter (permalinks)
            this.svcLocation.replaceState("/");
        });
    }

    showChildModal(type: TnTAlertingSubscription): void {
        // Set correct route parameter (permalinks)
        this.svcLocation.replaceState("/type/" + type.Id);
        this.addEndpointEnabled = false;
        this.strNewEndpointName = null;
        this.strNewEndpointUrl = null;
        this.selNewNotificationMode = null;

        // Load stuff
        this.isLoading = true;
        this.theSubscription = <TnTAlertingSubscription>{
            Id: type.Id,
            Type: type.Type,
            Description: type.Description,
            Endpoints: type.Endpoints,
        };

        if (this.theSubscription.Type == "Webhook") {
            this.svcAPI
                .getAlertingWebhooks()
                .pipe(
                    catchError((error) => {
                        return of([]); // Return empty message set
                    })
                )
                .subscribe((endpoints) => {
                    this.lstEndpoints = endpoints;
                });
        }

        this.isLoading = false;
        // Show modal to user
        this.childModal.show();
    }

    hideChildModal(): void {
        // Hide modal
        this.lstHeaders = [];
        this.childModal.hide();
    }

    getMaxLength(theArray: string[]): number {
        let theLength: number = 0;
        for (const el of theArray) {
            if (el.length > theLength) theLength = el.length;
        }
        return theLength;
    }

    endpointsClicked(i: number, field: String, clicked?: TnTAlertingSubscriptionEndpoint) {
        this.selectedRow = i;
        if (field === "Name") {
            this.lstEndpoints.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selEndpointName = clicked;
                    this.selEndpointNameId = clicked.Id;
                    this.selEndpointUrl = null;
                    this.selEndpointUrlId = -1;
                    this.selNotificationMode = null;
                    this.selNotificationModeId = -1;
                }
            });
        }

        if (field === "Url") {
            this.lstEndpoints.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selEndpointName = null;
                    this.selEndpointNameId = -1;
                    this.selEndpointUrl = clicked;
                    this.selEndpointUrlId = clicked.Id;
                    this.selNotificationMode = null;
                    this.selNotificationModeId = -1;
                }
            });
        }

        if (field === "NotificationMode") {
            this.lstEndpoints.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selEndpointName = null;
                    this.selEndpointNameId = -1;
                    this.selEndpointUrl = null;
                    this.selEndpointUrlId = -1;
                    this.selNotificationMode = clicked;
                    this.selNotificationModeId = clicked.Id;
                }
            });
        }
    }
    headersClicked(i: number, field: String, clicked?: TnTAlertingSubscriptionEndpointHeader) {
        this.selectedRow = i;
        if (field === "Key") {
            this.lstHeaders.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selHeaderKey = clicked;
                    this.selHeaderKeyId = clicked.Id;
                    this.selHeaderValue = null;
                    this.selHeaderValueId = -1;
                }
            });
        }

        if (field === "Value") {
            this.lstHeaders.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selHeaderKey = null;
                    this.selHeaderKeyId = -1;
                    this.selHeaderValue = clicked;
                    this.selHeaderValueId = clicked.Id;
                }
            });
        }
    }

    resetClicked() {
        this.selEndpointUrl = null;
        this.selEndpointUrlId = -1;
    }

    btnToggleEndpointClicked() {
        this.addEndpointEnabled = !this.addEndpointEnabled;
    }
    
    btnToggleHeaderClicked() {
        this.addHeaderEnabled = !this.addHeaderEnabled;
    }

    btnAddEndpointClicked() {
        if (this.strNewEndpointUrl != null && this.strNewEndpointName != null && this.selNewNotificationMode != null) {
            var notDistinct = false;
            for (let index = 0; index < this.lstEndpoints.length; index++) {
                console.log(this.lstEndpoints[index].Name);
                if (this.strNewEndpointName == this.lstEndpoints[index].Name) {
                    notDistinct = true;
                    break;
                }
            }

            if (notDistinct) {
                this.snackbarService.add({
                    msg: "Name already exists",
                    timeout: 2000,
                    customClass: "snackBarError",
                    action: {
                        text: "X",
                    },
                });
            } else {
                if (
                    (this.theSubscription.Type == "Webhook" || this.validateEmail(this.strNewEndpointUrl)) &&
                    (this.theSubscription.Type == "Email" || this.validateWebhook(this.strNewEndpointUrl))
                ) {
                    let subscription: TnTAlertingSubscription;
                    if (this.theSubscription.Type == "Email")
                        subscription = this.lstTypes.find((t) => t.Type == "Email");
                    if (this.theSubscription.Type == "Webhook")
                        subscription = this.lstTypes.find((t) => t.Type == "Webhook");
                    const theEndpoint: TnTAlertingSubscriptionEndpoint = <TnTAlertingSubscriptionEndpoint>{
                        Name: this.strNewEndpointName ? this.strNewEndpointName.trim() : null,
                        Url: this.strNewEndpointUrl ? this.strNewEndpointUrl.trim() : null,
                        Subscription: subscription,
                        NotificationMode: this.selNewNotificationMode ? this.selNewNotificationMode.trim() : null,
                    };

                    // TO DO SEND TO BACKEND
                    this.addEndpointEnabled = false;
                    console.log(theEndpoint);
                    this.lstEndpoints.push(theEndpoint);

                    this.strNewEndpointName = null;
                    this.strNewEndpointUrl = null;
                } else {
                    const _this = this;
                    this.snackbarService.add({
                        msg: "Please fill in valid " + this.theSubscription.Type.toLowerCase(),
                        timeout: 2000,
                        customClass: "snackBarError",
                        action: {
                            text: "X",
                        },
                    });
                }
            }
        } else {
            const _this = this;
            this.snackbarService.add({
                msg: "Please fill in name and endpoint",
                timeout: 2000,
                customClass: "snackBarError",
                action: {
                    text: "X",
                },
            });
        }
    }

    btnAddHeaderClicked() {
        if (this.strNewHeaderKey != null && this.strNewHeaderValue != null) {
            var notDistinct = false;
            for (let index = 0; index < this.lstHeaders.length; index++) {
                console.log(this.lstHeaders[index].Key);
                if (this.strNewEndpointName == this.lstHeaders[index].Key) {
                    notDistinct = true;
                    break;
                }
            }

            if (notDistinct) {
                this.snackbarService.add({
                    msg: "Name already exists",
                    timeout: 2000,
                    customClass: "snackBarError",
                    action: {
                        text: "X",
                    },
                });
            } else {
                if (this.theSubscription.Type == "Webhook") {
                    const theHeader: TnTAlertingSubscriptionEndpointHeader = <TnTAlertingSubscriptionEndpointHeader>{
                        Key: this.strNewHeaderKey ? this.strNewHeaderKey.trim() : null,
                        Value: this.strNewHeaderValue ? this.strNewHeaderValue.trim() : null,
                    };

                    // TO DO SEND TO BACKEND
                    this.addHeaderEnabled = false;
                    console.log(theHeader);
                    const index = this.lstEndpoints.indexOf(this.detailedEndpoint);
                    this.lstEndpoints[index].Headers.push(theHeader);
                    this.detailedEndpoint.Headers = this.lstEndpoints[index].Headers;
                    this.lstHeaders = this.detailedEndpoint.Headers;

                    this.strNewHeaderKey = null;
                    this.strNewHeaderValue = null;
                }
            }
        } else {
            const _this = this;
            this.snackbarService.add({
                msg: "Please fill in all fields",
                timeout: 2000,
                customClass: "snackBarError",
                action: {
                    text: "X",
                },
            });
        }
    }

    removeEndpoint(theEndpoint: TnTAlertingSubscriptionEndpoint) {
        const _this = this;
        if (typeof theEndpoint.Id != "undefined") {
            this.svcAPI
                .DeleteAlertingSubscriptionEndpoints(theEndpoint)
                .pipe(
                    catchError((error) => {
                        return "Error";
                    })
                )
                .subscribe((res) => {
                    if (res == "Success") {
                        this.snackbarService.add({
                            msg: "Deleted",
                            timeout: 2000,
                            customClass: "snackBarSucces",
                            action: {
                                text: "X",
                            },
                        });
                        const index = this.lstEndpoints.indexOf(theEndpoint);
                        if (index > -1) {
                            this.lstEndpoints.splice(index, 1);
                        }
                    } else {
                        this.snackbarService.add({
                            msg: res,
                            timeout: 2000,
                            customClass: "snackBarError",
                            action: {
                                text: "X",
                            },
                        });
                    }
                });
        } else {
            const index = this.lstEndpoints.indexOf(theEndpoint);
            if (index > -1) {
                this.lstEndpoints.splice(index, 1);
            }
        }
    }

    removeHeader(theHeader: TnTAlertingSubscriptionEndpointHeader) {
        const _this = this;
        if (typeof theHeader.Id != "undefined") {
            this.svcAPI
                .DeleteAlertingSubscriptionEndpointHeader(theHeader)
                .pipe(
                    catchError((error) => {
                        return "Error";
                    })
                )
                .subscribe((res) => {
                    if (res == "Success") {
                        this.snackbarService.add({
                            msg: "Deleted",
                            timeout: 2000,
                            customClass: "snackBarSucces",
                            action: {
                                text: "X",
                            },
                        });
                        const indexEnpointHeaders = this.detailedEndpoint.Headers.indexOf(theHeader);
                        if (indexEnpointHeaders > -1) {
                            this.detailedEndpoint.Headers.splice(indexEnpointHeaders, 1);
                        }
                        this.lstHeaders = this.detailedEndpoint.Headers;
                    } else {
                        this.snackbarService.add({
                            msg: res,
                            timeout: 2000,
                            customClass: "snackBarError",
                            action: {
                                text: "X",
                            },
                        });
                    }
                });
        } else {
            const index = this.lstHeaders.indexOf(theHeader);
            if (index > -1) {
                this.lstHeaders.splice(index, 1);
            }
        }
    }

    validateEmail(email: string) {
        const regexp = new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        return regexp.test(email);
    }

    validateWebhook(webhook) {
        let url;

        try {
            url = new URL(webhook);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    addHeaders() {
        this.detailedEndpoint.Headers[this.headerName] = this.headerValue;
        this.headerName = "";
        this.headerValue = "";
    }
}
