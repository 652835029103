import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { Location } from "@angular/common";
import { TnTAPIService, ConfigurationService } from "../_services/";
import { SnackbarService } from "ngx-snackbar";
import { ModalDirective } from "ngx-bootstrap";

import { Observable, forkJoin, pipe, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { BusinessviewComponent } from "../businessview/";

import { OAuthService } from "angular-oauth2-oidc";

@Component({
    styleUrls: ["./detail.component.scss"],
    templateUrl: "./detail.component.html",
    selector: "app-tnt-detail",
})
export class DetailComponent implements AfterViewInit {
    @ViewChild("childModal", { static: true }) public childModal: ModalDirective;
    public theMessage: TnTMessage;
    public theReprocessMessage: TnTMessage;
    public theReprocessMessageStatus: string = "";
    public lstTransactions: TnTTransaction[];
    public lstTransactionsTimeLine: TnTTransaction[];
    public theProcess: TnTProcess;
    public isLoading: boolean = true;
    public lstProcessesIncludingMessages: TnTProcessWithMessages[];

    public usrToken: string;

    public downloadReferenceEnabled: boolean = false;
    public refVisibleAmount: number = 5;
    public refVisibleState = {};

    public SETTINGS: TnTSettings;

    // Businessview modal
    @ViewChild(BusinessviewComponent, { static: true }) businessviewModal: BusinessviewComponent;

    constructor(
        private oauthService: OAuthService,
        private svcAPI: TnTAPIService,
        private svcRouter: Router,
        public svcConfig: ConfigurationService,
        public svcDomSanitization: DomSanitizer,
        private snackbarService: SnackbarService,
        private svcLocation: Location
    ) {
        this.svcConfig.SETTINGS.subscribe((res) => {
            if (res) {
                this.SETTINGS = res;
            }
        });
    }

    ngAfterViewInit() {
        this.childModal.onHidden.subscribe(() => {
            // Set correct route parameter (permalinks)
            this.svcLocation.replaceState("/");
        });
    }

    public showChildModal(retrMessage: TnTMessage): void {
        // Set correct route parameter (permalinks)
        this.svcLocation.replaceState("/message/" + retrMessage.Id);

        // Load stuff
        this.isLoading = true;
        this.theMessage = retrMessage;
        this.refVisibleState = {}; // Reset visible references
        this.theReprocessMessageStatus = "";

        this.theMessage.References.forEach((theRef) => {
            this.refVisibleState[theRef.Key] = false;
        });

        forkJoin({
            transactions: this.svcAPI.getTransactions(this.theMessage.ProcessId).pipe(
                catchError((error) => {
                    return of([]); // Return empty message set
                })
            ),
            messages: this.svcAPI.getProcessInclParentsWithMessages(this.theMessage.ProcessId),
            process: this.svcAPI.getProcess(this.theMessage.ProcessId),
            transactionsTimeLine: this.svcAPI.getTransactionsTimeLine(this.theMessage.ProcessId).pipe(
                catchError((error) => {
                    return of([]); // Return empty message set
                })
            ),
        }).subscribe({
            next: (resp) => {
                this.lstTransactions = [];
                for (const key in resp.transactions) {
                    if (Object.prototype.hasOwnProperty.call(resp.transactions, key)) {
                        const transaction = resp.transactions[key];
                        if (!containsDangerousHtml(transaction.TransactionInfo)) {
                            this.lstTransactions.push(transaction);
                        }
                    }
                }
                this.lstTransactions = resp.transactions;
                this.lstProcessesIncludingMessages = resp.messages;
                this.theProcess = resp.process;

                this.usrToken = this.oauthService.getAccessToken();

                // Set download reference column flag
                this.downloadReferenceEnabled = false;

                this.lstProcessesIncludingMessages.forEach((prc) => {
                    prc["Messages"].forEach((msg) => {
                        if (msg.DownloadReference != null) this.downloadReferenceEnabled = true;
                    });
                });

                this.lstTransactionsTimeLine = resp.transactionsTimeLine;

                // Disable loading indicator
                this.isLoading = false;
            },
        });

        // Show modal to user
        this.childModal.show();
    }

    public eyeClicked(theMessage: TnTMessage) {
        this.businessviewModal.showChildModal(theMessage);
    }

    public hideChildModal(): void {
        // Hide modal
        this.childModal.hide();
    }

    public getMaxLength(theArray: string[]): number {
        let theLength: number = 0;
        for (const el of theArray) {
            if (el.length > theLength) theLength = el.length;
        }
        return theLength;
    }

    public downloadMessage(theMessage: TnTMessage): void {
        this.svcAPI.getMessageDownload(theMessage.Id).subscribe((res) => {
            // Content-Disposition: attachment; filename=...
            const fileName = res.headers
                .get("content-disposition")
                .split(";")[1]
                .trim()
                .split("=")[1]
                .replace(/"/g, "");

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(res.body, fileName);
                return;
            }

            const url = window.URL.createObjectURL(res.body);
            const a = document.createElement("a");

            document.body.appendChild(a);

            // Explicitely hide element
            a.setAttribute("style", "display: none");

            // Set-up filename & downloadlink (url = data as blob, works in FF, Chrome, Opera, whatever browser except IE ofc)
            a.href = url;
            a.download = fileName;

            // Dispatchevent instead of click, works in Firefox
            a.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }));

            // For Firefox it is necessary to delay revoking the ObjectURL
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                a.remove();
            }, 100);
        });
    }

    public btnDownloadClick(theMessage: TnTMessage) {
        if (theMessage.HasContent) this.downloadMessage(theMessage);
    }

    public btnShareUrl() {
        const selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = window.location.href;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
        this.snackbarService.add({
            msg: "URL to message copied to clipboard.",
            timeout: 2000,
            customClass: "snackBarSucces",
            action: {
                text: "X",
            },
        });
    }

    public reprocessClicked(theMessage: TnTMessage) {
        //
        //this.theReprocessMessage = theMessage;
        //this.theReprocessMessageStatus = "loading";

        theMessage.ReprocessTriggerStatus = "loading";

        var theReprocessQueue: TnTReprocessQueue = {
            MessageId: theMessage.Id,
            //default meegeven, user wordt ingevuld in controller
            Triggered_by: -1,
        };

        this.svcAPI.addReprocessMessage(theReprocessQueue).subscribe((result) => {
            if (result === "Succes") {
                theMessage.ReprocessTriggerStatus = "queued";
            } else {
                theMessage.ReprocessTriggerStatus = "fail";
            }
        });
    }
}

function containsDangerousHtml(str) {
    const regex = /<script\b[^>]*>|<input\b[^>]*>|<button\b[^>]*>|<textarea\b[^>]*>|<select\b[^>]*>|<form\b[^>]*>/i;
    return regex.test(str);
}
