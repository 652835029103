<div class="nav-scroller bg-white shadow-sm">
    <nav class="nav nav-underline">
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/messageTypes">Message Types</a>
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/parties">Parties</a>
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/businessNames">Business Names</a>
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/businessViews">Business Views</a>
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/status">Transaction Statuses</a>
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/filters">Filters</a>
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" routerLink="/config/reprocessing">Reprocessing</a>
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/storageaccounts">Storage Accounts</a>
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/purging">Retention</a>
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" routerLink="/config/alerting">Alerting</a>
        <!--<a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/settings">Settings</a>-->
    </nav>
</div>

<router-outlet></router-outlet>