<div class="col-md-12 paddingTop" style="text-align: right; padding-bottom: 5px">
    <button class="btn btn-primary addButtonTypeDetail" type="button" (click)="btnToggleConditionClicked()">
        <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
    </button>
</div>
<div class="col-12 paddingBottomTable">
    <div class="card">
        <div class="table-responsive" style="overflow: inherit">
            <table class="table table-hover p-3 mb-5 tableLikeCard">
                <thead class="text-primary-inverse">
                    <tr>
                        <th class="col-2">Name</th>
                        <th class="col-1">Type</th>
                        <th class="col-3">Description</th>
                        <th class="col-2">Status code</th>
                        <th class="col-2">Number of Messages</th>
                        <th class="col-1 centerText">Settings</th>
                        <th class="col-1"></th>
                    </tr>
                </thead>
                <tbody class="pointer">
                    <tr>
                        <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstAlertingConditions.length == 0">
                            No conditions found.
                        </td>
                        <td colspan="12" *ngIf="isLoading">
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                    Loading conditions
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr id="lastTR" *ngIf="addConditionEnabled">
                        <td class="col-2">
                            <input class="form-control" [(ngModel)]="strNewConditionName" placeholder="Name" />
                        </td>
                        <td class="col-1">
                            <ng-select placeholder="Select type" [items]="lstTypes" [multiple]="false" [clearable]="true" bindLabel="Type" [(ngModel)]="selNewConditionType"></ng-select>
                        </td>
                        <td class="col-3">
                            <input class="form-control" [(ngModel)]="strNewConditionDescription" placeholder="Description" />
                        </td>
                        <td class="col-2">
                            <input [disabled]="selNewConditionType!='Status'" class="form-control" [(ngModel)]="strNewConditionStatusCode" placeholder="255" />
                        </td>
                        <td class="col-2">
                            <input [disabled]="selNewConditionType!='Event'" class="form-control" [(ngModel)]="strNewConditionNumberOfMessages" placeholder="0" />
                        </td>
                        <td class="col-1">
                        </td>
                        <td class="smallTD-6 centerText">
                            <span (click)="btnAddConditionClicked()" class="hoverTrashIcon">
                                <i-feather name="plus-circle" *ngIf="!showLoadingIcon"></i-feather>
                                <i-feather class="loader" *ngIf="showLoadingIcon"></i-feather>
                            </span>
                        </td>
                    </tr>

                    <!-- ROW EDIT -->
                    <tr *ngFor="let theCondition of lstAlertingConditions; let i = index" [class.active]="i == selectedRow">
                        <ng-container *ngIf="!isLoading">
                            <!--Name-->
                            <td class="col-2" (click)="conditionClicked(i, 'Name', theCondition)">
                                <span *ngIf="selNameId != theCondition.Id">{{ theCondition.Name }}</span>
                                <input class="form-control" *ngIf="selNameId == theCondition.Id" [(ngModel)]="theCondition.Name" placeholder="Name" (input)="conditionChanged(theCondition)" />
                            </td>
                            <!--Type-->
                            <td class="col-1" (click)="conditionClicked(i, 'Type', theCondition)">
                                <span *ngIf="selTypeId != theCondition.Id">{{ theCondition.Type }}</span>
                                <ng-select placeholder="Select type" [items]="lstTypes" [multiple]="false" [clearable]="true" bindLabel="Type" [(ngModel)]="theCondition.Type" *ngIf="selTypeId == theCondition.Id" (change)="conditionChanged(theCondition)"></ng-select>
                            </td>
                            <!--Description-->
                            <td class="col-3" (click)="conditionClicked(i, 'Description', theCondition)">
                                <span *ngIf="selDescriptionId != theCondition.Id">{{ theCondition.Description }}</span>
                                <input class="form-control" *ngIf="selDescriptionId == theCondition.Id" placeholder="Description" [(ngModel)]="theCondition.Description" (input)="conditionChanged(theCondition)" />
                            </td>
                            <!--StatusCode-->
                            <td class="col-2" (click)="conditionClicked(i, 'StatusCode', theCondition)">
                                <span *ngIf="selStatusCodeId != theCondition.Id && theCondition.Type == 'Status'">{{ theCondition.StatusCode }}</span>
                                <input class="form-control" placeholder="0-255;10;..." *ngIf="selStatusCodeId == theCondition.Id && theCondition.Type == 'Status'" [(ngModel)]="theCondition.StatusCode" (input)="conditionChanged(theCondition)" />
                            </td>
                            <!--NumberOfMessages-->
                            <td class="col-2" (click)="conditionClicked(i, 'NumberOfMessages', theCondition)">
                                <span *ngIf="selNumberOfMessagesId != theCondition.Id && theCondition.Type == 'Event'">{{ theCondition.NumberOfMessages }}</span>
                                <input class="form-control" placeholder="<50;>10;..." *ngIf="selNumberOfMessagesId == theCondition.Id && theCondition.Type == 'Event'" [(ngModel)]="theCondition.NumberOfMessages" (input)="conditionChanged(theCondition)" />
                            </td>
                            <td class="smallTD-6 centerText">
                                <span (click)="openDetail(theCondition)" class="hoverEditIcon"><i-feather name="settings"></i-feather></span>
                            </td>
                            <td class="smallTD-6 centerText">
                                <span (click)="removeCondition(theCondition)" class="hoverTrashIcon">
                                    <i-feather name="trash"></i-feather>
                                </span>
                            </td>
                        </ng-container>
                    </tr>

                    <!-- POPUP -->
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-tnt-configuration_alerting_conditions_detail></app-tnt-configuration_alerting_conditions_detail>