<!-- Header -->
<div class="container-fluid paddingTop">
    <h1 class="display-4 display">Settings</h1>
</div>

<!-- Content -->
<div class="row paddingTable container-fluid">
    <div class="col-md-12 paddingBottomTable">
        <div class="card">
            <div class="table-responsive" style="overflow: inherit">
                <table class="table table-hover p-3 mb-5 tableLikeCard" style="overflow: inherit">
                    <thead class="text-primary-inverse">
                        <tr>
                            <th>ID</th>
                            <th>Logo</th>
                            <th>Primary Colour</th>
                            <th>DateFormat</th>
                            <th>PageTitle</th>
                            <th>PageSubTitle</th>
                            <th>DownloadListOrder</th>
                            <th>RowHighlight</th>
                            <th>UserId</th>
                            <th class="smallTD"></th>
                        </tr>
                    </thead>
                    <tbody class="pointer">
                    <!-- Messages -->
                    <tr>
                        <td colspan="5" class="nomsg text-muted" *ngIf="!isLoading && lstSetting.length == 0">
                            No settings found.
                        </td>
                        <td colspan="5" *ngIf="isLoading">
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                    Loading settings
                                </div>
                            </div>
                        </td>
                    </tr>

                    <!-- Add settings row -->
                    <tr id="addRow">
                        <!-- Form fields -->
                        <td class="smallTD"></td>
                        <td class="smallTD">
                            <input name="newSettingLogo"
                                   class="form-control"
                                   [(ngModel)]="newSetting.Logo"
                                   width="5" />
                        </td>
                        <td class="smallTD">
                            <input name="newSettingPrimaryColour"
                                   class="form-control"
                                   [(ngModel)]="newSetting.PrimaryColour"
                                   width="5" />
                        </td>
                        <td class="smallTD">
                            <input name="newSettingDateFormat"
                                   class="form-control"
                                   [(ngModel)]="newSetting.DateFormat"
                                   width="5" />
                        </td>
                        <td class="smallTD">
                            <input name="newSettingPageTitle"
                                   class="form-control"
                                   [(ngModel)]="newSetting.PageTitle"
                                   width="5" />
                        </td>
                        <td class="smallTD">
                            <input name="newSettingPageSubtitle"
                                   class="form-control"
                                   [(ngModel)]="newSetting.PageSubtitle"
                                   width="5" />
                        </td>
                        <td>
                            <ng-select name="newSettingDownloadListOrder"
                                       [items]="lstOrder"
                                       [multiple]="false"
                                       [clearable]="false"
                                       bindValue="Order"
                                       [(ngModel)]="newSetting.DownloadListOrder"
                                      >
                            </ng-select>
                        </td>
                        <td>
                            <ng-select name="newSettingRowHighlight"
                                       [items]="lstRowHighlight"
                                       [multiple]="false"
                                       [clearable]="false"
                                       bindValue="RowHighLight"
                                       [(ngModel)]="newSetting.RowHighlight">
                            </ng-select>
                        </td>
                        <td class="smallTD">
                            <input name="newSettingUserId"
                                   class="form-control"
                                   [(ngModel)]="newSetting.UserId"
                                   width="5" />
                        </td>
                        <td class="smallTD">
                            <button class="btn btn-outline-primary" type="submit" [disabled]="!validateNewSetting()" (click)="validateNewSetting() && addSetting()">
                                <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
                            </button>
                        </td>
                    </tr>

                    <!-- Data rows -->
                    <ng-container *ngIf="!isLoading">
                        <tr *ngFor="let theSetting of lstSetting" class="settingstable" [class.active]="selSetting != null && theSetting.Id == selSetting.Id" (click)="rowClicked(theSetting)">
                            <td>{{theSetting.Id}}</td>
                            <td>
                                <span *ngIf="selSetting == null || theSetting.Id != selSetting.Id">{{theSetting.Logo}}</span>
                                <input #inputSetting class="form-control" *ngIf="selSetting != null && theSetting.Id == selSetting.Id" [(ngModel)]="edtSetting.Logo" />
                                <p class="small errorStatus" *ngIf="selSetting != null && selSetting.Id == theSetting.Id && strError != ''">{{strError}}</p>
                            </td>
                            <td>

                                <span *ngIf="selSetting == null || theSetting.Id != selSetting.Id">{{theSetting.PrimaryColour}}</span>
                                <input #inputSetting class="form-control" *ngIf="selSetting != null && theSetting.Id == selSetting.Id" [(ngModel)]="edtSetting.PrimaryColour" />
                                <p class="small errorStatus" *ngIf="selSetting != null && selSetting.Id == theSetting.Id && strError != ''">{{strError}}</p>
                            </td>
                            <td>

                                <span *ngIf="selSetting == null || theSetting.Id != selSetting.Id">{{theSetting.DateFormat}}</span>
                                <input #inputSetting class="form-control" *ngIf="selSetting != null && theSetting.Id == selSetting.Id" [(ngModel)]="edtSetting.DateFormat" />
                                <p class="small errorStatus" *ngIf="selSetting != null && selSetting.Id == theSetting.Id && strError != ''">{{strError}}</p>
                            </td>
                            <td>

                                <span *ngIf="selSetting == null || theSetting.Id != selSetting.Id">{{theSetting.PageTitle}}</span>
                                <input #inputSetting class="form-control" *ngIf="selSetting != null && theSetting.Id == selSetting.Id" [(ngModel)]="edtSetting.PageTitle" />
                                <p class="small errorStatus" *ngIf="selSetting != null && selSetting.Id == theSetting.Id && strError != ''">{{strError}}</p>
                            </td>
                            <td>

                                <span *ngIf="selSetting == null || theSetting.Id != selSetting.Id">{{theSetting.PageSubtitle}}</span>
                                <input #inputSetting class="form-control" *ngIf="selSetting != null && theSetting.Id == selSetting.Id" [(ngModel)]="edtSetting.PageSubtitle" />
                                <p class="small errorStatus" *ngIf="selSetting != null && selSetting.Id == theSetting.Id && strError != ''">{{strError}}</p>
                            </td>
                            <td>
                                <span *ngIf="selSetting == null || theSetting.Id != selSetting.Id">{{theSetting.DownloadListOrder}}</span>
                                <ng-select *ngIf="selSetting != null && theSetting.Id == selSetting.Id"
                                           [items]="lstOrder"
                                           [multiple]="false"
                                           [clearable]="false"
                                           bindValue="Order"
                                           [(ngModel)]="edtSetting.DownloadListOrder">
                                </ng-select>
                            </td>
                            <td>
                                <span *ngIf="selSetting == null || theSetting.Id != selSetting.Id">{{theSetting.RowHighlightEnabled}}</span>
                                <ng-select *ngIf="selSetting != null && theSetting.Id == selSetting.Id"
                                           [items]="lstRowHighlight"
                                           [multiple]="false"
                                           [clearable]="false"
                                           bindValue="RowHighLight"
                                           [(ngModel)]="edtSetting.RowHighlight">
                                </ng-select>
                            </td>
                            <td>
                                
                                <span *ngIf="selSetting == null || theSetting.Id != selSetting.Id">{{theSetting.UserId}}</span>
                                <input #inputSetting class="form-control" *ngIf="selSetting != null && theSetting.Id == selSetting.Id" [(ngModel)]="edtSetting.UserId" />
                                <p class="small errorStatus" *ngIf="selSetting != null && selSetting.Id == theSetting.Id && strError != ''">{{strError}}</p>
                            </td>
                            <td class="smallTD">
                                <div class="form-group">
                                    <span (click)="removeSetting(theSetting)" class="hoverTrashIcon"><i-feather name="trash"></i-feather></span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
