<!-- Header -->
<div class="container-fluid paddingTop">
    <h1 class="display-4 display">Reprocessing</h1>
</div>
<div class="row paddingUPContent">
    <div class="col-2">
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/reprocessing/description">Description</a>
            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/config/reprocessing/triggermech">Trigger mechanism</a>
        </div>
    </div>
    <div class="col-10">
        <router-outlet></router-outlet>
    </div>
</div>