<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="detail-header" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title overflow">
                    <div class="c-detail-wrapper">
                        <h4 id="detail-header" *ngIf="theSubscription">
                            Type
                        </h4>
                    </div>
                </div>
                <div class="pull-right">
                    <button type="button" class="close" aria-label="Close" (click)="hideChildModal()">
                        <i-feather name="x" class="modaltitlebutton"></i-feather>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <div class="detail-content" *ngIf="!isLoading">
                    <div class="container-fluid">
                        <!--<div class="row">
                            <h6 class="col-3">Description</h6>
                            <div class="col-9">
                                <input #inputNewTypeDescription class="form-control" [(ngModel)]="strNewTypeDescription" placeholder="Description" [(ngModel)]="theSubscription.Description" />
                            </div>
                        </div> -->
                        <div class="row">
                            <h6 class="col-11">{{ theSubscription.Type }}</h6>
                            <div class="col-1">
                                <button class="btn btn-primary float-right addButtonTypeDetail" type="button" (click)="btnToggleEndpointClicked()">
                                    <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
                                </button>
                            </div>
                        </div>
                        <div class="col-12 paddingBottomTable" *ngIf="theSubscription.Type === 'Webhook'">
                            <div class="card">
                                <div class="table-responsive" style="overflow: inherit">
                                    <table class=" table table-hover p-3 mb-5 tableLikeCard ">
                                        <thead class="text-primary-inverse">
                                            <tr>
                                                <th class="col-2">Name</th>
                                                <th class="col-3">URL</th>
                                                <th class="col-2">Notification mode</th>
                                                <th class="col-2">Last changed on</th>
                                                <th class="col-2 centerText">Settings</th>
                                                <th class="col-1"></th>
                                            </tr>
                                        </thead>
                                        <tbody class="pointer">
                                            <tr>
                                                <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstEndpoints.length == 0">
                                                    No subscriptions found.
                                                </td>
                                                <td colspan="12" *ngIf="isLoading">
                                                    <div class="progress">
                                                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                                            Loading subscriptions
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr id="lastTR" *ngIf="addEndpointEnabled">
                                                <td class="col-2">
                                                    <input #inputNewEndpointName class="form-control" [(ngModel)]="strNewEndpointName" placeholder="Name" />
                                                </td>
                                                <td class="col-3 ellipsis">
                                                    <input #inputNewEndpointUrl class="form-control" [(ngModel)]="strNewEndpointUrl" placeholder="Url" />
                                                </td>
                                                <td class="col-2">
                                                    <ng-select placeholder="Select mode" [items]="lstNotificationModes" [multiple]="false" [clearable]="true" bindLabel="NotificationMode" [(ngModel)]="selNewNotificationMode">
                                                    </ng-select>
                                                </td>
                                                <td class="col-2">

                                                </td>
                                                <td class="col-2">
                                                </td>
                                                <td class="smallTD-6 centerText">
                                                    <span (click)="btnAddEndpointClicked()" class="hoverTrashIcon">
                                                        <i-feather name="plus-circle"></i-feather>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr *ngFor="let theEndpoint of lstEndpoints; let i = index" [class.active]="i == selectedRow" (click)="endpointsClicked(theEndpoint)">
                                                <ng-container *ngIf="!isLoading">
                                                    <!--Name-->
                                                    <td class="col-2" (click)="endpointsClicked(i,'Name',theEndpoint)">
                                                        <div class="form-group">
                                                            <span *ngIf="selEndpointNameId != theEndpoint.Id">{{theEndpoint.Name}}</span>
                                                            <input class="form-control" *ngIf="selEndpointNameId == theEndpoint.Id" [(ngModel)]="theEndpoint.Name" />
                                                        </div>
                                                    </td>

                                                    <!--Url-->
                                                    <td class="col-3 ellipsis" (click)="endpointsClicked(i,'Url',theEndpoint)">
                                                        <div class="form-group">
                                                            <span *ngIf="selEndpointUrlId != theEndpoint.Id">{{theEndpoint.Url}}</span>
                                                            <input class="form-control" *ngIf="selEndpointUrlId == theEndpoint.Id" [(ngModel)]="theEndpoint.Url" />
                                                        </div>
                                                    </td>

                                                    <td class="col-2" (click)="endpointsClicked(i,'NotificationMode',theEndpoint)">
                                                        <span *ngIf="selNotificationModeId != theEndpoint.Id">{{theEndpoint.NotificationMode}}</span>
                                                        <ng-select *ngIf="selNotificationModeId == theEndpoint.Id" [items]="lstNotificationModes" [multiple]="false" [clearable]="false" [(ngModel)]="theEndpoint.NotificationMode"></ng-select>
                                                    </td>

                                                    <td class="col-2">
                                                        <span>{{this.datepipe.transform(theEndpoint.LastChangedOn, 'dd/MM/yyyy - HH:mm:ss')}}</span>
                                                    </td>

                                                    <td class="col-1 centerText">
                                                        <span (click)="showDetail(theEndpoint)" class="hoverEditIcon"><i-feather name="settings"></i-feather></span>
                                                    </td>

                                                    <!--Delete-->
                                                    <td class="smallTD-6 centerText">
                                                        <span (click)="removeEndpoint(theEndpoint)" class="hoverTrashIcon">
                                                            <i-feather name="trash"></i-feather>
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <h6 class="col-11">Header for {{ detailedEndpoint.Name }}</h6>
                            <div class="col-1">
                                <button class="btn btn-primary float-right addButtonTypeDetail" type="button" (click)="btnToggleHeaderClicked()">
                                    <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
                                </button>
                            </div>
                        </div>
                        <div class="col-12 paddingBottomTable" *ngIf="theSubscription.Type === 'Webhook'">
                            <div class="card">
                                <div class="table-responsive" style="overflow: inherit">
                                    <table class=" table table-hover p-3 mb-5 tableLikeCard ">
                                        <thead class="text-primary-inverse">
                                            <tr>
                                                <th class="col-5">Key</th>
                                                <th class="col-6">Value</th>
                                                <th class="col-1"></th>
                                            </tr>
                                        </thead>
                                        <tbody class="pointer">
                                            <tr>
                                                <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstHeaders.length == 0">
                                                    Please select a webhook first.
                                                </td>
                                                <td colspan="12" *ngIf="isLoading">
                                                    <div class="progress">
                                                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                                            Loading headers
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr id="lastTR" *ngIf="addHeaderEnabled">
                                                <td class="col-5">
                                                    <input #inputNewHeaderKey class="form-control" [(ngModel)]="strNewHeaderKey" placeholder="Key" />
                                                </td>
                                                <td class="col-6 ellipsis">
                                                    <input #inputNewHeaderValue class="form-control" [(ngModel)]="strNewHeaderValue" placeholder="Value" />
                                                </td>
                                                <td class="smallTD-6 centerText">
                                                    <span (click)="btnAddHeaderClicked()" class="hoverTrashIcon">
                                                        <i-feather name="plus-circle"></i-feather>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr *ngFor="let theHeader of lstHeaders; let i = index" [class.active]="i == selectedRow" (click)="headersClicked(theHeader)">
                                                <ng-container *ngIf="!isLoading">
                                                    <!--Key-->
                                                    <td class="col-5" (click)="headersClicked(i,'Key',theHeader)">
                                                        <div class="form-group">
                                                            <span *ngIf="selHeaderKeyId != theHeader.Id">{{theHeader.Key}}</span>
                                                            <input class="form-control" *ngIf="selHeaderKeyId == theHeader.Id" [(ngModel)]="theHeader.Key" />
                                                        </div>
                                                    </td>

                                                    <!--Value-->
                                                    <td class="col-6 ellipsis" (click)="headersClicked(i,'Value',theHeader)">
                                                        <div class="form-group">
                                                            <span *ngIf="selHeaderValueId != theHeader.Id">{{theHeader.Value}}</span>
                                                            <input class="form-control" *ngIf="selHeaderValueId == theHeader.Id" [(ngModel)]="theHeader.Value" />
                                                        </div>
                                                    </td>
                                                    <!--Delete-->
                                                    <td class="smallTD-6 centerText">
                                                        <span (click)="removeHeader(theHeader)" class="hoverTrashIcon">
                                                            <i-feather name="trash"></i-feather>
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div class="container-fluid" *ngIf="isLoading">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                            Loading
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row col-12">
                        <button class="btn btn-secondary col-5" (click)="hideChildModal()">
                            Close
                        </button>
                        <span class="col-2"></span>
                        <button class="btn btn-primary col-5" (click)="save()">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>