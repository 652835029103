import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home';
import { UserportalComponent, UserportalUsersComponent, UserportalGroupsComponent } from './userportal';
import { ConfigurationComponent, ConfigurationSettingsComponent, ConfigurationBusinessNamesComponent, ConfigurationPurgingComponent, ConfigurationPartiesComponent, ConfigurationMessageTypesComponent, ConfigurationStatusComponent, ConfigurationBusinessViewsComponent, ConfigurationFiltersComponent, ConfigurationReprocessingComponent, ConfigurationStorageAccountsComponent, ConfigurationAlertingComponent } from './configuration';
import { ConfigurationReprocessingDescriptionComponent, ConfigurationReprocessingTriggermechComponent } from './configuration/reprocessing';
import { LoginComponent } from './login';
import { NoContentComponent } from './no-content';
import { AuthGuard } from './_guards';
import { LogoutComponent } from './logout';
import { ConfigurationAlertingActionsComponent, ConfigurationAlertingSchedulerComponent, ConfigurationAlertingConditionsComponent, ConfigurationAlertingSubscriptionsComponent } from './configuration/alerting';

export const ROUTES: Routes = [
    // Default route
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'message/:id', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    // User portal
    { path: 'portal', component: UserportalComponent,
        children: [
            { path: '', redirectTo: 'groups', pathMatch: 'full' },
            { path: 'users', component: UserportalUsersComponent, canActivate: [AuthGuard]},
            { path: 'groups', component: UserportalGroupsComponent, canActivate: [AuthGuard] }
        ]
    },
    {
        path: 'config', component: ConfigurationComponent,
        children: [
            { path: '', redirectTo: 'messageTypes', pathMatch: 'full' },
            { path: 'messageTypes', component: ConfigurationMessageTypesComponent, canActivate: [AuthGuard] },
            { path: 'parties', component: ConfigurationPartiesComponent, canActivate: [AuthGuard] },
            { path: 'businessNames', component: ConfigurationBusinessNamesComponent, canActivate: [AuthGuard] },
            { path: 'businessViews', component: ConfigurationBusinessViewsComponent, canActivate: [AuthGuard] },
            { path: 'status', component: ConfigurationStatusComponent, canActivate: [AuthGuard] },
            { path: 'filters', component: ConfigurationFiltersComponent, canActivate: [AuthGuard] },
            { path: 'storageaccounts', component: ConfigurationStorageAccountsComponent, canActivate: [AuthGuard] },
            {
                path: 'reprocessing', component: ConfigurationReprocessingComponent, canActivate: [AuthGuard],
                children: [
                    { path: '', redirectTo: 'triggermech', pathMatch: 'full' },
                    { path: 'triggermech', component: ConfigurationReprocessingTriggermechComponent, canActivate: [AuthGuard] },
                    { path: 'description', component: ConfigurationReprocessingDescriptionComponent, canActivate: [AuthGuard] }
                ]
            },
            {
                path: 'alerting', component: ConfigurationAlertingComponent, canActivate: [AuthGuard],
                children: [
                    { path: '', redirectTo: 'subscriptions', pathMatch: 'full' },
                    { path: 'scheduler', component: ConfigurationAlertingSchedulerComponent, canActivate: [AuthGuard] },
                    { path: 'subscriptions', component: ConfigurationAlertingSubscriptionsComponent, canActivate: [AuthGuard] },
                    { path: 'conditions', component: ConfigurationAlertingConditionsComponent, canActivate: [AuthGuard] },
                    { path: 'actions', component: ConfigurationAlertingActionsComponent, canActivate: [AuthGuard] }
                ]
            },
            { path: 'purging', component: ConfigurationPurgingComponent, canActivate: [AuthGuard] },
            { path: 'alerting', component: ConfigurationAlertingComponent, canActivate: [AuthGuard] },
        ]
    },
    { path: 'logout', component: LogoutComponent },
    // Failover 404
    { path: '**', component: NoContentComponent }
];
