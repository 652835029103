import { PipeTransform, Pipe } from '@angular/core';

// https://gist.github.com/adamrecsko/0f28f474eca63e0279455476cc11eca7
@Pipe({
    name: 'ceil'
})
export class CeilPipe implements PipeTransform {
    transform(theNumber: number): number {
        return Math.ceil(theNumber);
    }
}
