import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TnTAPIService } from "../../../_services";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { SnackbarService } from "ngx-snackbar";
import { ConfigurationAlertingActionsDetailComponent } from "./detail";

@Component({
    styleUrls: [
        "../../configuration.component.scss",
        "./configuration_alerting_actions.component.scss",
    ],
    templateUrl: "./configuration_alerting_actions.component.html",
})
export class ConfigurationAlertingActionsComponent implements OnInit {
    // Detail modal
    @ViewChild(ConfigurationAlertingActionsDetailComponent, { static: true }) detailModal: ConfigurationAlertingActionsDetailComponent;
    public lstAlertingActions: Array<TnTAlertingAction> = [];
    
    // Timer
    private timer;

    public isLoading: boolean;
    public addActionEnabled: boolean = false;
    public selectedRow: Number;
    public selectedSubscription: number;

    public selName: TnTAlertingAction;
    public selNameId: number = -1;

    public selSubscription: TnTAlertingAction;
    public selSubscriptionId: number = -1;

    public selCondition: TnTAlertingAction;
    public selConditionId: number = -1;

    public selDescription: TnTAlertingAction;
    public selDescriptionId: number = -1;

    public selTemplate: TnTAlertingAction;
    public selTemplateId: number = -1;



    public strNewName: string = null;
    public selNewSubscription: string = null;
    public selNewCondition: string = null;
    public strNewDescription: string = null;



    public lstSubscriptions: Array<TnTAlertingSubscription> = []
    public lstSubscriptionsNames: Array<string> = []
    public lstConditions: Array<TnTAlertingCondition> = []
    public lstConditionsNames: Array<string> = []
    

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private snackbarService: SnackbarService) {}

    public ngOnInit() {
        this.isLoading = true;
        this.getAlertingActions();
        this.getAlertingSubscriptions();
        this.getAlertingConditions();
        this.isLoading = false;
    }
    
    getAlertingSubscriptions() {
        this.svcAPI.getAlertingSubscriptions()
        .pipe(catchError((error) => {
            return of([]); // Return empty message set
        }))
        .subscribe(
            subscriptions => {
                this.lstSubscriptions = subscriptions
                subscriptions.forEach(subscription => {
                    this.lstSubscriptionsNames.push(subscription.Type);
                });
            }
        );
    }
    getAlertingConditions() {
        this.svcAPI.getAlertingConditions()
        .pipe(catchError((error) => {
            return of([]); // Return empty message set
        }))
        .subscribe(
            Condition => {
                this.lstConditions = Condition
                Condition.forEach(Condition => {
                    this.lstConditionsNames.push(Condition.Name);
                });
            }
        );
    }

    ngAfterViewInit(): void {}

    btnToggleActionClicked() {
        this.addActionEnabled = !this.addActionEnabled;
    }

    // Show detail modal
    public async openDetail(theAction: TnTAlertingAction) {
        this.getAlertingActions();

        await new Promise(f => setTimeout(f, 1000));


        this.lstAlertingActions.forEach(element => {
            if (element.Id == theAction.Id) {
                theAction = element;
            } 
        });
        this.selectedSubscription = theAction.Id;
        console.log(this.detailModal)
        this.detailModal.showChildModal(theAction);
    }

    removeAction(theAction: TnTAlertingAction){
        this.svcAPI.DeleteAlertingAction(theAction)
            .pipe(catchError((error) => {
                return 'Error';
            }))
            .subscribe(
            res => {
                if(res == 'Success'){
                    const _this = this;
                this.snackbarService.add({
                    msg: "Deleted",
                    timeout: 2000,
                    customClass: "snackBarSucces",
                    action: {
                        text: "X",
                    },
                });
                const index = this.lstAlertingActions.indexOf(theAction);
                if (index > -1) {
                    this.lstAlertingActions.splice(index, 1);
                }
                }
                else{
                    const _this = this;
                    this.snackbarService.add({
                        msg: res,
                        timeout: 2000,
                        customClass: "snackBarError",
                        action: {
                            text: "X",
                        },
                    });
                }
            }
        );
    }

    btnAddActionClicked() {
        
        if (this.strNewName) {
            if (!this.lstSubscriptionsNames.includes(this.selNewSubscription)) {
                const _this = this;
                this.snackbarService.add({
                    msg: 'Please select a type',
                    timeout: 2000,
                    customClass: 'snackBarError',
                    action: {
                        text: 'X'
                    }
                });

            } else {
                const theAction: TnTAlertingAction = <TnTAlertingAction>{
                    Name: this.strNewName ? this.strNewName.trim() : null,
                    Subscription: this.lstSubscriptions.find(t => t.Type == this.selNewSubscription) ? this.lstSubscriptions.find(t => t.Type == this.selNewSubscription) : null,
                    Condition: this.lstConditions.find(t => t.Name == this.selNewCondition) ? this.lstConditions.find(t => t.Name == this.selNewCondition) : null,
                    Description: this.strNewDescription ? this.strNewDescription.trim() : null
                };

                // TO DO SEND TO BACKEND
                console.log(theAction)
                
            this.svcAPI.AddAlertingAction(theAction)
            .pipe(catchError((error) => {
                return 'Error';
            }))
            .subscribe(
            res => {
                if(res['Message'] == 'Success'){
                this.snackbarService.add({
                    msg: "Saved",
                    timeout: 2000,
                    customClass: "snackBarSucces",
                    action: {
                        text: "X",
                    },
                });
                this.addActionEnabled = false
                this.lstAlertingActions.push(res['Value'])
                this.strNewName = null;
                this.strNewDescription = null;
                this.selNewSubscription = null;
                this.selNewCondition = null;
                }else{
                    this.snackbarService.add({
                        msg: res['Message'],
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });
                }
            }
        );
            }
        } else {
            const _this = this;
            this.snackbarService.add({
                msg: 'Please fill in name and URL',
                timeout: 2000,
                customClass: 'snackBarError',
                action: {
                    text: 'X'
                }
            });

        }
    }

    getAlertingActions() {
        this.svcAPI.getAlertingActions()
        .pipe(catchError((error) => {
            return of([]); // Return empty message set
        }))
        .subscribe(
            actions => {
                this.lstAlertingActions = actions
            }
        );
    }

    actionClicked(i: number, field: String, clicked?: TnTAlertingAction) {
        this.selectedRow = i;
        if (field === "Name") {
            this.lstAlertingActions.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = clicked;
                    this.selNameId = clicked.Id;
                    this.selSubscription = null;
                    this.selSubscriptionId = -1;
                    this.selCondition = null;
                    this.selConditionId = -1;
                    this.selDescription = null;
                    this.selDescriptionId = -1;
                }
            });
        }
        if (field === "Subscription") {
            this.lstAlertingActions.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = null;
                    this.selNameId = -1;
                    this.selSubscription = clicked;
                    this.selSubscriptionId = clicked.Id;
                    this.selCondition = null;
                    this.selConditionId = -1;
                    this.selDescription = null;
                    this.selDescriptionId = -1;
                }
            });
        }
        if (field === "Condition") {
            this.lstAlertingActions.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = null;
                    this.selNameId = -1;
                    this.selSubscription = null;
                    this.selSubscriptionId = -1;
                    this.selCondition = clicked;
                    this.selConditionId = clicked.Id;
                    this.selDescription = null;
                    this.selDescriptionId = -1;
                }
            });
        }
        if (field === "Description") {
            this.lstAlertingActions.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = null;
                    this.selNameId = -1;
                    this.selSubscription = null;
                    this.selSubscriptionId = -1;
                    this.selCondition = null;
                    this.selConditionId = -1;
                    this.selDescription = clicked;
                    this.selDescriptionId = clicked.Id;
                }
            });
        }
    }

    actionChanged(mt: TnTAlertingAction): void {
        if (mt != null) {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.svcAPI.modifyAlertingAction(mt).subscribe((result) => {
                    if (result === "Success") {
                        const _this = this;
                        this.snackbarService.add({
                            msg: "Saved",
                            timeout: 2000,
                            customClass: "snackBarSucces",
                            action: {
                                text: "X",
                            },
                        });
                    } else {
                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: "snackBarError",
                            action: {
                                text: "X",
                            },
                        });
                    }
                });

                clearInterval(this.timer);
            }, 800);
        }
    }
}
