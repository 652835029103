import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../_services/';

@Component({
    styleUrls: ['./configuration_settings.component.scss'],
    templateUrl: './configuration_settings.component.html',
})
export class ConfigurationSettingsComponent implements OnInit {
    // Loader
    public isLoading: boolean;

    public lstSetting: Array<TnTSettings> = [];
    public selSetting: TnTSettings; // Pointer to selected element in lstSetting
    public edtSetting: TnTSettings; // Will contain edited values for selected setting until another row is clicked, the changes are saved & this value is reset
    public newSetting: TnTSettings = <TnTSettings>{}; // Contains new setting values

    public strError: string = '';
    public strNewError: string = '';

    public lstOrder: Array<string> = [];
    public lstRowHighlight: Array<string> = [];

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router) {}

    public ngOnInit() {
        this.refreshData();

        this.lstOrder.push('ASC');
        this.lstOrder.push('DESC');

        this.lstRowHighlight.push('true');
        this.lstRowHighlight.push('false');

    }

    public refreshData(): void {
        // Retrieve list of settings
        this.svcAPI.getSettings().subscribe(
            setting => {
                this.lstSetting = setting;
            }
        );
    }

    public rowClicked(theSetting: TnTSettings) {
        if (this.selSetting !== theSetting) {
            this.strError = ''; // Reset error msg
            this.saveChanges();
            this.selSetting = theSetting;
            this.edtSetting = Object.assign({}, theSetting); // Copy properties, not just pointer to heap
        }
    }

    private saveChanges() {
        // API call to save changes
        if (this.selSetting != null && this.edtSetting != null) {
            if (this.selSetting.Logo !== this.edtSetting.Logo || this.selSetting.PrimaryColour !== this.edtSetting.PrimaryColour || this.selSetting.DateFormat !== this.edtSetting.DateFormat
                || this.selSetting.PageTitle !== this.edtSetting.PageTitle || this.selSetting.PageSubtitle !== this.edtSetting.PageSubtitle || this.selSetting.DownloadListOrder !== this.edtSetting.DownloadListOrder
                || this.selSetting.RowHighlight !== this.edtSetting.RowHighlight || this.selSetting.UserId !== this.edtSetting.UserId) {
                this.selSetting = this.edtSetting;
                this.svcAPI.changeSetting(this.edtSetting).subscribe(
                    (res) => {
                        this.refreshData();
                        this.selSetting = null;
                    },
                (err) => {
                    this.strError = err.error;
                });
            }
        }
    }

    public removeSetting(theSetting: TnTSettings): void {
        this.svcAPI.deleteSetting(theSetting).subscribe(
        (res) => {
            this.refreshData();
        },
        (err) => {
            this.selSetting = theSetting;
            this.strError = err.error;
        });
    }

    public addSetting(): void {
        this.svcAPI.addSetting(this.newSetting).subscribe(
            (res) => {
                this.newSetting = <TnTSettings>{}; // Reset setting
                this.refreshData();
            },
            (err) => {
                
            });
    }

    public validateNewSetting(): boolean {
        let valid: boolean = true;
        //if (!this.isNumber(this.newSetting.Id) || this.newSetting.Id < 0 || this.newSetting.Id > 255) valid = false;
        //if (!this.newSetting.Logo) valid = false;
        //if (!this.newSetting.PageTitle) valid = false;
        //if (!this.newSetting.PageSubTitle) valid = false;

        return valid;
    }

    private isNumber(value: string | number): boolean {
        return ((value != null) && !isNaN(Number(value.toString())));
    }
}
