import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../_services/';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SnackbarService } from 'ngx-snackbar';
import { DatePipe } from '@angular/common';

@Component({
    styleUrls: ['../configuration.component.scss', './configuration_purging.component.scss'],
    templateUrl: './configuration_purging.component.html',
    providers: [DatePipe]
})

export class ConfigurationPurgingComponent implements OnInit {

    public selectedRow: Number;
    private selCustom: TnTPurgingCustom = null;
    private selDefault: TnTPurgingDefault = null;
    private selCustomUnitId: number = -1;
    private selCustomValueId = -1;
    private selCustomBn: TnTMessageTypeBusinessName;
    private selRetentionUnit: String;
    private selCustomBNId: number = -1;
    public isLoading: boolean = false;

    //PurgingValues
    public strDefault: string = "";
    public strRetentionValue: string = "";
    public strRetentionUnit: string = "";

    //Add reprocesscustom
    public selNewPurgingCustomBn: TnTMessageTypeBusinessName;
    public selBusinessNames: Array<TnTMessageTypeBusinessName> = [];
    private timer;

    public lstPurgingCustoms: Array<TnTPurgingCustom> = [];
    public lstFEBusinessNames: Array<TnTMessageTypeBusinessName> = [];
    public lstAllBusinessNames: Array<TnTMessageTypeBusinessName> = [];
    public lstUnits: Array<String> = ["Days", "Messages"];
    public lstDefaultDays: Array<TnTPurgingDefault> = [];
    public defaultDays: TnTPurgingDefault = null;

    //Purging History Data
    //public lstPurgingHistory: Array<TnTPurgingHistory> = [];
    public FElstPurgingHistory: Array<FETnTPurgingHistoryAmountPerType> = [];
    public message: FETnTPurgingHistoryAmountPerType = { PurgedType: 'MESSAGE', AmountDeleted: 0 };
    public transaction: FETnTPurgingHistoryAmountPerType = { PurgedType: 'TRANSACTION', AmountDeleted: 0 };
    public process: FETnTPurgingHistoryAmountPerType = { PurgedType: 'PROCESS', AmountDeleted: 0 };
    public lstPurgingStatus: Array<TnTPurgingStatus> = [];
    public historyInterval: String;
    public lstIntervals: Array<String> = ["Today", "Last week", "Last month"];


    @ViewChild('inputRetentionValue', { static: false }) inputRetentionValue: any;
    @ViewChild('inputRetentionUnit', { static: false }) inputRetentionUnit: any;
    @ViewChild('inputDefault', { static: false }) inputDefault: any;

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private snackbarService: SnackbarService, public datepipe: DatePipe) { }

    public ngOnInit() {
        this.isLoading = true;
        this.getAllPurgingCustoms();
        this.getAllBusinessNames();
        this.getAvailableBusinessNames();
        this.getDefaultDays();
        this.getPurgingStatus();
        this.historyInterval = "Today";
        this.FElstPurgingHistory = [this.message, this.transaction, this.process];
        this.getPurgingHistory(this.historyInterval);
        this.isLoading = false;
    }

    ngAfterViewInit(): void { }


    public getAllPurgingCustoms(): void {
        this.svcAPI.getAllPurgingCustoms()
            .pipe(catchError((error) => {
                return of([]); // Return empty message set
            }))
            .subscribe(
                res => {
                    this.lstPurgingCustoms = res;
                }
            );
    }

    public getStatusColor() {
        return "red"
    }

    public getPurgingStatus() {
        var statusS, statusF;
        this.svcAPI.getPurgingStatus()
            .pipe(catchError((error) => {
                return of([]); // Return empty message set
            }))
            .subscribe(
                purgingStatus => {
                    this.lstPurgingStatus = purgingStatus;
                }
            );
    }

    public getDefaultDays() {
        this.svcAPI.getDefaultDays()
            .pipe(catchError((error) => {
                return of([]); // Return empty message set
            }))
            .subscribe(
                defaultdays => {
                    this.lstDefaultDays = defaultdays;
                    this.defaultDays = defaultdays[0];
                }
            );
    }

    public getPurgingHistory(historyInterval: String) {
        this.FElstPurgingHistory.forEach((h) => {
            h.AmountDeleted = 0;
        });
        this.svcAPI.getPurgingHistory(historyInterval)
            .pipe(catchError((error) => {
                return of([]); // Return empty message set
            }))
            .subscribe(
                purginghistory => {
                    //this.lstPurgingHistory = purginghistory;
                    purginghistory.forEach((h) => {
                        /*var found = false;
                        for (var i = 0; i < this.FElstPurgingHistory.length; i++) {
                            if (this.FElstPurgingHistory[i].PurgedType == h.PurgedType) {
                                found = true;
                                break;
                            }
                        }
                        if (found) {
                            this.FElstPurgingHistory.find(x => x.PurgedType == h.PurgedType).AmountDeleted += h.AmountDeleted;
                        }*/
                        switch(h.PurgedType) { 
                            case "PROCESS": { 
                                this.process.AmountDeleted = h.AmountDeleted
                               break; 
                            } 
                            case "MESSAGE": { 
                               this.message.AmountDeleted = h.AmountDeleted
                               break; 
                            } 
                            case "TRANSACTION": { 
                                this.transaction.AmountDeleted = h.AmountDeleted
                               break; 
                            } 
                            default: { 
                               //statements; 
                               break; 
                            } 
                         } 
                    });
                    
                }
            );
    }

    public getAllBusinessNames(): void {
        this.svcAPI.getMessageTypesBusinessNames(false,)
            .pipe(catchError((error) => {
                return of([]); // Return empty mt set
            }))
            .subscribe(
                bn => {
                    this.lstAllBusinessNames = bn;
                }
            );
    }

    public toClipBoard(val?: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.snackbarService.add({
            msg: 'Copied to clipboard.',
            timeout: 2000,
            customClass: 'snackBarSucces',
            action: {
                text: 'X'
            }
        });
    }

    public getAvailableBusinessNames(includeBusinessName?: TnTPurgingCustom) {

        if (includeBusinessName != null) {
            this.svcAPI.getBusinessNamesPurgingCustom(false, includeBusinessName.BusinessNameId)
                .pipe(catchError((error) => {
                    return of([]); // Return empty message set
                }))
                .subscribe(
                    businessNames => {
                        this.lstFEBusinessNames = businessNames;
                        this.selCustomBn = this.lstFEBusinessNames.find(x => x.Id == includeBusinessName.BusinessNameId);
                    }
                );
        } else {
            this.svcAPI.getBusinessNamesPurgingCustom(false)
                .pipe(catchError((error) => {
                    return of([]); // Return empty message set
                }))
                .subscribe(
                    businessNames => {
                        this.lstFEBusinessNames = businessNames;
                    }
                );

        }
    }

    public customClicked(i: number, field: String, clicked?: TnTPurgingCustom) {
        this.selectedRow = i;
        this.selCustom = clicked;
        this.selCustomValueId = -1;
        this.selCustomUnitId = -1;
        this.selCustomBNId = -1;
        this.lstFEBusinessNames = this.lstAllBusinessNames;
        if (clicked != undefined) {
            this.getAvailableBusinessNames(clicked);
        }

        if (field === 'RetentionValue') {
            this.lstPurgingCustoms.forEach((t) => {
                if (clicked.Id === t.Id) {
                    this.selCustomValueId = t.Id;
                    setTimeout(() => {
                        this.inputRetentionValue.nativeElement.focus();
                    }, 10);
                }
            });
        }

        if (field === 'RetentionUnit') {
            this.lstPurgingCustoms.forEach((t) => {
                if (clicked.Id === t.Id) {
                    this.selCustomUnitId = t.Id;
                    setTimeout(() => {
                        this.inputRetentionUnit.nativeElement.focus();
                    }, 10);
                }
            });
        }

        if (field === 'DefaultDaysToKeep') {
            this.lstDefaultDays.forEach((t) => {
                if (clicked.Id === t.Id) {
                    this.selCustomValueId = t.Id;
                    setTimeout(() => {
                        this.inputDefault.nativeElement.focus();
                    }, 10);
                }
            });
        }

        if (field === 'BusinessName') {
            this.lstPurgingCustoms.forEach((t) => {
                if (clicked.Id === t.Id) {
                    this.selCustomBNId = t.Id;
                }
            });
        }

    }
    public defaultClicked(clicked: TnTPurgingDefault) {
        this.selectedRow = -1;
        this.selDefault = clicked;
        this.selCustomValueId = -1;
        this.lstDefaultDays.forEach((t) => {
            if (clicked.Id === t.Id) {
                this.selCustomValueId = t.Id;
                setTimeout(() => {
                    this.inputDefault.nativeElement.focus();
                }, 10);
            }
        });
    }

    public DefaultChanged(t: TnTPurgingDefault): void {
        if (t != null) {
            if (isNaN(Number(t.DefaultDaysToKeep))) {
                const _this = this;
                this.snackbarService.add({
                    msg: 'Please provide a number value',
                    timeout: 2000,
                    customClass: 'snackBarError',
                    action: {
                        text: 'X'
                    }
                });
            }
            else if (t.DefaultDaysToKeep < 1) {
                const _this = this;
                this.snackbarService.add({
                    msg: 'Please provide a value higher than 0',
                    timeout: 2000,
                    customClass: 'snackBarError',
                    action: {
                        text: 'X'
                    }
                });
            }
            else {
                clearInterval(this.timer);
                this.timer = setInterval(() => {
                    this.svcAPI.changePurgingDefault(t).subscribe(
                        result => {
                            if (result === 'Succes') {
                                const _this = this;
                                this.snackbarService.add({
                                    msg: 'Saved',
                                    timeout: 2000,
                                    customClass: 'snackBarSucces',
                                    action: {
                                        text: 'X'
                                    }
                                });
                            } else {
                                const _this = this;
                                this.snackbarService.add({
                                    msg: result,
                                    timeout: 2000,
                                    customClass: 'snackBarError',
                                    action: {
                                        text: 'X'
                                    }
                                });
                            }
                        }
                    );
                    clearInterval(this.timer);
                }, 800);
            }
        }
    }

    public CustomChanged(t: TnTPurgingCustom): void {
        if (t != null) {
            if (t.RetentionValue < 1) {
                const _this = this;
                this.snackbarService.add({
                    msg: 'Please provide a value higher than 0',
                    timeout: 2000,
                    customClass: 'snackBarError',
                    action: {
                        text: 'X'
                    }
                });
                t.RetentionValue = 0;
            }
            else {
                clearInterval(this.timer);
                this.timer = setInterval(() => {
                    this.svcAPI.changePurgingCustom(t).subscribe(
                        result => {
                            if (result === 'Succes') {
                                const _this = this;
                                this.snackbarService.add({
                                    msg: 'Saved',
                                    timeout: 2000,
                                    customClass: 'snackBarSucces',
                                    action: {
                                        text: 'X'
                                    }
                                });
                            } else {
                                const _this = this;
                                this.snackbarService.add({
                                    msg: result,
                                    timeout: 2000,
                                    customClass: 'snackBarError',
                                    action: {
                                        text: 'X'
                                    }
                                });
                            }
                        }
                    );
                    clearInterval(this.timer);
                }, 800);
            }
        }
    }

    public purgingEnableChange(): void {
        console.log("defaultdays:   " + JSON.stringify(this.defaultDays));
        //defaultDaysToKeep.Enabled = true;
        ////defaultDaysToKeep.Enabled = this.purgingEnabled;
        //console.log("defaultdays:   " + JSON.stringify(defaultDaysToKeep));
        clearInterval(this.timer);
        this.timer = setInterval(() => {
            this.svcAPI.changePurgingDefault(this.defaultDays).subscribe(
                result => {
                    if (result === 'Succes') {
                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Saved',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });
                    } else {
                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: 'snackBarError',
                            action: {
                                text: 'X'
                            }
                        });
                    }
                }
            );
            clearInterval(this.timer);
        }, 800);
    }

    public bnCustomChanged(t: TnTPurgingCustom): void {
        if (t != null) {            
            t.BusinessNameId = t.BusinessName.Id;
            var FEConversion: TnTPurgingCustom = {
                Id: t.Id,
                BusinessNameId: Number(t.BusinessNameId),
                RetentionValue: Number(t.RetentionValue),
                RetentionUnit: t.RetentionUnit
            }
            this.svcAPI.changePurgingCustom(FEConversion).subscribe(
                result => {
                    if (result === 'Succes') {
                        this.getAllPurgingCustoms();
                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Saved',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });
                    } else {
                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: 'snackBarError',
                            action: {
                                text: 'X'
                            }
                        });
                    }

                }
                );
        }
        this.getAllBusinessNames();
        this.getAvailableBusinessNames();
        }
    
    public btnAddPurgingClicked(): void {
        if (this.strRetentionValue.includes(' ') || isNaN(Number(this.strRetentionValue))) {
            const _this = this;
            this.snackbarService.add({
                msg: 'Please provide a number value',
                timeout: 2000,
                customClass: 'snackBarError',
                action: {
                    text: 'X'
                }
            });
        }
        else if (Number(this.strRetentionValue) < 0) {
            const _this = this;
            this.snackbarService.add({
                msg: 'Please provide a positive value',
                timeout: 2000,
                customClass: 'snackBarError',
                action: {
                    text: 'X'
                }
            });
        } 
        else if (this.strRetentionUnit == '' || this.strRetentionValue == '') {
            const _this = this;
            this.snackbarService.add({
                msg: 'Please provide a retention value and unit',
                timeout: 2000,
                customClass: 'snackBarError',
                action: {
                    text: 'X'
                }
            });
        } 
        else if (this.selBusinessNames.length == 0) {
            const _this = this;
            this.snackbarService.add({
                msg: 'Please select at least 1 business name',
                timeout: 2000,
                customClass: 'snackBarError',
                action: {
                    text: 'X'
                }
            });
        } else {

            // AddPurgingoptionCustom            
            for (var bnItem of this.selBusinessNames) {
                var theNewPurgingCustom: TnTPurgingCustom = {
                    Id: Number(''),
                    BusinessNameId: Number(bnItem.Id),
                    RetentionValue: Number(this.strRetentionValue),
                    RetentionUnit: this.strRetentionUnit
                }
                this.svcAPI.addPurgingCustom(theNewPurgingCustom).subscribe(
                    result => {
                        if (result === 'Succes') {
                            this.getAllPurgingCustoms();
                            this.getAvailableBusinessNames();
                            //this.selNewPurgingCustomBn= null;                            
                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Added',
                                timeout: 2000,
                                customClass: 'snackBarSucces',
                                action: {
                                    text: 'X'
                                }
                            });
                        } else {
                            const _this = this;
                            this.snackbarService.add({
                                msg: result,
                                timeout: 2000,
                                customClass: 'snackBarError',
                                action: {
                                    text: 'X'
                                }
                            });
                        }
                    }
                );
            }
            this.selBusinessNames = [];
            this.strRetentionValue = "";
            this.strRetentionUnit = "";
        }
    }

    public removePurgingCustom(t: TnTPurgingCustom): void {
        if (t != null) {
            this.svcAPI.deletePurgingCustom(t.Id).subscribe(
                result => {
                    if (result === 'Succes') {
                        this.getAllPurgingCustoms();
                        this.getAvailableBusinessNames();
                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Removed',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });
                    } else {
                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: 'snackBarError',
                            action: {
                                text: 'X'
                            }
                        });
                    }
                }
            );
        }
    }   
}
