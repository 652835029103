<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="businessviewheader" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="businessviewheader" class="modal-title" *ngIf="theMessage">{{theMessage.MessageTypeBusinessName}}</h4>
                <div class="pull-right">
                <button type="button" class="close" aria-label="Close" (click)="hideChildModal()">
                    <i-feather name="x" class="modaltitlebutton"></i-feather>
                </button>
                <a *ngIf="theMessage && theMessage.HasViews" href="api/businessview/{{theMessage.Id}}?token={{this.usrToken}}" target="_blank" class="text-muted">
                    <button type="button" class="close" aria-label="Businessview">
                        <i-feather name="external-link" class="modaltitlebutton"></i-feather>
                    </button>
                </a>
                    <button *ngIf="theMessage && theMessage.HasViews" type="button" class="close" aria-label="Print" (click)="printBusinessview()">
                        <i-feather name="printer" class="modaltitlebutton"></i-feather>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <div class="businessview-content">
                    <!-- Business view -->
                    <div class="container-fluid" [hidden]="isLoading">
                        <iframe #frame id="bview" name="bview" class="businessview"></iframe>
                    </div>
                </div>
                <div class="container-fluid" *ngIf="isLoading">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                            Loading view.
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="modal-body">
                <div class="container-fluid">
                    <button class="btn btn-secondary btn-block" (click)="hideChildModal()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
