import { Component, OnInit, ViewChild, Inject, LOCALE_ID, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Location, formatDate } from "@angular/common";
import { TnTAPIService, ConfigurationService } from "../_services/";
import { DetailComponent } from "../detail/";
import { BusinessviewComponent } from "../businessview/";
import { PopoverConfig } from "ngx-bootstrap/popover";
import { of, timer } from "rxjs";
import { catchError } from "rxjs/operators";

export function getPopoverConfig(): PopoverConfig {
    return Object.assign(new PopoverConfig(), {
        placement: "bottom",
        outsideClick: "true",
    });
}

@Component({
    styleUrls: ["./home.component.scss"],
    templateUrl: "./home.component.html",
    providers: [{ provide: PopoverConfig, useFactory: getPopoverConfig }],
    encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
    public lstMessages: TnTMessage[] = [];
    public lstBusinessview: boolean[] = [];
    public lstFiltersAll: TnTFilter[] = [];
    public isLoading: boolean = true;

    // Storage
    public lstParties: Array<TnTPartyBusinessName> = [];
    public lstMessageTypes: Array<TnTMessageTypeBusinessName> = [];
    public lstStatus: Array<TnTTransactionStatus> = [];
    public mapStatus: Map<number, TnTTransactionStatus> = new Map<number, TnTTransactionStatus>();
    public selectedMessage: number = -1;
    public totalMessageCount: number = -1;

    // Model parameters to store (uncommitted) search parameters
    // Input search parameter bindings
    public srcReference: string = "";
    public srcAlerts: boolean = false;
    public dtFromParam: Date;
    public dtToParam: Date;
    public strFromParam: string;
    public strToParam: string;

    // Lists of selected items
    public lstSelectedSenders: Array<TnTPartyBusinessName> = [];
    public lstSelectedReceivers: Array<TnTPartyBusinessName> = [];
    public lstSelectedMessageTypes: Array<TnTMessageTypeBusinessName> = [];
    public lstSelectedStatus: Array<TnTTransactionStatus> = [];

    // Pagination
    public pagCurrentPage: number = 1;
    public pagItemsPerPage: number = 25;

    // Applied filters
    public blnFilterSenderApplied: boolean = false;
    public blnFilterReceiverApplied: boolean = false;
    public blnFilterMessageTypeApplied: boolean = false;
    public blnFilterStatusApplied: boolean = false;
    public blnFilterReferenceApplied: boolean = false;
    public blnFilterDateApplied: boolean = false;
    public blnPresetFilterApplied: boolean = false;

    // Stores currently applied/committed search parameters
    public appliedSearchParameters: TnTMessageSearchParameters;

    // Date
    public blnFromInvalid: boolean = false;
    public blnToInvalid: boolean = false;
    public blnApplyBtnDate: boolean = true;
    public fromError: string = "";
    public toError: string = "";

    // Variable number of references - table layout
    public maxReferences: number = 5; // Moet minstens 1 zijn, anders kan ref filter niet getoond worden
    public totalTableColumns: number = 7 + this.maxReferences;

    // Preset filters
    public strSearchPresetFilters: string = "";
    public objSearchPresetFilters: any = [];

    // Auto-refresh
    public blnAutoRefreshEnabled: boolean = false;
    public tmrAutoRefresh;
    public tmrAutoRefreshSubscription;
    public intAutoRefreshInterval = 30000;

    // Configuration object
    public SETTINGS: TnTSettings;

    // Button multi-download/export
    public showBtnMulti: boolean = false;
    private lstSelectedMsgs: any = [];
    public btnAllSelected: boolean = false;

    // Businessview modal
    @ViewChild(BusinessviewComponent, { static: true })
    businessviewModal: BusinessviewComponent;

    // Detail modal
    @ViewChild(DetailComponent, { static: true }) detailModal: DetailComponent;

    // Searchbox reference
    @ViewChild("inputReference", { static: false }) inputReference: any;

    // From date
    @ViewChild("inputFromDate", { static: false }) inputFromDate: any;

    constructor(
        private svcAPI: TnTAPIService,
        public svcConfig: ConfigurationService,
        private svcRouter: Router,
        private svcActivatedRoute: ActivatedRoute,
        private svcLocation: Location,
        @Inject(LOCALE_ID) private locale: string
    ) {
        this.svcConfig.SETTINGS.subscribe((res) => {
            if (res) {
                this.SETTINGS = res;
                console.log(this.SETTINGS);
            }
        });
    }

    public pageChanged(direction: string): void {
        let pagNewPage: number = this.pagCurrentPage;
        if (direction === "next") {
            pagNewPage++;
        } else if (direction === "previous") {
            pagNewPage--;
        } else if (direction === "first") {
            pagNewPage = 1;
        } else if (direction.substr(0, 1) === "+") {
            var skip: number = +direction.substr(1);
            pagNewPage = pagNewPage + skip;
        } else if (direction.substr(0, 1) === "-") {
            var skip: number = +direction.substr(1);
            pagNewPage = pagNewPage - skip;
        }

        // False triggers vermijden
        if (pagNewPage > 0 && this.pagCurrentPage !== pagNewPage) {
            this.getMessagesForParams(pagNewPage);
            this.pagCurrentPage = pagNewPage;
        }
    }

    ngOnInit() {
        // Get route parameter for message detail
        this.svcActivatedRoute.paramMap.subscribe((params) => {
            const tmpID = params.get("id");
            if (tmpID != null) {
                const theID: number = Number(tmpID);
                if (!isNaN(theID)) {
                    this.svcAPI
                        .getMessage(theID)
                        .pipe(
                            catchError((error) => {
                                this.svcLocation.replaceState("/");
                                return of(null); // Return empty message
                            })
                        )
                        .subscribe((theMessage) => {
                            if (theMessage != null) this.detailModal.showChildModal(theMessage);
                        });
                } else {
                    this.svcLocation.replaceState("/"); // Reset route (invalid ID)
                }
            } // No need to reset route, ID = null
        });

        // Retrieve list of parties
        this.svcAPI.getPartiesBusinessNames(true).subscribe((parties) => {
            this.lstParties = parties;
        });

        // Retrieve list of messagetypes
        this.svcAPI.getMessageTypesBusinessNames(true).subscribe((messagetypes) => {
            this.lstMessageTypes = messagetypes;
        });

        // Retrieve list of filters
        this.svcAPI
            .getFilters()
            .pipe(
                catchError((error) => {
                    return of([]); // Return empty message set
                })
            )
            .subscribe((filters) => {
                this.lstFiltersAll = filters;
            });

        // Get messages on load
        this.getMessagesForParams();
    }

    // Show detail modal
    public messageClicked(theMessage: TnTMessage) {
        this.selectedMessage = theMessage.Id;
        this.detailModal.showChildModal(theMessage);
    }

    // Show businessview modal
    public eyeClicked(theMessage: TnTMessage) {
        this.businessviewModal.showChildModal(theMessage);
    }

    public clearFilter(theFilter: string = "") {
        this.blnPresetFilterApplied = false;
        this.objSearchPresetFilters = [];

        if (theFilter === "sender") {
            this.lstSelectedSenders = [];
            this.blnFilterSenderApplied = false;
        } else if (theFilter === "receiver") {
            this.lstSelectedReceivers = [];
            this.blnFilterReceiverApplied = false;
        } else if (theFilter === "messagetype") {
            this.lstSelectedMessageTypes = [];
            this.blnFilterMessageTypeApplied = false;
        } else if (theFilter === "status") {
            this.lstSelectedStatus = [];
            this.blnFilterStatusApplied = false;
        } else if (theFilter === "reference") {
            this.srcReference = "";
            this.blnFilterReferenceApplied = false;
        } else if (theFilter === "date") {
            this.strFromParam = "";
            this.strToParam = "";
            this.dtFromParam = null;
            this.dtToParam = null;
            this.blnFilterDateApplied = false;
        }

        this.applyFilters();
    }

    public fromChanged() {
        /*if(this.strFromParam.length == 2 ) this.strFromParam += "/"
        if(this.strFromParam.length == 5) this.strFromParam += "/"
        if(this.strFromParam.length == 10) this.strFromParam += " "
        if(this.strFromParam.length == 13) this.strFromParam += ":"*/
        let invalidDate: boolean = true;
        console.log("From changed");
        console.log(new Date(this.strFromParam + ":00.000"));
        this.dtFromParam = new Date(this.strFromParam + ":00.000");
        // DD/MM/YYYY HH:MM
        const trimDt: string = this.strFromParam.trim();
        if (trimDt.length === 10) {
            // Parse date
            const splitDate = trimDt.split("/");
            const tmpDate = new Date(splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0] + "T00:00:00.000"); // If no date in from field, take start of day = 00.00h

            if (!isNaN(tmpDate.getTime())) {
                this.dtFromParam = tmpDate;
                invalidDate = false;
            }
        } else if (trimDt.length === 16) {
            // Parse datetime
            const splitDateTime = trimDt.split(" ");
            const splitDate = splitDateTime[0].split("/");
            const splitTime = splitDateTime[1].split(":");
            const tmpDate = new Date(
                splitDate[2] +
                    "-" +
                    splitDate[1] +
                    "-" +
                    splitDate[0] +
                    "T" +
                    splitTime[0] +
                    ":" +
                    splitTime[1] +
                    ":00.000"
            ); // Define datetime as GMT

            if (!isNaN(tmpDate.getTime())) {
                this.dtFromParam = tmpDate;
                invalidDate = false;
            }
        } else {
            this.dtFromParam = null;
        }

        if (this.strFromParam.length === 0) invalidDate = false;
        // Set error if needed
        this.blnFromInvalid = invalidDate;
        this.fromError = invalidDate ? "Invalid date." : "";
        this.blnApplyBtnDate = this.dtFromParam != null || this.dtToParam != null;
    }

    public toChanged() {
        let invalidDate: boolean = true;
        console.log("To changed");
        console.log(new Date(this.strToParam + ":00.000"));
        this.dtToParam = new Date(this.strToParam + ":00.000");

        // DD/MM/YYYY HH:MM
        const trimDt: string = this.strToParam.trim();
        if (trimDt.length === 10) {
            // Parse date
            const splitDate = trimDt.split("/");
            const tmpDate = new Date(splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0] + "T23:59:59.999"); // If no date in to field, take start of day = 00.00h

            if (!isNaN(tmpDate.getTime())) {
                this.dtToParam = tmpDate;
                invalidDate = false;
            }
        } else if (trimDt.length === 16) {
            // Parse datetime
            const splitDateTime = trimDt.split(" ");
            const splitDate = splitDateTime[0].split("/");
            const splitTime = splitDateTime[1].split(":");
            const tmpDate = new Date(
                splitDate[2] +
                    "-" +
                    splitDate[1] +
                    "-" +
                    splitDate[0] +
                    "T" +
                    splitTime[0] +
                    ":" +
                    splitTime[1] +
                    ":00.000"
            ); // Define datetime as GMT

            if (!isNaN(tmpDate.getTime())) {
                this.dtToParam = tmpDate;
                invalidDate = false;
            }
        } else {
            this.dtToParam = null;
        }

        if (this.strToParam.length === 0) invalidDate = false;
        // Set error if needed
        this.blnToInvalid = invalidDate;
        this.toError = invalidDate ? "Invalid date." : "";
        this.blnApplyBtnDate = this.dtFromParam != null || this.dtToParam != null;
    }

    // For-loops in template without elements to loop over
    public createRange(tmpArray: TnTReference[]) {
        //const theLength = 1;
        const theLength = this.maxReferences - tmpArray.length; //old logic
        const items: number[] = [];
        for (let i = 0; i < theLength; i++) {
            items.push(i);
        }

        return items;
    }

    public focusInputReference() {
        setTimeout(() => {
            this.inputReference.nativeElement.focus();
        }, 10);
    }

    public focusInputFrom() {
        setTimeout(() => {
            this.inputFromDate.nativeElement.focus();
        }, 10);
    }

    public dateTextAutoComplete() {
        // Update date fields, fill in hour if left empty
        const trimStrFrom: string = this.strFromParam.trim();
        const trimStrTo: string = this.strToParam.trim();
        if (trimStrFrom.length === 10) this.strFromParam = this.strFromParam + " 00:00";
        if (trimStrTo.length === 10) this.strToParam = this.strToParam + " 23:59";
    }

    public applyFilters() {
        // Refresh results
        this.blnPresetFilterApplied = false;
        this.refreshResults();

        // Set filter indicators
        this.blnFilterSenderApplied = this.appliedSearchParameters.senders.length > 0;
        this.blnFilterReceiverApplied = this.appliedSearchParameters.receivers.length > 0;
        this.blnFilterMessageTypeApplied = this.appliedSearchParameters.messageTypes.length > 0;
        this.blnFilterStatusApplied = this.appliedSearchParameters.status.length > 0;
        this.blnFilterReferenceApplied = this.srcReference.length > 0;
        this.blnFilterDateApplied =
            this.appliedSearchParameters.startDate != null || this.appliedSearchParameters.endDate != null;
    }

    public applyPresetFilter() {
        if (this.objSearchPresetFilters !== null) {
            var id: number = this.objSearchPresetFilters.Id;
            this.resetManualFilters();

            this.lstFiltersAll.forEach((f) => {
                if (f.Id === id) {
                    // Show applied filter
                    this.blnPresetFilterApplied = true;

                    // Set reference search
                    if (f.Reference !== null && f.Reference !== "") {
                        this.blnFilterReferenceApplied = true;
                        this.srcReference = f.Reference;
                    }

                    // Set senders
                    if (f.Senders !== null && f.Senders !== "") {
                        this.blnFilterSenderApplied = true;
                        const tmpList = f.Senders.trim().split(",");
                        tmpList.forEach((sid) => {
                            this.lstParties.forEach((p) => {
                                if (parseInt(sid.trim(), 10) === p.Id) {
                                    this.lstSelectedSenders.push({
                                        Id: p.Id,
                                        BusinessName: p.BusinessName,
                                    });
                                }
                            });
                        });
                    }

                    // Set Receivers
                    if (f.Receivers !== null && f.Receivers !== "") {
                        this.blnFilterReceiverApplied = true;
                        const tmpList = f.Receivers.trim().split(",");
                        tmpList.forEach((sid) => {
                            this.lstParties.forEach((p) => {
                                if (parseInt(sid.trim(), 10) === p.Id) {
                                    this.lstSelectedReceivers.push({
                                        Id: p.Id,
                                        BusinessName: p.BusinessName,
                                    });
                                }
                            });
                        });
                    }

                    // Set MessageTypes
                    if (f.MessageTypes !== null && f.MessageTypes !== "") {
                        this.blnFilterMessageTypeApplied = true;
                        const tmpList = f.MessageTypes.trim().split(",");
                        tmpList.forEach((sid) => {
                            this.lstMessageTypes.forEach((mt) => {
                                if (parseInt(sid.trim(), 10) === mt.Id) {
                                    this.lstSelectedMessageTypes.push({
                                        Id: mt.Id,
                                        BusinessName: mt.BusinessName,
                                    });
                                }
                            });
                        });
                    }

                    // Set transaction status
                    if (f.TransactionStatus !== null && f.TransactionStatus !== "") {
                        this.blnFilterStatusApplied = true;
                        const tmpList = f.TransactionStatus.trim().split(",");
                        tmpList.forEach((sid) => {
                            this.lstStatus.forEach((s) => {
                                if (parseInt(sid.trim(), 10) === s.Id) {
                                    this.lstSelectedStatus.push({
                                        Id: s.Id,
                                        TransactionStatus: s.TransactionStatus,
                                        Text: s.Text,
                                        Icon: s.Icon,
                                    });
                                }
                            });
                        });
                    }

                    // SetEndDate
                    if (f.EndDate !== null) {
                        const tmpDate = new Date(f.EndDate); // Define datetime as GMT

                        if (!isNaN(tmpDate.getTime())) {
                            this.dtToParam = tmpDate;
                            this.blnFilterDateApplied = true;
                            this.strToParam = formatDate(tmpDate, "dd/MM/yyyy HH:mm", this.locale).toString();
                        }
                    }

                    // SetFromDate
                    if (f.StartDate !== null) {
                        const tmpDate = new Date(f.StartDate); // Define datetime as GMT

                        if (!isNaN(tmpDate.getTime())) {
                            this.dtFromParam = tmpDate;
                            this.blnFilterDateApplied = true;
                            this.strFromParam = formatDate(tmpDate, "dd/MM/yyyy HH:mm", this.locale).toString();
                        }
                    }

                    // Set transaction status
                    if (f.FailedMessages !== null && f.FailedMessages) {
                        this.srcAlerts = true;
                    }

                    // Execute refresh
                    this.refreshResults();
                }
            });
        }
    }

    // Resets all search parameters and refreshes result list
    public resetAllFilters() {
        this.objSearchPresetFilters = [];
        this.resetManualFilters();
    }

    public resetManualFilters() {
        // Reset reference search
        this.srcReference = "";

        // Reset selections
        this.lstSelectedSenders = [];
        this.lstSelectedReceivers = [];
        this.lstSelectedMessageTypes = [];
        this.lstSelectedStatus = [];
        this.strFromParam = "";
        this.strToParam = "";
        this.dtFromParam = null;
        this.dtToParam = null;

        // Reset filter indicators
        this.blnFilterSenderApplied = false;
        this.blnFilterReceiverApplied = false;
        this.blnFilterMessageTypeApplied = false;
        this.blnFilterStatusApplied = false;
        this.blnFilterReferenceApplied = false;
        this.blnFilterDateApplied = false;
        this.blnPresetFilterApplied = false;
    }

    // Refreshes result list
    public refreshResults() {
        // Reset page, or page numbering will be inconsistent
        this.pagCurrentPage = 1;

        // Reload search results
        this.getMessagesForParams();
    }

    // Executes API call to retrieve search results if search parameters changed
    public getMessagesForParams(thePage: number = this.pagCurrentPage) {
        const theParams: TnTMessageSearchParameters = <TnTMessageSearchParameters>{
            senders: this.lstSelectedSenders,
            receivers: this.lstSelectedReceivers,
            messageTypes: this.lstSelectedMessageTypes,
            status: this.lstSelectedStatus,
            startDate: this.dtFromParam,
            endDate: this.dtToParam,
            reference: this.srcReference,
            alert: this.srcAlerts,
        };
        this.appliedSearchParameters = theParams;

        // GetMessageCount
        this.svcAPI.getMessageCount(theParams).subscribe((count) => {
            this.totalMessageCount = count;
        });

        this.isLoading = true; // Enable loading indicator
        this.svcAPI
            .getMessages(theParams, thePage, this.pagItemsPerPage)
            .pipe(
                catchError((error) => {
                    return of([]); // Return empty message set
                })
            )
            .subscribe((messages) => {
                this.lstMessages = messages;

                // Update max references count & update transaction status list if messages contain a new status
                let updatedStatuses: boolean = false; // update them max once for a message batch
                if (this.lstMessages.length === 0) this.isLoading = false;
                for (const theMessage of this.lstMessages) {
                    // Max reference count
                    /*if (theMessage.References.length > this.maxReferences) {

                        //this.maxReferences = theMessage.References.length; Before we showed all refs
                        this.maxReferences = 2;
                        this.totalTableColumns = 7 + this.maxReferences;
                    }*/

                    // Transaction status
                    if (!this.mapStatus.has(theMessage.Status) && !updatedStatuses) {
                        updatedStatuses = true;
                        this.svcAPI
                            .getTransactionStatusTypes() // Transaction statustypes
                            .pipe(
                                catchError((error) => {
                                    return of([]); // Return empty status set
                                })
                            )
                            .subscribe((theStatuses) => {
                                this.mapStatus.clear();
                                for (const t of theStatuses) {
                                    this.mapStatus.set(t.Id, t);
                                }
                                this.lstStatus = Array.from(this.mapStatus.values());
                                this.isLoading = false; // Disable loading indicator after statuses were loaded
                            });
                    } else {
                        // Disable loading indicator, enable message table - no statuses to load
                        this.isLoading = false;
                    }
                }
            });
    }

    public stopProp(e) {
        e.stopPropagation();
    }

    public pagItemsPerPageChange() {
        this.pagCurrentPage = 1;
        this.getMessagesForParams();
    }

    public btnResetClick() {
        this.resetAllFilters();
        this.refreshResults();
    }

    public btnRefreshClick() {
        if (this.blnAutoRefreshEnabled) this.toggleAutoRefresh();
        this.refreshResults();
    }

    public btnAutoRefreshClick() {
        this.toggleAutoRefresh();
    }

    public toggleAutoRefresh() {
        this.blnAutoRefreshEnabled = !this.blnAutoRefreshEnabled;
        if (this.blnAutoRefreshEnabled) {
            this.tmrAutoRefresh = timer(this.intAutoRefreshInterval, this.intAutoRefreshInterval);
            this.tmrAutoRefreshSubscription = this.tmrAutoRefresh.subscribe(() => {
                this.refreshResults();
            });
        } else {
            this.tmrAutoRefreshSubscription.unsubscribe();
        }
    }

    public handleSelected($event, id: Number) {
        if ($event.target.checked === true) {
            this.lstSelectedMsgs.push(id);
        } else {
            this.lstSelectedMsgs.forEach((value, index) => {
                if (value == id) this.lstSelectedMsgs.splice(index, 1);
            });
        }
        if (this.lstSelectedMsgs.length > 0) {
            this.showBtnMulti = true;
        } else this.showBtnMulti = false;
    }

    public multiDownload(): void {
        this.svcAPI.getZipDownload(this.lstSelectedMsgs).subscribe((res) => {
            // Content-Disposition: attachment; filename=...
            const fileName = res.headers
                .get("content-disposition")
                .split(";")[1]
                .trim()
                .split("=")[1]
                .replace(/"/g, "");

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(res.body, fileName);
                return;
            }

            const url = window.URL.createObjectURL(res.body);
            const a = document.createElement("a");

            document.body.appendChild(a);

            // Explicitely hide element
            a.setAttribute("style", "display: none");

            // Set-up filename & downloadlink (url = data as blob, works in FF, Chrome, Opera, whatever browser except IE ofc)
            a.href = url;
            a.download = fileName;

            // Dispatchevent instead of click, works in Firefox
            a.dispatchEvent(
                new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );

            // For Firefox it is necessary to delay revoking the ObjectURL
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                a.remove();
            }, 100);
        });
    }

    public multiExport(): void {
        this.svcAPI.getExport(this.lstSelectedMsgs).subscribe((res) => {
            // Content-Disposition: attachment; filename=...
            const fileName = res.headers
                .get("content-disposition")
                .split(";")[1]
                .trim()
                .split("=")[1]
                .replace(/"/g, "");

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(res.body, fileName);
                return;
            }

            const url = window.URL.createObjectURL(res.body);
            const a = document.createElement("a");

            document.body.appendChild(a);

            // Explicitely hide element
            a.setAttribute("style", "display: none");

            // Set-up filename & downloadlink (url = data as blob, works in FF, Chrome, Opera, whatever browser except IE ofc)
            a.href = url;
            a.download = fileName;

            // Dispatchevent instead of click, works in Firefox
            a.dispatchEvent(
                new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );

            // For Firefox it is necessary to delay revoking the ObjectURL
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                a.remove();
            }, 100);
        });
    }

    public async selectAll(): Promise<void> {
        // this.pagItemsPerPage = this.totalMessageCount;
        this.btnAllSelected = true;

        // this.getMessagesForParams()

        // await new Promise(f => setTimeout(f, 3000));

        console.log(this.lstMessages.length);
        this.lstMessages.forEach((message) => {
            this.lstSelectedMsgs.push(message.Id);
        });

        if (this.lstSelectedMsgs.length > 0) {
            this.showBtnMulti = true;
        } else this.showBtnMulti = false;
    }

    public deSelectAll(): void {
        this.lstSelectedMsgs = [];

        this.btnAllSelected = false;
        if (this.lstSelectedMsgs.length > 0) {
            this.showBtnMulti = true;
        } else this.showBtnMulti = false;
    }

    public myFunction() {
        document.getElementById("myDropdown").classList.toggle("show");
    }
}
