<ng-template #filterMessagetype>
    <div class="filterwidth">
        <ng-select [items]="lstAllBusinessNames" [multiple]="false" [clearable]="true" bindLabel="BusinessName" [(ngModel)]="srcBusinessNameFilter">
        </ng-select>
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="getAllPurgingCustoms(); popoverMessageType.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearSearchMessagetype(); popoverMessageType.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<div class="container-fluid paddingTop">
    <div class="row">
        <div class="col-md-6">
            <h1 class="display-4 display">Retention</h1>
        </div>
        <div class="col-md-6">
            <div class="form-inline d-flex align-items-center float-right purgingEnable">
                <div class="btn-group" btnRadioGroup [(ngModel)]="defaultDays.Enabled">
                    <label class="btn btn-outline-danger mb-0" [btnRadio]="false" (click)="purgingEnableChange()">Disable</label>
                    <label class="btn btn-outline-success mb-0" [btnRadio]="true" (click)="purgingEnableChange()">Enable</label>
                </div>
            </div>
            <h1 class="display-4 display">
                Job status
                <span *ngIf="lstPurgingStatus.length > 0">
                    <svg *ngIf="lstPurgingStatus[0].State == 'SUCCESS' && defaultDays.Enabled" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" /></svg>
                    <svg *ngIf="lstPurgingStatus[0].State == 'ERROR' && defaultDays.Enabled" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" /></svg>
                    <svg *ngIf="lstPurgingStatus[0].State == 'RUNNING' && defaultDays.Enabled" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" /></svg>
                    <svg *ngIf="!defaultDays.Enabled" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" /></svg>
                </span>
                <span *ngIf="lstPurgingStatus.length < 1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" /></svg>
                </span>
            </h1>
            <div id="divDefaultDaysToKeep" *ngFor="let defaultvar of lstDefaultDays">
                <input class="float-right form-control" id="inputDefaultDaysToKeep" [(ngModel)]="defaultvar.DefaultDaysToKeep" (input)="DefaultChanged(defaultvar)" [disabled]="defaultDays.Enabled">
                <h6 class="display-4 display float-right small-title" id="labelDefaultDaysToKeep">Default days to keep: </h6>
            </div>
        </div>
    </div>
</div>

<div class="row paddingTable container-fluid">
    <div class="col-md-6 paddingBottomTable">
        <div class="card">
            <div class="table-responsive" style="overflow: inherit">
                <table class="table table-hover p-3 mb-5 tableLikeCard">
                    <thead class="text-primary-inverse">
                        <tr>
                            <th class="col-8 firstCell">BusinessName</th>
                            <th class="col-4">Retention Value</th>
                            <th class="col-4">Retention Unit</th>
                            <th class="smallTD-6 centerText"></th>
                        </tr>
                    </thead>
                    <tbody class="pointer">
                        <tr>
                            <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstPurgingCustoms.length == 0">
                                No custom purging settings found.
                            </td>
                            <td colspan="12" *ngIf="isLoading">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                        Loading custom purging settings
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr id="lastTR">
                            <td class="col-5" (click)="$event.stopPropagation();">
                                <ng-select [items]="lstFEBusinessNames" [multiple]="true" [clearable]="true" [closeOnSelect]="false" bindLabel="BusinessName" [(ngModel)]="selBusinessNames">
                                </ng-select>
                            </td>
                            <td class="col-5 ellipsis">
                                <div class="form-group">
                                    <input class="form-control" [(ngModel)]="strRetentionValue" />
                                </div>
                            </td>
                            <td class="col-5" (click)="unitClicked(); $event.stopPropagation();">
                                <ng-select [items]="lstUnits" [multiple]="false" [clearable]="false" bindLabel="RetentionUnit" [(ngModel)]="strRetentionUnit">
                                </ng-select>
                            </td>
                            <td class="smallTD-6 centerText">
                                <button class="btn btn-outline-primary" type="button" (click)="btnAddPurgingClicked()">
                                    <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
                                </button>
                            </td>
                        </tr>
                        <tr *ngFor="let custom of lstPurgingCustoms; let i = index" [class.active]="i == selectedRow">
                            <ng-container>
                                <td class="firstCell col-8" [ngClass]="selCustomBNId != custom.Id" (click)="customClicked(i, 'BusinessName', custom); $event.stopPropagation();">
                                    <span class="ellipsisSpecial" *ngIf="selCustomBNId != custom.Id" tooltip="{{custom.BusinessName}}"> {{custom.BusinessName}}</span>
                                    <ng-select *ngIf="selCustomBNId == custom.Id" [items]="lstFEBusinessNames" [multiple]="false" [clearable]="false" bindLabel="BusinessName" [(ngModel)]="custom.BusinessName" (ngModelChange)="bnCustomChanged(custom)">
                                    </ng-select>
                                </td>

                                <td class="col-5 ellipsis" [ngClass]="selCustomValueId != custom.Id" (click)="customClicked(i, 'RetentionValue', custom); $event.stopPropagation();">
                                    <div class="form-group">
                                        <span *ngIf="selCustomValueId != custom.Id">{{custom.RetentionValue}}</span>
                                        <input #inputURL class="form-control" *ngIf="selCustomValueId == custom.Id" [(ngModel)]="custom.RetentionValue" (input)="CustomChanged(custom)" />
                                    </div>
                                </td>

                                <td class="firstCell col-5" [ngClass]="selCustomUnitId != custom.Id" (click)="customClicked(i, 'RetentionUnit', custom); $event.stopPropagation();">
                                    <span *ngIf="selCustomUnitId != custom.Id"> {{custom.RetentionUnit}}</span>
                                    <ng-select *ngIf="selCustomUnitId == custom.Id" [items]="lstUnits" [multiple]="false" [clearable]="false" bindLabel="RetentionUnit" [(ngModel)]="custom.RetentionUnit" (ngModelChange)="CustomChanged(custom)">
                                    </ng-select>
                                </td>
                                <td class="smallTD-6 centerText">
                                    <span (click)="removePurgingCustom(custom)" class="hoverTrashIcon"><i-feather name="trash"></i-feather></span>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-6 paddingBottomTable">
        <div class="table-responsive" style="overflow: inherit">
            <div class="card">
                <table class="table table-hover p-3 mb-5 tableLikeCard">
                    <thead class="text-primary-inverse">
                        <tr>
                            <th class="firstCell col-md-6" id="runtime">Last status runtime</th>
                            <th class="col-md-4" id="status">Status</th>
                            <th class="centerText" [ngClass]="{'smallTD-6': !isLoading && lstPurgingCustoms.length > 0, 'col-md-3': !isLoading && lstPurgingCustoms.length == 0}"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstPurgingStatus.length == 0">
                                No status to display.
                            </td>
                            <td colspan="12" *ngIf="isLoading">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                        Loading status
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr *ngFor="let runstatus of lstPurgingStatus; let i = index">
                            <td class="firstCell col-md-6 ellipsis">
                                <span>
                                    {{this.datepipe.transform(runstatus.PurgingDate, 'dd/MM/yyyy - HH:mm:ss')}}
                                </span>
                            </td>
                            <td class="col-md-4 ellipsis">
                                <span>{{runstatus.State}}</span>
                            </td>
                            <td class="smallTD-6 centerText">
                                <span *ngIf="runstatus.Errormessage != null && runstatus.Errormessage != ''" (click)="toClipBoard(runstatus.State + ': '+runstatus.Errormessage)" class="hoverTrashIcon"><i-feather name="clipboard"></i-feather></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="container-fluid paddingTopHistory">
            <h1 class="display-4 display">Job history</h1>
        </div>
        <ng-select id="historyInterval" class="col-md-3" [items]="lstIntervals" [multiple]="false" [clearable]="false" [searchable]="false" bindLabel="historyInterval" [(ngModel)]="historyInterval" (ngModelChange)="getPurgingHistory(historyInterval)">
        </ng-select>

        <div class="card">
            <div class="table-responsive" style="overflow: inherit">
                <table class="table table-hover p-3 mb-5 tableLikeCard">
                    <thead class="text-primary-inverse">
                        <tr>
                            <th class="col-5 firstCell">Purged Type</th>
                            <th class="col-5">Amount deleted</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && FElstPurgingHistory.length == 0">
                                No history yet
                            </td>
                            <td colspan="12" *ngIf="isLoading">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                        Loading status
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let history of FElstPurgingHistory">
                            <td class="firstCell col-5">
                                <span>{{history.PurgedType}}</span>
                            </td>
                            <td class="col-5 ellipsis">
                                <span>{{history.AmountDeleted}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>