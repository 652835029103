import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../_services/';
import { OAuthService } from 'angular-oauth2-oidc';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from 'ngx-snackbar';

@Component({
    styleUrls: ['./userportal_users.component.scss'],
    templateUrl: './userportal_users.component.html',
})

export class UserportalUsersComponent implements OnInit {
    public lstUsers: TnTUser[] = [];
    public selUser: TnTUser = null;
    public selUserId: number = -1;

    public thisUser: string = '';

    public lstGroups: any;
    public lstUserUserGroups: any;

    public blnLoadingUsers: boolean = true;

    // New user
    public newUserName: string = '';
    public newUserGroupId: number = null;

    // Modify user
    public modifyUserGroupId: number = 0;

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private oauthService: OAuthService, private snackbarService: SnackbarService) { }

    public ngOnInit() {
        // Get the username of the user who's currently logged in
        const claims = this.oauthService.getIdentityClaims();
        if (claims) this.thisUser = claims['oid'];

        // Get total user count, update on user delete/add
        this.getUsers();

        this.svcAPI.portalGetGroups().subscribe(
            groups => {
                this.lstGroups = [];
                for (const group of groups) {
                    const data = { 'Group': group, 'Active': 0 };
                    this.lstGroups.push(data);
                }
            }
        );
    }

    public modifyUser(): void {
  //      this.selUser.UsergroupId = this.lstGroups[this.modifyUserGroupId].Id;
        this.svcAPI.portalModUser(this.selUser).subscribe(
            result => {
                if (result) { // user added
                    this.getUsers();

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Saved',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });

                } else {

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Failed to modify user',
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });

                }
            }
        );
    }

    public addNewUser(): void {
        const theUser: TnTUser = <TnTUser> {
            ADUser: this.newUserName
        };
        this.svcAPI.portalAddUser(theUser).subscribe(
            result => {
                if (result) { // user added
                    this.getUsers();

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Added',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });

                } else {

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Failed to add user',
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });

                }
            }
        );
    }

    public pageChanged(theEvent: any): void {
        this.getUsers(theEvent.page);
    }

    public userClicked(idx: number): void {
        this.selUser = this.lstUsers[idx];
        this.selUserId = this.selUser.Id;

        // Assignment keeps reference to the previous array.
        this.lstUserUserGroups = this.lstGroups.map(x => Object.assign({}, x));

        // Retrieve active messagetypes
        this.svcAPI.portalGetGroupsFromUser(this.selUser).subscribe(
            activeGroups => {
                activeGroups.forEach((activeGroup) => {
                    this.lstGroups.forEach((group, sidx) => {
                        if (group.Group.Id === activeGroup.Id) {
                            this.lstUserUserGroups[sidx].Active = 1;
                        }
                    });
                });
            }
        );
    }

    public removeUser(): any {
        this.svcAPI.portalRemUser(this.selUser).subscribe(
            result => {
                if (result) { // user added
                    this.getUsers();

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Removed',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });
                    this.getUsers();
                    this.selUser = null;
                } else {

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Failed to modify user',
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });

                }
            }
        );

    }

    private getUsers(thePage: number = 1) {
        this.blnLoadingUsers = true;
        // Users ophalen
        this.svcAPI.portalGetUsers().subscribe(
            users => {
                this.lstUsers = users;
                this.blnLoadingUsers = false;
            }
        );
    }

    public groupClicked(clickedGroup: any): void {
        this.lstUserUserGroups.forEach((group, idx) => {
            if (group.Group.Id === clickedGroup.Group.Id) {
                if (clickedGroup.Active === 0)
                    this.lstUserUserGroups[idx].Active = 1;
                else
                    this.lstUserUserGroups[idx].Active = 0;
            }
        });
        this.saveGroupsForUser();
    }

    public saveGroupsForUser(): void {
        const activeGroupIds: number[] = [];
        this.lstUserUserGroups.forEach((group, idx) => {
            if (group.Active === 1) {
                activeGroupIds.push(group.Group.Id);
            }
        });
        if (this.selUser != null) {
            this.svcAPI.portalSetUserUserGroups(this.selUser, activeGroupIds)
                .pipe(catchError((error) => {
                    return of(null); // Return empty message
                }))
                .subscribe(
                    response => {
                        if (response == null) {

                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Failed to change groups for user, check database.',
                                timeout: 2000,
                                customClass: 'snackBarError',
                                action: {
                                    text: 'X'
                                }
                            });

                            this.ngOnInit();
                        } else {

                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Saved',
                                timeout: 2000,
                                customClass: 'snackBarSucces',
                                action: {
                                    text: 'X'
                                }
                            });

                        }
                    });
        }
    }
}
