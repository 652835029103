<div class="col-md-12 paddingTop" style="text-align: right; padding-bottom: 5px">
    <button class="btn btn-primary addButtonTypeDetail" type="button" (click)="btnToggleActionClicked()">
        <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
    </button>
</div>
<div class="col-12 paddingBottomTable">
    <div class="card">
        <div class="table-responsive" style="overflow: inherit">
            <table class="table table-hover p-3 mb-5 tableLikeCard">
                <thead class="text-primary-inverse">
                    <tr>
                        <th class="col-1">Mode</th>
                        <th class="col-2">Name</th>
                        <th class="col-2">Action</th>
                        <th class="col-2">Description</th>
                        <th class="col-1 centerText">Status</th>
                        <th class="col-2">Last changed on</th>
                        <th class="col-1 centerText">Settings</th>
                        <th class="col-1"></th>
                    </tr>
                </thead>
                <tbody class="pointer">
                    <tr>
                        <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstAlertingSchedulers.length == 0">
                            No schedulers found.
                        </td>
                        <td colspan="12" *ngIf="isLoading">
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                    Loading schedulers
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr id="lastTR" *ngIf="addSchedulerEnabled">
                        <td class="col-1">
                            <!-- <input #inputNewEndpointEmail class="form-control" [(ngModel)]="strNewStatusCode" placeholder="255" /> -->
                        </td>
                        <td class="col-2">
                            <input #inputNewEndpointEmail class="form-control" [(ngModel)]="strNewName" placeholder="Name" />
                        </td>
                        <td class="col-2">
                            <ng-select placeholder="Select action" [items]="lstActionsNames" [multiple]="false" [clearable]="true" bindLabel="Action" [(ngModel)]="selNewAction"></ng-select>
                        </td>
                        <td class="col-2">
                            <input #inputNewEndpointEmail class="form-control" [(ngModel)]="strNewDescription" placeholder="Description" />
                        </td>
                        <td class="col-1">
                        </td>
                        <td class="col-2">
                        </td>
                        <td class="col-1">
                        </td>
                        <td class="smallTD-6 centerText col-1">
                            <span (click)="btnAddSchedulerClicked()" class="hoverTrashIcon">
                                <i-feather name="plus-circle"></i-feather>
                            </span>
                        </td>
                    </tr>

                    <!-- ROW EDIT -->
                    <tr *ngFor="let theScheduler of lstAlertingSchedulers; let i = index" [class.active]="i == selectedRow" (click)="schedulerClicked(theScheduler)">
                        <ng-container *ngIf="!isLoading">
                            <!--Status-->
                            <td class="col-1" (click)="statusClicked(theScheduler)">
                                <span tooltip="Disabled" *ngIf="theScheduler.Mode == false">
                                <i-feather name="square" id="lastCheckMark" class="pointer" *ngIf="theScheduler.Mode == false"></i-feather>
                                </span>
                                <span tooltip="Enabled" *ngIf="theScheduler.Mode == true">
                                <i-feather name="check-square" id="lastCheckMark" class="pointer" *ngIf="theScheduler.Mode == true"></i-feather>
                            </span>
                            </td>
                            <!--Name-->
                            <td class="col-2" (click)="schedulerClicked(i, 'Name', theScheduler)">
                                <span *ngIf="selNameId != theScheduler.Id">{{ theScheduler.Name }}</span>
                                <input class="form-control" *ngIf="selNameId == theScheduler.Id" [(ngModel)]="theScheduler.Name" (input)="schedulerChanged(theScheduler)" />
                            </td>
                            <!--Action-->
                            <td class="col-2" (click)="schedulerClicked(i, 'Action', theScheduler)">
                                <span *ngIf="selActionId != theScheduler.Id">{{ theScheduler.Action.Name }}</span>
                                <ng-select placeholder="Select action" [multiple]="false" [clearable]="false" bindLabel="Action" [(ngModel)]="theScheduler.Action" *ngIf="selActionId == theScheduler.Id" (change)="schedulerChanged(theScheduler)">
                                    <ng-option *ngFor="let action of lstActions" [value]="action">{{action.Name}}</ng-option>
                                </ng-select>
                            </td>
                            <!--Description-->
                            <td class="col-2" (click)="schedulerClicked(i, 'Description', theScheduler)">
                                <span *ngIf="selDescriptionId != theScheduler.Id">{{ theScheduler.Description }}</span>
                                <input class="form-control" *ngIf="selDescriptionId == theScheduler.Id" [(ngModel)]="theScheduler.Description" (input)="schedulerChanged(theScheduler)" />
                            </td>
                            <!--State-->
                            <td class="col-1 centerText">
                                <svg *ngIf="theScheduler.Status == 0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" /></svg>
                                <svg *ngIf="theScheduler.Status > 0 && theScheduler.Status < 255" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" /></svg>
                                <svg *ngIf="theScheduler.Status == 255" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" /></svg>
                            </td>
                            <!--CreationDate-->
                            <td class="col-2">
                                <span>{{this.datepipe.transform(theScheduler.LastChangedOn, 'dd/MM/yyyy - HH:mm:ss')}}</span>
                            </td>
                            <!--Settings-->
                            <td class="smallTD-6 centerText">
                                <span (click)="openDetail(theScheduler)" class="hoverEditIcon"><i-feather name="settings"></i-feather></span>
                            </td>
                            <td class="smallTD-6 centerText">
                                <span (click)="removeScheduler(theScheduler)" class="hoverTrashIcon">
                                    <i-feather name="trash"></i-feather>
                                </span>
                            </td>
                        </ng-container>
                    </tr>

                    <!-- POPUP -->
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-tnt-configuration_alerting_schedulers_detail></app-tnt-configuration_alerting_schedulers_detail>