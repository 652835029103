import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService, TnTAPIService } from '../_services';

import { OAuthService } from 'angular-oauth2-oidc';
import pkg from 'package.json'

@Component({
    selector: 'app-tnt-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {
    public isCollapsed: boolean = true;
    public userName: string = '';
    public showAdminLinks: boolean = false;
    public dIMVersion: string = pkg.version;

    constructor(public svcConfiguration: ConfigurationService,
        private svcRouter: Router,
        public oauthService: OAuthService,
        private svcAPI: TnTAPIService) {
    }

    ngOnInit() {
        // If the user has valid tokens stored, use them
        if (this.oauthService.hasValidIdToken()) {
            this.setupUserDetails();
        }

        // Listen for new credentials
        this.oauthService.events.subscribe(e => {
            if (e.type === 'token_received' || e.type === 'token_refreshed') {
                this.setupUserDetails();
            }
        });
    }

    private setupUserDetails() {
        // Set username
        const claims = this.oauthService.getIdentityClaims();
        if (claims) {
            this.userName = claims['name'];
            // Retrieve list of parties
            this.svcAPI.getUserInfo().subscribe(
                userinfo => {
                    this.showAdminLinks = userinfo.IsAdmin;
                }
            );
        }
    }

    public logoutClicked() {
        this.oauthService.logOut();
    }
}
