import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../_services/';

@Component({
    styleUrls: ['../configuration.component.scss', './configuration_reprocessing.component.scss'],
    templateUrl: './configuration_reprocessing.component.html'
})

export class ConfigurationReprocessingComponent {
    constructor(private svcAPI: TnTAPIService, private svcRouter: Router) { }
}

