import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TnTAPIService } from "../../../_services";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { SnackbarService } from "ngx-snackbar";
import { ConfigurationAlertingConditionsDetailComponent } from "./detail";

@Component({
    styleUrls: [
        "../../configuration.component.scss",
        "./configuration_alerting_conditions.component.scss",
    ],
    templateUrl: "./configuration_alerting_conditions.component.html",
})
export class ConfigurationAlertingConditionsComponent implements OnInit {
    // Timer
    private timer;

    // Loader
    public isLoading: boolean;
    public selectedRow: Number;

    public selName: TnTAlertingCondition;
    public selNameId: number = -1;

    public selType: TnTAlertingCondition;
    public selTypeId: number = -1;

    public selDescription: TnTAlertingCondition;
    public selDescriptionId: number = -1;

    public selStatusCode: TnTAlertingCondition;
    public selStatusCodeId: number = -1;

    public selNumberOfMessages: TnTAlertingCondition;
    public selNumberOfMessagesId: number = -1;

    public strPrevName: string = "";

    public strNewConditionName: string = null;
    public selNewConditionType: string = null;
    public strNewConditionDescription: string = null;
    public strNewConditionStatusCode: string = null;
    public strNewConditionNumberOfMessages: string = null;

    public lstTypes: Array<string> = [];
    public lstAlertingConditions: Array<TnTAlertingCondition> = [];

    public addConditionEnabled: boolean = false;
    public selectedType: number;

    // Detail modal
    @ViewChild(ConfigurationAlertingConditionsDetailComponent, { static: true })
    detailModal: ConfigurationAlertingConditionsDetailComponent;
    constructor(
        private svcAPI: TnTAPIService,
        private svcRouter: Router,
        private snackbarService: SnackbarService
    ) {}

    public ngOnInit() {
        this.isLoading = true;
        this.getAlertingConditions();
        this.getTypes();
        this.isLoading = false;
    }

    ngAfterViewInit(): void {}

    // Show detail modal
    public openDetail(theCondition: TnTAlertingCondition) {
        this.selectedType = theCondition.Id;
        console.log(this.detailModal);
        this.detailModal.showChildModal(theCondition);
    }

    getTypes() {
        this.lstTypes = ["Status", "Event"];
    }

    btnToggleConditionClicked(): void {
        this.addConditionEnabled = !this.addConditionEnabled;
    }

    getAlertingConditions() {
        this.svcAPI
            .getAlertingConditions()
            .pipe(
                catchError((error) => {
                    return of([]); // Return empty message set
                })
            )
            .subscribe((conditions) => {
                this.lstAlertingConditions = conditions;
            });
    }

    private charValidation(type: string): boolean{
        var chars = "";

        switch (type) {
            // Status
            case "Status":
                chars = "-;0123456789"
                for (let i = 0; i < this.strNewConditionStatusCode.length; i++) {
                    if(!chars.includes(this.strNewConditionStatusCode[i])) {
                        return false;
                    }                       
                 }
                 return true;
        
            // Event
            case "Event":
                chars = "<>=;0123456789";
                for (let i = 0; i < this.strNewConditionNumberOfMessages.length; i++) {
                    if(!chars.includes(this.strNewConditionNumberOfMessages[i])) {
                        return false;
                    }                       
                 }
                 return true;
            default:
                break;
        }
    }

    public showLoadingIcon: boolean = false;
    async btnAddConditionClicked() {
        if (this.strNewConditionName) {
            if (!this.lstTypes.includes(this.selNewConditionType)) {
                const _this = this;
                this.snackbarService.add({
                    msg: "Please select a type",
                    timeout: 2000,
                    customClass: "snackBarError",
                    action: {
                        text: "X",
                    },
                });
            }
            else if ((this.strNewConditionStatusCode == null || this.strNewConditionStatusCode == "") && (this.strNewConditionNumberOfMessages == null || this.strNewConditionNumberOfMessages == "")) {
                if (this.selNewConditionType == "Status") {
                    this.snackbarService.add({
                        msg: "Please fill in 'Status code'",
                        timeout: 2000,
                        customClass: "snackBarError",
                        action: {
                            text: "X",
                        },
                    });
                }
                else {
                    this.snackbarService.add({
                        msg: "Please fill in 'Number of Messages'",
                        timeout: 2000,
                        customClass: "snackBarError",
                        action: {
                            text: "X",
                        },
                    });
                }
            }
            else if (this.charValidation(this.selNewConditionType) == false) {
                if (this.selNewConditionType == "Status") {
                    this.snackbarService.add({
                        msg: "Invalid input for Status code",
                        timeout: 2000,
                        customClass: "snackBarError",
                        action: {
                            text: "X",
                        },
                    });
                }
                else {
                    this.snackbarService.add({
                        msg: "Invalid input for Number of Messages",
                        timeout: 2000,
                        customClass: "snackBarError",
                        action: {
                            text: "X",
                        },
                    });
                }
            } 
            else {
                this.showLoadingIcon = true; 
                const theCondition: TnTAlertingCondition = <
                    TnTAlertingCondition
                >{
                    Name: this.strNewConditionName
                        ? this.strNewConditionName.trim()
                        : null,
                    Type: this.selNewConditionType
                        ? this.selNewConditionType.trim()
                        : null,
                    Description: this.strNewConditionDescription
                        ? this.strNewConditionDescription.trim()
                        : null,
                    StatusCode: this.strNewConditionStatusCode
                        ? this.strNewConditionStatusCode.trim()
                        : null,
                    NumberOfMessages: this.strNewConditionNumberOfMessages
                        ? this.strNewConditionNumberOfMessages.trim()
                        : null,
                };

                // TO DO SEND TO BACKEND

                await this.svcAPI
                    .AddAlertingCondition(theCondition)
                    .pipe(
                        catchError((error) => {
                            this.showLoadingIcon = false;
                            return "Error";
                        })
                    )
                    .subscribe((res) => {
                        if (res["Message"] == "Success") {
                            const _this = this;
                            console.log(res);
                            this.snackbarService.add({
                                msg: "Saved",
                                timeout: 2000,
                                customClass: "snackBarSucces",
                                action: {
                                    text: "X",
                                },
                            });
                            this.showLoadingIcon = false;
                            this.addConditionEnabled = false;
                            this.lstAlertingConditions.push(res["Value"]);
                            this.strNewConditionName = null;
                            this.selNewConditionType = null;
                            this.strNewConditionDescription = null;
                            this.strNewConditionStatusCode = null;
                            this.strNewConditionNumberOfMessages = null;
                        } else {
                            const _this = this;
                            this.snackbarService.add({
                                msg: res["Message"],
                                timeout: 2000,
                                customClass: "snackBarError",
                                action: {
                                    text: "X",
                                },
                            });
                            this.showLoadingIcon = false;
                        }
                    })
            }
        } else {
            const _this = this;
            this.snackbarService.add({
                msg: "Please fill in name and URL",
                timeout: 2000,
                customClass: "snackBarError",
                action: {
                    text: "X",
                },
            });
        }
    }

    conditionClicked(i: number, field: String, clicked?: TnTAlertingCondition) {
        this.selectedRow = i;

        if (field === "Name") {
            this.lstAlertingConditions.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = clicked;
                    this.selNameId = clicked.Id;
                    this.selType = null;
                    this.selTypeId = -1;
                    this.selDescription = null;
                    this.selDescriptionId = -1;
                    this.selStatusCode = null;
                    this.selStatusCodeId = -1;
                    this.selNumberOfMessages = null;
                    this.selNumberOfMessagesId = -1;
                }
            });
        }
        if (field === "Type") {
            this.lstAlertingConditions.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = null;
                    this.selNameId = -1;
                    this.selType = clicked;
                    this.selTypeId = clicked.Id;
                    this.selDescription = null;
                    this.selDescriptionId = -1;
                    this.selStatusCode = null;
                    this.selStatusCodeId = -1;
                    this.selNumberOfMessages = null;
                    this.selNumberOfMessagesId = -1;
                }
            });
        }
        if (field === "Description") {
            this.lstAlertingConditions.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = null;
                    this.selNameId = -1;
                    this.selType = null;
                    this.selTypeId = -1;
                    this.selDescription = clicked;
                    this.selDescriptionId = clicked.Id;
                    this.selStatusCode = null;
                    this.selStatusCodeId = -1;
                    this.selNumberOfMessages = null;
                    this.selNumberOfMessagesId = -1;
                }
            });
        }
        if (field === "StatusCode") {
            this.lstAlertingConditions.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = null;
                    this.selNameId = -1;
                    this.selType = null;
                    this.selTypeId = -1;
                    this.selDescription = null;
                    this.selDescriptionId = -1;
                    this.selStatusCode = clicked;
                    this.selStatusCodeId = clicked.Id;
                    this.selNumberOfMessages = null;
                    this.selNumberOfMessagesId = -1;
                }
            });
        }
        if (field === "NumberOfMessages") {
            this.lstAlertingConditions.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = null;
                    this.selNameId = -1;
                    this.selType = null;
                    this.selTypeId = -1;
                    this.selDescription = null;
                    this.selDescriptionId = -1;
                    this.selStatusCode = null;
                    this.selStatusCodeId = -1;
                    this.selNumberOfMessages = clicked;
                    this.selNumberOfMessagesId = clicked.Id;
                }
            });
        }
    }

    conditionChanged(mt: TnTAlertingCondition): void {
        if (mt != null) {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.svcAPI.modifyAlertingCondition(mt).subscribe((result) => {
                    if (result === "Success") {
                        const _this = this;
                        this.snackbarService.add({
                            msg: "Saved",
                            timeout: 2000,
                            customClass: "snackBarSucces",
                            action: {
                                text: "X",
                            },
                        });
                    } else {
                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: "snackBarError",
                            action: {
                                text: "X",
                            },
                        });
                    }
                });

                clearInterval(this.timer);
            }, 800);
        }
    }

    removeCondition(theCondition: TnTAlertingCondition) {
        const _this = this;
        this.svcAPI
            .DeleteAlertingCondition(theCondition)
            .pipe(
                catchError((error) => {
                    return "Error";
                })
            )
            .subscribe((res) => {
                const _this = this;
                if (res == "Success") {
                    const _this = this;
                    const index =
                        this.lstAlertingConditions.indexOf(theCondition);
                    if (index > -1) {
                        this.lstAlertingConditions.splice(index, 1);
                    }
                    this.snackbarService.add({
                        msg: "Deleted",
                        timeout: 2000,
                        customClass: "snackBarSucces",
                        action: {
                            text: "X",
                        },
                    });
                } else {
                    const _this = this;
                    this.snackbarService.add({
                        msg: res,
                        timeout: 2000,
                        customClass: "snackBarError",
                        action: {
                            text: "X",
                        },
                    });
                }
            });
    }
}
