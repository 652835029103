import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TnTAPIService } from "../../../_services";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { SnackbarService } from "ngx-snackbar";
import { generateTypesForGlobal } from "typescript";
import { ConfigurationAlertingSubscriptionsDetailComponent } from "./detail";
import { DatePipe } from "@angular/common";

@Component({
    styleUrls: [
        "../../configuration.component.scss",
        "./configuration_alerting_subscriptions.component.scss",
    ],
    templateUrl: "./configuration_alerting_subscriptions.component.html",
})
export class ConfigurationAlertingSubscriptionsComponent implements OnInit {
    // Timer
    private timer;

    // Loader
    public isLoading: boolean;
    public selectedRow: Number;

    public selName: TnTAlertingSubscription;
    public selNameId: number = -1;

    public selType: TnTAlertingSubscription;
    public selTypeId: number = -1;

    public selDescription: TnTAlertingSubscription;
    public selDescriptionId: number = -1;

    public selUrl: TnTAlertingSubscription;
    public selUrlId: number = -1;


    public strPrevName: string = "";

    public strNewTypeName: string = "";
    public selNewType: string = "";
    public strNewTypeDescription: string = "";
    public strNewTypeUrl: string = "";

    public lstTypes: Array<string> = [];
    public lstAlertingSubscriptions: Array<TnTAlertingSubscription> = [];

    public AddTypeEnabled: boolean = false;
    public selectedType: number;

    // Detail modal
    @ViewChild(ConfigurationAlertingSubscriptionsDetailComponent, { static: true })
    detailModal: ConfigurationAlertingSubscriptionsDetailComponent;

    constructor(
        private svcAPI: TnTAPIService,
        private svcRouter: Router,
        private snackbarService: SnackbarService
    ) {}

    ngOnInit() {
        this.isLoading = true;
        this.getTypes();
        this.getAlertingSubscriptions();
        this.isLoading = false;
    }

    ngAfterViewInit(): void {}

    // Show detail modal
    public openDetail(theType: TnTAlertingSubscription) {
        this.selectedType = theType.Id;
        this.detailModal.showChildModal(theType);
    }

    // btnAddTypeClicked(){
    //     if (this.strNewTypeName && this.strNewTypeUrl) {
    //         if (this.selNewType == null) {
    //             const _this = this;
    //             this.snackbarService.add({
    //                 msg: 'Please select a type',
    //                 timeout: 2000,
    //                 customClass: 'snackBarError',
    //                 action: {
    //                     text: 'X'
    //                 }
    //             });

    //         } else {
    //             const theType: TnTAlertingSubscription = <TnTAlertingSubscription>{
    //                 Name: this.strNewTypeName.trim(),
    //                 Type: this.selNewType.trim(),
    //                 Description: this.strNewTypeDescription?this.strNewTypeDescription.trim():null,
    //                 Url: this.strNewTypeUrl.trim(),
    //             };

    //             // TO DO SEND TO BACKEND
    //             console.log(theType)
    //             this.lstAlertingSubscriptions.push(theType)
    //         }
    //     } else {
    //         const _this = this;
    //         this.snackbarService.add({
    //             msg: 'Please fill in name and URL',
    //             timeout: 2000,
    //             customClass: 'snackBarError',
    //             action: {
    //                 text: 'X'
    //             }
    //         });

    //     }
    // }

    getTypes() {
        this.lstTypes = ["Email", "Webhook"];
    }

    btnAddTypeEnabledClicked(): void {
        this.AddTypeEnabled = !this.AddTypeEnabled;
    }

    getAlertingSubscriptions() {
        this.svcAPI
            .getAlertingSubscriptions()
            .pipe(
                catchError((error) => {
                    return of([]); // Return empty message set
                })
            )
            .subscribe((types) => {
                this.lstAlertingSubscriptions = types;
            });
    }

    typeClicked(i: number, field: String, clicked?: TnTAlertingSubscription) {
        this.selectedRow = i;

        if (field === "Description") {
            this.lstAlertingSubscriptions.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = null;
                    this.selNameId = -1;
                    this.selType = null;
                    this.selTypeId = -1;
                    this.selDescription = clicked;
                    this.selDescriptionId = clicked.Id;
                    this.selUrl = null;
                    this.selUrlId = -1;
                }
            });
        }
    }

    descriptionChanged(mt: TnTAlertingSubscription): void {
        if (mt != null) {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.svcAPI.modifyAlertingSubscriptions(mt).subscribe((result) => {
                    console.log(result);
                    if (result === "Success") {
                        const _this = this;
                        this.snackbarService.add({
                            msg: "Saved",
                            timeout: 2000,
                            customClass: "snackBarSucces",
                            action: {
                                text: "X",
                            },
                        });
                    } else {
                        //mt.Name = this.strPrevName;

                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: "snackBarError",
                            action: {
                                text: "X",
                            },
                        });
                    }
                });

                clearInterval(this.timer);
            }, 800);
        }
    }
}
