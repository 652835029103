<div class="container-fluid paddingTop">
    <h1 class="display-4 display">User administration</h1>
</div>
<div class="row paddingUPContent">
    <div class="col-md-4">
        <div class="card">
            <div class="card-header card-header-groups text-primary-inverse">
                Users
            </div>
            <div class="card-body nopadding scrollCardNoFooter pointer">
                <ul class="list-group">
                    <li *ngFor="let theUser of lstUsers; let idx = index" (click)="userClicked(idx)" [class.active]="theUser.Id == selUserId" class="list-group-item list-group-item-action">
                        {{theUser.Name}}
                        <span class="right" (click)="removeUser()"><i-feather name="trash" *ngIf="selUserId == theUser.Id && (theUser.ADUser | lowercase) != (thisUser | lowercase)"></i-feather></span>
                    </li>
                </ul>
            </div>

        </div>
    </div>
    <div class="col-md-4" *ngIf="selUser != null">
        <div class="card">
            <div class="card-header bg-primary text-primary-inverse">
                <strong>{{selUser.Name}}: </strong>Groups
            </div>
            <div class="card-body nopadding scrollCardNoFooter pointer">
                <ul class="list-group">
                    <li class="list-group-item list-group-item-action" *ngFor="let group of lstUserUserGroups" [value]="group.Group.Id" (click)="groupClicked(group)">
                        <span>
                            <i-feather name="square" *ngIf="group.Active == 0"></i-feather>
                            <i-feather name="check-square" *ngIf="group.Active != 0"></i-feather>
                            {{group.Group.Groupname}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="col-md-8 aligner" *ngIf="selUser == null">
        <h1 class="display-4 display aligner-item">Select a user to edit</h1>
    </div>
</div>
