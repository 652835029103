import { Component } from '@angular/core';

@Component({
  selector: 'app-logout',
  styles: [`
      h2 {
          text-align: center;
          padding-bottom: 50px;
      }
      .paddingTop {
          padding-left: 2em;
          padding-right: 2em;
          padding-top: 1em;
      }
      `],
  template: `
    <div class="container-fluid paddingTop">
      <div class="row">
        <h1 class="display-4 display">Logout</h1>
      </div>
      <div class="row">
        <p>
          You were logged out. Click <a routerLink="">here</a> to return to the homepage (and login again).
        </p>
      </div>
    </div>
  `
})
export class LogoutComponent { }
