import { Component, OnInit, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../_services/';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as MimeTypes from './../MimeTypes';
import { SnackbarService } from 'ngx-snackbar';


@Component({
    styleUrls: ['../configuration.component.scss', './configuration_messageTypes.component.scss'],
    templateUrl: './configuration_messageTypes.component.html',
})

export class ConfigurationMessageTypesComponent implements OnInit, AfterViewInit {

    // Loader
    public isLoading: boolean;
    selectedRow: Number;

    // Lists
    public lstMessageTypes: Array<any> = [];
    public lstMessageTypesBusinessNames: Array<any> = [];
    public lstExtensions: Array<string> = MimeTypes.MimeTypes;

    public lstPurgingCustom: Array<TnTPurgingCustom> = [];
    public purgingDefault: TnTPurgingDefault;


    // MessageTypes vars
    public selMessageType: TnTMessageType;
    public selMessageTypeId: number = -1;

    public selMessageTypeDr: TnTMessageType;
    public selMessageTypeDrId: number = -1;

    public selMessageTypeBn: TnTMessageType;
    public selMessageTypeBnId: number = -1;

    public selMessageTypeExt: TnTMessageType;
    public selMessageTypeExtId: number = -1;

    public strPrevMessageType: string = '';

    public selNewMessageTypeBn: TnTMessageType;
    public selNewMessageTypeExt: string = '';
    public strNewMessageTypeDr: string = '';
    public strNewMessageType: string = '';
    public blnNewMessageTypeSearchable: boolean = false;
    public blnSubmitAllowed: boolean = false;



    // Filter vars
    public selMessageTypeBnFilter: TnTMessageTypeBusinessName = null;
    public blnSearchMessageTypeBusinessNameApplied = false;

    public srcMessageType: string = '';
    public blnSearchMessageTypeApplied = false;

    @ViewChild('inputMessageType', { static: false }) inputMessageType: any;
    @ViewChild('inputMessageTypeDr', { static: false }) inputMessageTypeDr: any;
    @ViewChild('inputMessageTypeSearch', { static: false }) inputMessageTypeSearch: any;

    // Timer
    private timer;

    // Add
    public AddMessageTypeEnabled: boolean = false;

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private snackbarService: SnackbarService) { }

    public ngOnInit() {
        this.isLoading = true;
        this.getMessageTypes();
        this.getBusinessNames();
        this.getPurgingValues();
        this.isLoading = false;
    }

    ngAfterViewInit(): void {

    }

    public getRetentionFE(BNId: number): string {
        let result = this.lstPurgingCustom.find(element => element.BusinessNameId === BNId);
        if (result == null) {
            return (String(this.purgingDefault.DefaultDaysToKeep + " days (default)"));
        }
        else {
            return (String(result.RetentionValue) + " " + result.RetentionUnit);
        }
        
    }


    public getPurgingValues(): void {
        this.svcAPI.getAllPurgingCustoms().subscribe(
            pc => {
                this.lstPurgingCustom = pc;
            }
        );
        this.svcAPI.getPurgingDefault().subscribe(
            pd => {
                this.purgingDefault = pd[0];
            }
        );
    }

    public getBusinessNames(): void {
        this.svcAPI.getMessageTypesBusinessNames().subscribe(
            bn => {
                this.lstMessageTypesBusinessNames = bn;
            }
        );
    }

    public getMessageTypes(): void {
        this.svcAPI.getMessageTypesUserPortal(false, this.srcMessageType, this.selMessageTypeBnFilter)
            .pipe(catchError((error) => {
                return of([]); // Return empty message set
            }))
            .subscribe(
                messagetypes => {
                    this.lstMessageTypes = messagetypes;
                }
            );
        this.blnSearchMessageTypeApplied = this.srcMessageType !== '';
        this.blnSearchMessageTypeBusinessNameApplied = this.selMessageTypeBnFilter !== null;
    }

    // Clicked functions
    public messageTypeClicked(i: number, field: String, clicked?: TnTMessageType) {
        this.selectedRow = i;
        if (field === 'MessageType') { // MessageType technical name
            this.lstMessageTypes.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selMessageType = clicked;
                    this.selMessageTypeId = clicked.Id;
                    this.selMessageTypeDr = null;
                    this.selMessageTypeDrId = -1;
                    this.selMessageTypeBn = null;
                    this.selMessageTypeBnId = -1;
                    this.selMessageTypeExt = null;
                    this.selMessageTypeExtId = -1;
                    this.strPrevMessageType = clicked.MessageType;
                    setTimeout(() => {
                        this.inputMessageType.nativeElement.focus();
                    }, 10);
                }
            });
        }

        if (field === 'BusinessName') { // BusinessName
            this.lstMessageTypes.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selMessageTypeBn = clicked;
                    this.selMessageTypeBnId = clicked.Id;
                    this.selMessageTypeDr = null;
                    this.selMessageTypeDrId = -1;
                    this.selMessageType = null;
                    this.selMessageTypeId = -1;
                    this.selMessageTypeExt = null;
                    this.selMessageTypeExtId = -1;
                }
            });
        }

        if (field === 'DownloadRef') { // DownloadReference
            this.lstMessageTypes.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selMessageType = null;
                    this.selMessageTypeId = -1;
                    this.selMessageTypeDr = clicked;
                    this.selMessageTypeDrId = clicked.Id;
                    this.selMessageTypeBn = null;
                    this.selMessageTypeBnId = -1;
                    this.selMessageTypeExt = null;
                    this.selMessageTypeExtId = -1;
                    this.strPrevMessageType = clicked.MessageType;
                    setTimeout(() => {
                        this.inputMessageTypeDr.nativeElement.focus();
                    }, 10);
                }
            });
        }

        if (field === 'Extension') { // Extension
            this.lstMessageTypes.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selMessageType = null;
                    this.selMessageTypeId = -1;
                    this.selMessageTypeDr = null;
                    this.selMessageTypeDrId = -1;
                    this.selMessageTypeBn = null;
                    this.selMessageTypeBnId = -1;
                    this.selMessageTypeExt = clicked;
                    this.selMessageTypeExtId = clicked.Id;
                }
            });
        }
    }

    public searchableClicked(mt: TnTMessageType): void {
        if (mt.Searchable) {
            mt.Searchable = false;
        } else {
            mt.Searchable = true;
        }
        this.svcAPI.changeMessageTypes(mt).subscribe(
            result => {
                if (result === 'Succes' || result === 'MTNOTUNIQUE') {

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Saved',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });
                    this.getMessageTypes();

                } else {

                    const _this = this;
                    this.snackbarService.add({
                        msg: result,
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });

                }
            }
        );
    }

    // Changed functions
    public mtChanged(mt: TnTMessageType): void {
        if (mt != null) {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.svcAPI.changeMessageTypes(mt).subscribe(
                    result => {
                        if (result === 'Succes') {

                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Saved',
                                timeout: 2000,
                                customClass: 'snackBarSucces',
                                action: {
                                    text: 'X'
                                }
                            });

                        } else {
                            mt.MessageType = this.strPrevMessageType;

                            const _this = this;
                            this.snackbarService.add({
                                msg: result,
                                timeout: 2000,
                                customClass: 'snackBarError',
                                action: {
                                    text: 'X'
                                }
                            });

                        }
                    }
                );
                clearInterval(this.timer);
            }, 800);
        }
    }

    public bnMtChanged(mt: TnTMessageType): void {
        if (this.selMessageTypeBn != null) {
            mt.BusinessNameId = this.selMessageTypeBn.Id;
            mt.BusinessName = this.selMessageTypeBn.BusinessName;
            this.svcAPI.changeMessageTypes(mt).subscribe(
                result => {
                    if (result === 'Succes' || result === 'MTNOTUNIQUE') {

                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Saved',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });
                        this.getMessageTypes();

                    } else {
                        mt.MessageType = this.strPrevMessageType;

                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: 'snackBarError',
                            action: {
                                text: 'X'
                            }
                        });

                    }
                }
            );
        }
    }

    public drMtChanged(mt: TnTMessageType): void {
        if (mt != null) {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.svcAPI.changeMessageTypes(mt).subscribe(
                    result => {
                        if (result === 'Succes' || result === 'MTNOTUNIQUE') {

                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Saved',
                                timeout: 2000,
                                customClass: 'snackBarSucces',
                                action: {
                                    text: 'X'
                                }
                            });
                            this.getMessageTypes();

                        } else {
                            mt.MessageType = this.strPrevMessageType;
                            const _this = this;
                            this.snackbarService.add({
                                msg: result,
                                timeout: 2000,
                                customClass: 'snackBarError',
                                action: {
                                    text: 'X'
                                }
                            });

                        }
                    }
                );
                clearInterval(this.timer);
            }, 800);
        }
    }

    public extMtChanged(mt: TnTMessageType): void {
        if (this.selMessageTypeExt != null) {
            this.svcAPI.changeMessageTypes(mt).subscribe(
                result => {
                    if (result === 'Succes' || result === 'MTNOTUNIQUE') {

                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Saved',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });
                        this.getMessageTypes();

                    } else {

                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: 'snackBarError',
                            action: {
                                text: 'X'
                            }
                        });

                    }
                }
            );
        }
    }

    // Add MessageType
    public searchableNewMTClicked(): void {
        if (this.blnNewMessageTypeSearchable) {
            this.blnNewMessageTypeSearchable = false;
        } else {
            this.blnNewMessageTypeSearchable = true;
        }
    }

    public btnAddMessageTypeClicked(): void {

        if (this.strNewMessageType) {
            if (this.strNewMessageTypeDr != null) {
                this.strNewMessageTypeDr = this.strNewMessageTypeDr.trim();
            }

            if (this.selNewMessageTypeBn == null) {

                const _this = this;
                this.snackbarService.add({
                    msg: 'Please select or create a businessname for the messagetype',
                    timeout: 2000,
                    customClass: 'snackBarError',
                    action: {
                        text: 'X'
                    }
                });

            } else {
                const theMessageType: TnTMessageType = <TnTMessageType>{
                    Id: -1,
                    MessageType: this.strNewMessageType,
                    Searchable: this.blnNewMessageTypeSearchable,
                    BusinessNameId: this.selNewMessageTypeBn.Id,
                    BusinessName: this.selNewMessageTypeBn.BusinessName,
                    ReferenceKey: this.strNewMessageTypeDr,
                    Extension: this.selNewMessageTypeExt
                };

                this.svcAPI.portalAddMessageType(theMessageType).subscribe(
                    result => {
                        if (result === 'Succes') {
                            this.getMessageTypes();
                            this.blnNewMessageTypeSearchable = false;
                            this.strNewMessageType = null;
                            this.strNewMessageTypeDr = null;
                            this.selNewMessageTypeBn = null;
                            this.selNewMessageTypeExt = null;

                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Added',
                                timeout: 2000,
                                customClass: 'snackBarSucces',
                                action: {
                                    text: 'X'
                                }
                            });

                        } else {

                            const _this = this;
                            this.snackbarService.add({
                                msg: result,
                                timeout: 2000,
                                customClass: 'snackBarError',
                                action: {
                                    text: 'X'
                                }
                            });

                        }
                    }
                );
            }
        } else {
            const _this = this;
            this.snackbarService.add({
                msg: 'Please fill in MessageType name',
                timeout: 2000,
                customClass: 'snackBarError',
                action: {
                    text: 'X'
                }
            });

        }
    }

    public removeMessageType(theMessageType: TnTMessageType): void {
        this.svcAPI.portalRemoveMessageType(theMessageType).subscribe(
            result => {
                if (result === 'Succes') {

                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Removed',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });

                    this.getMessageTypes();
                } else {

                    const _this = this;
                    this.snackbarService.add({
                        msg: result,
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });

                    this.selMessageType = theMessageType;
                    this.selMessageTypeId = theMessageType.Id;
                }
            }
        );
    }

    public applySearchMessagetype(): void {
        this.getMessageTypes();
    }

    public clearSearchMessagetype(): void {
        this.srcMessageType = '';
        this.getMessageTypes();
    }

    public focusInputSearchMessageType(): void {
        setTimeout(() => {
            this.inputMessageTypeSearch.nativeElement.focus();
        }, 10);
    }

    public clearSearchMessagetypeBusinessName(): void {
        this.selMessageTypeBnFilter = null;
        this.getMessageTypes();
    }

    public btnAddMessageTypeEnabledClicked(): void {
        this.AddMessageTypeEnabled = !this.AddMessageTypeEnabled;
    }
}
