import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../../_services';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SnackbarService } from 'ngx-snackbar';
import { ConfigurationAlertingSchedulerDetailComponent } from './detail';
import { DatePipe } from '@angular/common';


@Component({
    styleUrls: ['../../configuration.component.scss','./configuration_alerting_scheduler.component.scss'],
    templateUrl: './configuration_alerting_scheduler.component.html',
})
export class ConfigurationAlertingSchedulerComponent implements OnInit {
    // Detail modal
    @ViewChild(ConfigurationAlertingSchedulerDetailComponent, { static: true }) detailModal: ConfigurationAlertingSchedulerDetailComponent;
    public lstAlertingSchedulers: Array<TnTAlertingScheduler> = [];

    // Timer
    private timer;

    public isLoading: boolean;
    public addSchedulerEnabled: boolean = false;
    public selectedRow: Number;
    public selectedType: number;

    public selStatus: TnTAlertingScheduler;
    public selStatusId: number = -1;

    public selName: TnTAlertingScheduler;
    public selNameId: number = -1;

    public selAction: TnTAlertingScheduler;
    public selActionId: number = -1;

    public selDescription: TnTAlertingScheduler;
    public selDescriptionId: number = -1;

    public selTemplate: TnTAlertingScheduler;
    public selTemplateId: number = -1;



    public strNewName: string = null;
    public selNewAction: string = null;
    public strNewDescription: string = null;



    public lstActions: Array<TnTAlertingAction> = []
    public lstActionsNames: Array<string> = []


    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private snackbarService: SnackbarService, public datepipe: DatePipe) { }

    public ngOnInit() {
        this.isLoading = true;
        this.getAlertingSchedulers();
        this.getActions();
        this.isLoading = false;
    }

    openUrl(jobId: string){
        window.open(window.location.origin + '/hangfire/jobs/details/' + jobId,'_blank')
    }
    
    getActions() {
        this.svcAPI.getAlertingActions()
        .pipe(catchError((error) => {
            return of([]); // Return empty message set
        }))
        .subscribe(
            actions => {
                actions.forEach(action => {
                    this.lstActions.push(action)
                    this.lstActionsNames.push(action.Name)
                });
            }
        );
    }

    public showDate(dateString: string){
        const date: Date = new Date(dateString)
        return this.addZ(date.getDate()) + '/' + this.addZ(date.getMonth()) + '/' + date.getFullYear() + " " + this.addZ(date.getHours()) + ":" + this.addZ(date.getMinutes()) + ":" + this.addZ(date.getSeconds())
    }

    addZ(n){return n<10? '0'+n:''+n;}

    ngAfterViewInit(): void {}

    btnToggleActionClicked() {
        this.addSchedulerEnabled = !this.addSchedulerEnabled;
    }

    // Show detail modal
    public async openDetail(theAction: TnTAlertingScheduler) {
        this.getAlertingSchedulers();
        await new Promise(f => setTimeout(f, 1000));
        for (let i = 0; i < this.lstAlertingSchedulers.length; i++) {
            const element = this.lstAlertingSchedulers[i];
            if (theAction.Id == element.Id)
            {
                theAction = element;
            }
        }
        this.selectedType = theAction.Id;
        console.log(this.detailModal)
        this.detailModal.showChildModal(theAction);
    }

    btnAddActionClicked() {}

    getAlertingSchedulers() {
        this.svcAPI.getAlertingSchedulers()
        .pipe(catchError((error) => {
            return of([]); // Return empty message set
        }))
        .subscribe(
            schedulers => {
                this.lstAlertingSchedulers = schedulers
            }
        );
    }

    schedulerClicked(i: number, field: String, clicked?: TnTAlertingScheduler) {
        this.selectedRow = i;
        if (field === "Name") {
            this.lstAlertingSchedulers.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = clicked;
                    this.selNameId = clicked.Id;
                    this.selAction = null;
                    this.selActionId = -1;
                    this.selDescription = null;
                    this.selDescriptionId = -1;
                }
            });
        }
        if (field === "Action") {
            this.lstAlertingSchedulers.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = null;
                    this.selNameId = -1;
                    this.selAction = clicked;
                    this.selActionId = clicked.Id;
                    this.selDescription = null;
                    this.selDescriptionId = -1;
                }
            });
        }
        if (field === "Description") {
            this.lstAlertingSchedulers.forEach((mt) => {
                if (clicked.Id === mt.Id) {
                    this.selName = null;
                    this.selNameId = -1;
                    this.selAction = null;
                    this.selActionId = -1;
                    this.selDescription = clicked;
                    this.selDescriptionId = clicked.Id;
                }
            });
        }
    }

    schedulerChanged(mt: TnTAlertingScheduler): void {
        if (mt != null) {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.svcAPI.modifyAlertingScheduler(mt).subscribe((result) => {
                    if (result === "Success") {
                        const _this = this;
                        this.snackbarService.add({
                            msg: "Saved",
                            timeout: 2000,
                            customClass: "snackBarSucces",
                            action: {
                                text: "X",
                            },
                        });
                    } else {
                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: "snackBarError",
                            action: {
                                text: "X",
                            },
                        });
                    }
                });

                clearInterval(this.timer);
            }, 800);
        }
    }

    statusClicked(mt: TnTAlertingScheduler): void {
        if (mt != null) {
            mt.Mode = !mt.Mode;
            this.schedulerChanged(mt);
        }
    }

    btnAddSchedulerClicked(){
        if (this.strNewName) {
            if (!this.lstActionsNames.includes(this.selNewAction)) {
                const _this = this;
                this.snackbarService.add({
                    msg: 'Please select a type',
                    timeout: 2000,
                    customClass: 'snackBarError',
                    action: {
                        text: 'X'
                    }
                });

            } else {
                const theScheduler: TnTAlertingScheduler = <TnTAlertingScheduler>{
                    Mode: true,
                    Name: this.strNewName ? this.strNewName.trim() : null,
                    Action: this.lstActions.find(r => r.Name == this.selNewAction) ? this.lstActions.find(r => r.Name == this.selNewAction) : null,
                    Description: this.strNewDescription ? this.strNewDescription.trim() : null,
                    Status: 0,
                    LastChangedOn: new Date()
                };

                // TO DO SEND TO BACKEND
                console.log(theScheduler)
                
            this.svcAPI.AddAlertingScheduler(theScheduler)
            .pipe(catchError((error) => {
                return 'Error';
            }))
            .subscribe(
            res => {
                if(res['Message'] == 'Success'){
                this.snackbarService.add({
                    msg: "Saved",
                    timeout: 2000,
                    customClass: "snackBarSucces",
                    action: {
                        text: "X",
                    },
                });
                this.addSchedulerEnabled = false
                this.lstAlertingSchedulers.push(res['Value'])
                console.log(res['Value'])
                this.strNewName = null;
                this.strNewDescription = null;
                this.selNewAction = null;
                }else{
                    this.snackbarService.add({
                        msg: res['Message'],
                        timeout: 2000,
                        customClass: "snackBarError",
                        action: {
                            text: "X",
                        },
                    });
                }
            }
        );
            }
        } else {
            const _this = this;
            this.snackbarService.add({
                msg: 'Please fill in name and URL',
                timeout: 2000,
                customClass: 'snackBarError',
                action: {
                    text: 'X'
                }
            });

        }
    }

    removeScheduler(theScheduler: TnTAlertingScheduler){
        const index = this.lstAlertingSchedulers.indexOf(theScheduler);
        if (index > -1) {
            this.lstAlertingSchedulers.splice(index, 1);
        }
        this.svcAPI.DeleteAlertingScheduler(theScheduler)
            .pipe(catchError((error) => {
                return 'Error';
            }))
            .subscribe(
            res => {
                if(res == 'Success'){
                    const _this = this;
                this.snackbarService.add({
                    msg: "Deleted",
                    timeout: 2000,
                    customClass: "snackBarSucces",
                    action: {
                        text: "X",
                    },
                });
                }
                else{
                    const _this = this;
                    this.snackbarService.add({
                        msg: res,
                        timeout: 2000,
                        customClass: "snackBarError",
                        action: {
                            text: "X",
                        },
                    });
                }
            }
        );
    }
}
