import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../../_services/';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SnackbarService } from 'ngx-snackbar';


@Component({
    styleUrls: ['../../configuration.component.scss','./configuration_reprocessing_description.component.scss'],
    templateUrl: './configuration_reprocessing_description.component.html',
})
export class ConfigurationReprocessingDescriptionComponent implements OnInit {

    private selectedRow: Number;

    private selDescription: TnTReprocessTrigger = null;
    private selDescriptionId: number = -1;
    private selDescriptionBn: TnTMessageType;
    private selDescriptionMtId: number = -1;
    public isLoading: boolean = false;


    //Add reprocessDescription
    public strNewReprocessDescription: string = "";
    public selNewReprocessDescriptionBn: TnTMessageType;


    private previousReprocessDescription: TnTReprocessTrigger = null;

    private timer;

    public lstReprocessTriggers: Array<TnTReprocessTrigger> = [];
    public lstFEMessageTypes: Array<TnTMessageType> = [];
    public lstAllMessageTypes: Array<TnTMessageType> = [];

    @ViewChild('inputDescription', { static: false }) inputDescription: any;

    // Filter vars
    public srcMessageTypeFilter: TnTMessageType = null;
    public blnSearchMessageTypeApplied = false;

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private snackbarService: SnackbarService) { }

    public ngOnInit() {
        this.isLoading = true;
        this.getAllReprocessDescriptions();
        this.getAvailableMessageTypes();
        this.getAllMessageTypes();
        this.isLoading = false;
    }

    ngAfterViewInit(): void { }

    public getAllReprocessDescriptions(): void {
        this.svcAPI.getAllReprocessDescriptions(this.srcMessageTypeFilter)
            .pipe(catchError((error) => {
                return of([]); // Return empty message set
            }))
            .subscribe(
                res => {
                    this.lstReprocessTriggers = res;
                }
            );

    }

    public getAllMessageTypes() {
        this.svcAPI.getMessageTypes(false)
            .pipe(catchError((error) => {
                return of([]); // Return empty message set
            }))
            .subscribe(
                messagetypes => {
                    this.lstAllMessageTypes = messagetypes;
                }
            );
    }

    public getAvailableMessageTypes(includeMessageType?: TnTReprocessTrigger) {

        if (includeMessageType != null) {
            //
            //
            this.svcAPI.getMessageTypesReprocessTriggerDescription(false, includeMessageType.MessageTypeId)
                .pipe(catchError((error) => {
                    return of([]); // Return empty message set
                }))
                .subscribe(
                    messagetypes => {
                        this.lstFEMessageTypes = messagetypes;
                        this.selDescriptionBn = this.lstFEMessageTypes.find(x => x.Id == includeMessageType.MessageTypeId);
                    }
                );
        } else {
            this.svcAPI.getMessageTypesReprocessTriggerDescription(false)
                .pipe(catchError((error) => {
                    return of([]); // Return empty message set
                }))
                .subscribe(
                    messagetypes => {
                        this.lstFEMessageTypes = messagetypes;
                    }
                );

        }

    }

    public DescriptionClicked(i: number, field: String, clicked?: TnTReprocessTrigger) {
        this.selectedRow = i;
        this.selDescription = clicked;

        this.selDescriptionMtId = -1;
        this.selDescriptionId = -1;

        this.lstFEMessageTypes = this.lstAllMessageTypes;

        if (field === 'Description') {
            this.lstReprocessTriggers.forEach((t) => {
                if (clicked.Id === t.Id) {
                    this.selDescriptionId = t.Id;
                    setTimeout(() => {
                        this.inputDescription.nativeElement.focus();
                    }, 10);
                }
            });
        }

        if (field === 'MessageType') {

            this.lstReprocessTriggers.forEach((t) => {
                if (clicked.Id === t.Id) {
                    this.selDescriptionMtId = t.Id;
                }
            });
        }
    }

    public newDescriptionClicked(): void {
        this.getAvailableMessageTypes();
    }

    public PayloadClicked(t: TnTReprocessTrigger): void {
        if (t.IncludePayload) {
            t.IncludePayload = false;
        } else {
            t.IncludePayload = true;
        }
        this.svcAPI.changeReprocessTrigger(t).subscribe(
            result => {
                if (result === 'Succes') {
                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Saved',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });
                } else {
                    const _this = this;
                    this.snackbarService.add({
                        msg: result,
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });
                }
            }
        );
    }


    public MetadataClicked(t: TnTReprocessTrigger): void {
        if (t.IncludeMetadata) {
            t.IncludeMetadata = false;
        } else {
            t.IncludeMetadata = true;
        }
        this.svcAPI.changeReprocessTrigger(t).subscribe(
            result => {
                if (result === 'Succes') {
                    const _this = this;
                    this.snackbarService.add({
                        msg: 'Saved',
                        timeout: 2000,
                        customClass: 'snackBarSucces',
                        action: {
                            text: 'X'
                        }
                    });
                } else {
                    const _this = this;
                    this.snackbarService.add({
                        msg: result,
                        timeout: 2000,
                        customClass: 'snackBarError',
                        action: {
                            text: 'X'
                        }
                    });
                }
            }
        );
    }

    public DescriptionChanged(t: TnTReprocessTrigger): void {
        if (t != null) {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.svcAPI.changeReprocessTrigger(t).subscribe(
                    result => {
                        if (result === 'Succes') {
                            const _this = this;
                            this.snackbarService.add({
                                msg: 'Saved',
                                timeout: 2000,
                                customClass: 'snackBarSucces',
                                action: {
                                    text: 'X'
                                }
                            });
                        } else {
                            const _this = this;
                            this.snackbarService.add({
                                msg: result,
                                timeout: 2000,
                                customClass: 'snackBarError',
                                action: {
                                    text: 'X'
                                }
                            });
                        }
                    }
                );
                clearInterval(this.timer);
            }, 800);
        }
    }

    public bnDescriptionChanged(t: TnTReprocessTrigger): void {
        if (t != null) {
            t.MessageTypeId = this.selDescriptionBn.Id;
            this.svcAPI.changeReprocessTrigger(t).subscribe(
                result => {
                    if (result === 'Succes') {
                        this.getAllReprocessDescriptions();
                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Saved',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });
                    } else {
                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: 'snackBarError',
                            action: {
                                text: 'X'
                            }
                        });
                    }
                }
            );
        }
    }

    public removeReprocessTrigger(t: TnTReprocessTrigger): void {
        if (t != null) {
            this.svcAPI.deleteReprocessTrigger(t.Id, true).subscribe(
                result => {
                    if (result === 'Succes') {
                        this.getAllReprocessDescriptions();
                        this.getAvailableMessageTypes();
                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Removed',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });
                    } else {
                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: 'snackBarError',
                            action: {
                                text: 'X'
                            }
                        });
                    }
                }
            );
        }
    }

    public btnAddReprocessDescriptionClicked(): void {
        if (this.strNewReprocessDescription == '') {
            const _this = this;
            this.snackbarService.add({
                msg: 'Please fill in a description',
                timeout: 2000,
                customClass: 'snackBarError',
                action: {
                    text: 'X'
                }
            });
        }
        else if (this.selNewReprocessDescriptionBn == undefined) {
            const _this = this;
            this.snackbarService.add({
                msg: 'Please select a messagetype',
                timeout: 2000,
                customClass: 'snackBarError',
                action: {
                    text: 'X'
                }
            });
        } else {
            var theNewReprocessTrigger: TnTReprocessTrigger = {
                Id: -1,
                URL: '',
                MessageTypeId: this.selNewReprocessDescriptionBn.Id,
                MessageTypeBusinessName: '',
                IncludeMetadata: false,
                IncludePayload: false,
                IncludeProcess: false,
                Description: this.strNewReprocessDescription

            }

            this.svcAPI.addReprocessTrigger(theNewReprocessTrigger).subscribe(
                result => {
                    if (result === 'Succes') {
                        this.getAllReprocessDescriptions();
                        this.getAvailableMessageTypes();
                        this.selNewReprocessDescriptionBn = null;
                        this.strNewReprocessDescription = '';

                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Added',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });
                    } else {
                        const _this = this;
                        this.snackbarService.add({
                            msg: result,
                            timeout: 2000,
                            customClass: 'snackBarError',
                            action: {
                                text: 'X'
                            }
                        });
                    }
                }
            );
        }
    }

    public clearSearchMessagetype(): void {
        this.srcMessageTypeFilter = null;
        this.getAllReprocessDescriptions();
    }

    public getlstReprocessTriggers() {
        return this.lstReprocessTriggers.filter((rpt) => rpt.Description !== '' && rpt.Description !== null);
    }
}
