import { Component, OnInit, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TnTAPIService } from '../../_services/';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SnackbarService } from 'ngx-snackbar';

@Component({
    styleUrls: ['../configuration.component.scss','./configuration_businessViews.component.scss'],
    templateUrl: './configuration_businessViews.component.html',
})

export class ConfigurationBusinessViewsComponent implements OnInit {

    // Loader
    public isLoading: boolean;

    // Lists
    public lstMessageTypes: Array<TnTMessageType> = [];
    public lstMessageTypeIdsThatHasBv: Array<number> = [];

    // Vars
    public selMt: TnTMessageType;
    public selMtId: number = -1;
    public selBvXSLorig: string = '';
    public selBvXSLnew: string = '';
    public selBv: TnTBusinessView = { Id: -1, MessageTypeId: -1, XSL: '' };
    public srcMessageType: string = '';
    public blnSearchMessageTypeApplied: boolean = false;

    //public editorOptions = {
    //    theme: 'vs-light',
    //    language: 'text/html',
    //    scrollBeyondLastLine: false
    //};

    // Timer
    private timer;

    @ViewChild('inputMessageTypeSearch', { static: false }) inputMessageTypeSearch: any;

    constructor(private svcAPI: TnTAPIService, private svcRouter: Router, private snackbarService: SnackbarService) { }

    public ngOnInit() {
        this.getMessageTypes();
        this.getAllMessageTypesThatHasBusinessView();
    }

    public getMessageTypes(): void {
        this.svcAPI.getMessageTypesUserPortal(false, this.srcMessageType)
            .pipe(catchError((error) => {
                return of([]); // Return empty message set
            }))
            .subscribe(
            messagetypes => {
                this.lstMessageTypes = messagetypes;
                }
        );
        this.blnSearchMessageTypeApplied = this.srcMessageType !== '';
    }

    public getAllMessageTypesThatHasBusinessView(): void {
        this.svcAPI.getAllMessageTypesThatHasBusinessView()
            .pipe(catchError((error) => {
                return of([]); // Return empty message set
            }))
            .subscribe(
            bvs => {
                this.lstMessageTypeIdsThatHasBv = bvs;
                }
            );
    }

    public messageTypeClicked(clickedMT: TnTMessageType): void {
        this.lstMessageTypes.forEach((mt) => {
            if (mt.Id === clickedMT.Id) {
                this.selMt = mt;
                this.selMtId = mt.Id;
                this.svcAPI.getBusinessViewValues(clickedMT.Id)
                    .pipe(catchError((error) => {
                        return of([]); // Return empty bvset
                    }))
                    .subscribe(
                        bv => {
                            if (bv[0] != null) {
                                this.selBv = bv[0];
                                this.selBvXSLnew = this.selBv.XSL;
                                this.selBvXSLorig = this.selBv.XSL;
                            } else {
                                this.selBv = { Id: -1, MessageTypeId: clickedMT.Id, XSL: '' };
                                this.selBvXSLnew = '';
                                this.selBvXSLorig = '';
                            }
                        }
                    );
            }
        });
    }

    public businessViewChanged(bvChanged: string): void {
        this.selBvXSLnew = bvChanged;
    }

    public saveBv(): void {
        this.selBv.XSL = this.selBvXSLnew;
        clearInterval(this.timer);
        this.timer = setInterval(() => {
            this.svcAPI.changeBusinessView(this.selBv).subscribe(
                result => {
                    if (result == "Succes") {
                        this.getAllMessageTypesThatHasBusinessView();

                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Saved',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });
                    } else {

                        const _this = this;
                        this.snackbarService.add({
                            msg: 'An error occured while saving',
                            timeout: 2000,
                            customClass: 'snackBarError',
                            action: {
                                text: 'X'
                            }
                        });

                    }
                }
            );
            clearInterval(this.timer);
        }, 800);
    }

    public discardBv(): void {
        this.selBvXSLnew = this.selBvXSLorig;
        this.selBv.XSL = this.selBvXSLorig;
        clearInterval(this.timer);
        this.timer = setInterval(() => {
            this.svcAPI.changeBusinessView(this.selBv).subscribe(
                result => {
                    if (result == "Succes") {
                        this.getAllMessageTypesThatHasBusinessView();

                        const _this = this;
                        this.snackbarService.add({
                            msg: 'Discarded changes',
                            timeout: 2000,
                            customClass: 'snackBarSucces',
                            action: {
                                text: 'X'
                            }
                        });
                    } else {

                        const _this = this;
                        this.snackbarService.add({
                            msg: 'An error occured while discarding changes',
                            timeout: 2000,
                            customClass: 'snackBarError',
                            action: {
                                text: 'X'
                            }
                        });

                    }
                }
            );
            clearInterval(this.timer);
        }, 800);
    }

    public getMessageType(): void {
        this.getMessageTypes();
    }

    public clearSearchMessageType(): void {
        this.srcMessageType = '';
        this.selBv = { Id: -1, MessageTypeId: -1, XSL: '' };
        this.selBvXSLnew = '';
        this.selBvXSLorig = '';
        this.getMessageTypes();
    }

    public focusInputSearchMessageTypeBn(): void {
        setTimeout(() => {
            this.inputMessageTypeSearch.nativeElement.focus();
        }, 10);
    }
}
