<div class="col-md-12 paddingTop" style="text-align: right; padding-bottom: 5px">
    <button class="btn btn-primary addButtonTypeDetail" type="button" (click)="btnToggleActionClicked()">
        <i-feather name="plus-circle" id="plusIcon" class="pointer"></i-feather>
    </button>
</div>
<div class="col-12 paddingBottomTable">
    <div class="card">
        <div class="table-responsive" style="overflow: inherit">
            <table class="table table-hover p-3 mb-5 tableLikeCard">
                <thead class="text-primary-inverse">
                    <tr>
                        <th class="col-3">Name</th>
                        <th class="col-2">Subscription</th>
                        <th class="col-2">Condition</th>
                        <th class="col-3">Description</th>
                        <th class="col-1 centerText">Settings</th>
                        <th class="col-1"></th>
                    </tr>
                </thead>
                <tbody class="pointer">
                    <tr>
                        <td colspan="12" class="nomsg text-muted" *ngIf="!isLoading && lstAlertingActions.length == 0">
                            No actions found.
                        </td>
                        <td colspan="12" *ngIf="isLoading">
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                    Loading actions
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr id="lastTR" *ngIf="addActionEnabled">
                        <td class="col-3">
                            <input #inputNewEndpointEmail class="form-control" [(ngModel)]="strNewName" placeholder="Name" />
                        </td>
                        <td class="col-2">
                            <ng-select placeholder="Select subscription" [items]="lstSubscriptionsNames" [multiple]="false" [clearable]="true" bindLabel="Subscription" [(ngModel)]="selNewSubscription"></ng-select>
                        </td>
                        <td class="col-2">
                            <ng-select placeholder="Select condition" [items]="lstConditionsNames" [multiple]="false" [clearable]="true" bindLabel="Condition" [(ngModel)]="selNewCondition"></ng-select>
                        </td>
                        <td class="col-4">
                            <input #inputNewEndpointEmail class="form-control" [(ngModel)]="strNewDescription" placeholder="Description" />
                        </td>
                        <td class="col-1">
                        </td>
                        <td class="smallTD-6 centerText">
                            <span (click)="btnAddActionClicked()" class="hoverTrashIcon">
                                <i-feather name="plus-circle"></i-feather>
                            </span>
                        </td>
                    </tr>

                    <!-- ROW EDIT -->
                    <tr *ngFor="let theAction of lstAlertingActions; let i = index" [class.active]="i == selectedRow" (click)="actionClicked(theAction)">
                        <ng-container *ngIf="!isLoading">
                            <!--Name-->
                            <td class="col-3" (click)="actionClicked(i, 'Name', theAction)">
                                <span *ngIf="selNameId != theAction.Id">{{ theAction.Name }}</span>
                                <input class="form-control" *ngIf="selNameId == theAction.Id" [(ngModel)]="theAction.Name" (input)="actionChanged(theAction)" />
                            </td>
                            <!--Subscription-->
                            <td class="col-2" (click)="actionClicked(i, 'Subscription', theAction)">
                                <span *ngIf="selSubscriptionId != theAction.Id">{{ theAction.Subscription.Type }}</span>
                                <ng-select placeholder="Select subscription" [multiple]="false" [clearable]="true" bindLabel="Subscription" [(ngModel)]="theAction.Subscription" *ngIf="selSubscriptionId == theAction.Id" (change)="actionChanged(theAction)">
                                    <ng-option *ngFor="let subscription of lstSubscriptions" [value]="subscription">{{subscription.Type}}</ng-option>
                                </ng-select>
                            </td>
                            <!--Condition-->
                            <td class="col-2" (click)="actionClicked(i, 'Condition', theAction)">
                                <span *ngIf="selConditionId != theAction.Id">{{ theAction.Condition.Name }}</span>
                                <ng-select placeholder="Select condition" [multiple]="false" [clearable]="true" bindLabel="Condition" [(ngModel)]="theAction.Condition" *ngIf="selConditionId == theAction.Id" (change)="actionChanged(theAction)">
                                    <ng-option *ngFor="let Condition of lstConditions" [value]="Condition">{{Condition.Name}}</ng-option>
                                </ng-select>
                            </td>
                            <!--Description-->
                            <td class="col-4" (click)="actionClicked(i, 'Description', theAction)">
                                <span *ngIf="selDescriptionId != theAction.Id">{{ theAction.Description }}</span>
                                <input class="form-control" *ngIf="selDescriptionId == theAction.Id" [(ngModel)]="theAction.Description" (input)="actionChanged(theAction)" />
                            </td>
                            <td class="smallTD-6 centerText">
                                <span (click)="openDetail(theAction)" class="hoverEditIcon"><i-feather name="settings"></i-feather></span>
                            </td>
                            <td class="smallTD-6 centerText">
                                <span (click)="removeAction(theAction)" class="hoverTrashIcon">
                                    <i-feather name="trash"></i-feather>
                                </span>
                            </td>
                        </ng-container>
                    </tr>

                    <!-- POPUP -->
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-tnt-configuration_alerting_actions_detail></app-tnt-configuration_alerting_actions_detail>