import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UserportalService {

    // Observable string sources
    private newGroup = new Subject<string>();
    private deletedGroup = new Subject<string>();

    // Observable string streams
    newGroup$ = this.newGroup.asObservable();
    deletedGroup$ = this.deletedGroup.asObservable();

    // Service message commands
    emitNewGroup(group: string) {
        this.newGroup.next(group);
    }

    emitDeletedGroup(group: string) {
        this.deletedGroup.next(group);
    }
}
